import {defineStore} from "pinia";

export const useTableStore = defineStore('table', {
  state: () => ({
    tableId: null,
    shopId: null, // ✅ Link table to a shop
  }),

  actions: {
    setTable(tableId, shopId) {
      this.tableId = tableId;
      this.shopId = shopId;
    },
    resetTable() {
      this.tableId = null;
      this.shopId = null;
    }
  },

  getters: {
    getTableId: state => state.tableId,
    getShopId: state => state.shopId,
  },

  persist: {
    enabled: true,
    strategies: [{ key: 'table', storage: localStorage }],
  },
});
