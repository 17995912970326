<template>
  <div class="shopCards startPageBackgroundImage">

    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>

    <!-- wrapper to prevent showing an empty shop-card when the element is still loading -->
    <div class="shopCards">
      <h1 class="shopPageTitle">
        {{ $t('subTitle.chooseShop') }}
      </h1>
      <shop-carousel v-if="showCarousel" :shops="shops" :callbackSelect="selectShop"> </shop-carousel>
      <div class="col-12 shopCardWrapper" style="align-items: center;justify-content: center;">
        <shop-card v-for="shop in shops" v-bind:key="shop.id" :id="shop.id" :addresses="shop.addresses" :image="shop.image" :name="shop.name" :url="shop.custom10" :code="shop.custom2" :callback="selectShop"></shop-card>
      </div>
    </div>
  </div>
</template>

<script>
import ShopCard from '../main-components/ShopCard.vue'
import VueLoading from 'vue-loading-overlay'
import Carousel from '../sub-components/ShopCarousel.vue'
import $ from 'jquery'
import Utils from "@/utils/utils";
import {shopService} from "@/services/api/shopService";
import {useShopStore} from "../../stores/shop";
import {useAppStore} from "@/stores/app";
import {Workflow} from "@/constants/constants";
import {useOrderStore} from "@/stores/order";

export default {
  data() {
    return {
      isLoading: false,
      shops: [],
      selectedShop: {},
      showCarousel: false,
      deltaY: 0
    }
  },
  async created() {
    this.showCarousel = window.config.SHOW_SHOP_CAROUSEL;
    await this.loadShops();
  },
  props: {
    callback: {
      Type: Function
    },
    shopId:{
      Type: Number,
      Default: 0
    },
  },
  setup() {
    const shopStore = useShopStore();
    const appStore = useAppStore();
    const orderStore = useOrderStore();

    return {
      shopStore,
      appStore,
      orderStore,
    };
  },
  methods: {
    async loadShops() {
      this.isLoading = true;
      const response = await shopService.getShops(true);
      this.isLoading = false;

      if (Utils.isEmpty(response.shops)) {
        this.makeToast('danger', this.$t('notification.noShopsAvailable'));
        return;
      }

      this.shops = response.shops;
      if (this.shops.length === 1 && window.config.FLAG_AUTOSELECTSHOP) {
        await this.selectShop(this.shops[0].id);
      }
    },
    async selectShop(shopId) {
      const selectedShop = this.shops.filter(shop => shop.id.toString() === shopId.toString())[0];
      this.shopStore.setShop(selectedShop.id, selectedShop.custom1, selectedShop.custom2, selectedShop.name, selectedShop.image, selectedShop.custom6);

      if (this.appStore.isWorkflow(Workflow.MEAL_PLAN)) {
        this.$navigateToMealPlanner();
      } else if (this.appStore.isWorkflow(Workflow.ORDER)) {
        this.$navigateToShop(false);
      }
    },
  },
  components: {
    'shop-card' : ShopCard,
    'shop-carousel' : Carousel,
    'loading': VueLoading,
  },
  watch: {
    '$i18n.locale': function(newVal, oldVal) {
      if(newVal != oldVal)
      {
        this.loadShops();
      }
    }
  },
  // eslint-disable-next-line no-unused-vars
  beforeRouteEnter (to, from, next) {
    // eslint-disable-next-line no-unused-vars
    next(vm => {
      var myDiv = document.getElementById('contentSection');
      $("#contentSection").scrollTop(0);
      $("#app").scrollTop(0);

      if(myDiv != undefined)
        if(myDiv != null)
          myDiv.scrollTop = 0;
    })
  },
}
</script>

<style scoped>
.shopCardWrapper {
  display:flow-root;
  padding: 2% 10%;
}
@media screen and (max-width:770px) {
  .shopCardWrapper {
    display:flow-root;
  }
}

</style>
