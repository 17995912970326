import {apiUtils} from "@/utils/apiUtils";

export const basketService = {
  async addProductToBasket(productId, orderType, calculateOrder, quantity, options = {}) {
      try {
        return await apiUtils.doPost("/TCPAddMenuItemToCartJ.action", {
          json: "true",
          productId: productId,
          orderType: orderType,
          calculateOrder: calculateOrder,
          quantity: quantity,
          ...options,
          });
      } catch (error) {
          console.error("Error adding product to basket:", error);
          throw error;
      }
  },
  async addComplexProductToBasket(productId, orderType, calculateOrder, quantity, options = {}) {
    try {
      return await apiUtils.doPost("/TCPAddMenuItemToCartJ.action", {
        json: "true",
        prod: productId,
        orderType: orderType,
        calculateOrder: calculateOrder,
        quantity: quantity,
        ...options,
      });
    } catch (error) {
      console.error("Error adding product to basket:", error);
      throw error;
    }
  },
  async editBasket(productId, quantity, orderType) {
    try {
      return await apiUtils.doPost('/TCPEditCartSubmitJ.action', {
        basketId: productId,
        quantity: quantity,
        orderType: orderType,
      });
    } catch (error) {
      console.error("Error edit basket:", error);
      throw error;
    }
  },
  async removeProductFromBasket(productId, orderType) {
      try {
        return apiUtils.doPost('/TCPRemoveMenuItemFromCartJ.action', {
            id: productId,
            orderType: orderType
          });
      } catch (error) {
          console.error("Error removing product from basket:", error);
          throw error;
      }
  },
  async removeProductFromBasketByProductId(productId, quantity, orderType) {
    try {
      return apiUtils.doPost('/TCPRemoveMenuItemFromCartJ.action', {
        productId: productId,
        orderType: orderType,
        quantity: quantity,
      });
    } catch (error) {
      console.error("Error removing product from basket:", error);
      throw error;
    }
  },
  async emptyBasket() {
    try {
      return apiUtils.doPost('/TCPEmptyBasketJ.action', {
        json: "true"
      });
    } catch (error) {
      console.error("Error emptying basket:", error);
      throw error;
    }
  }
};
