<template>
  <div class="d-flex flex-row flex-nowrap position-relative cSwiper" :class="{['cSwiper-navigationInline']:navigationInline}">
    <div v-if="!navigationInline" class="cSwiper-nav-outside-wrapper">
      <button class="cSwiper-nav-outside" ref="prevEl" @click="swiperInstance.slidePrev()"
        :aria-label="label_prev">
        <span class="swiper-button-prev"></span>
      </button>
    </div>
    <div ref="swiperEl" class="swiper w-100">

      <div class="swiper-wrapper">
        <div v-for="(item,index) in loadedItems" :key="index" class="swiper-slide" :data-swiper-slide-index="index" :aria-hidden="!isVisible(index + startIndex).toString()">
          <slot
            :index="index"
            :value="item"
            :offset="index + startIndex"
            :visible="isVisible(index + startIndex)"
            :selected="isSelected(index+startIndex)"
            :onSelect="function(){select(item,index + startIndex);}">
            Item : {{ index }}<br/>
            Value : {{ item }}<br/>
            <small>Offset : {{ index + startIndex }}</small>
          </slot>
        </div>
      </div>

      <!-- @theme : default -->
      <!-- If we need navigation buttons -->
      <button class="swiper-button-prev cSwiper-nav-inside" v-if="navigationInline" :aria-label="label_prev" @click="swiperInstance.slidePrev()"></button>
      <button class="swiper-button-next cSwiper-nav-inside" v-if="navigationInline" :aria-label="label_next" @click="swiperInstance.slideNext()"></button>

      <!-- Vue - Input -->
      <div class="d-none" v-if="selectable">
        <slot name="vueModel">
          <input v-model="nativeModel" type="hidden" />
        </slot>
      </div>
    </div>
    <div v-if="!navigationInline" class="cSwiper-nav-outside-wrapper">
      <button class="cSwiper-nav-outside" ref="nextEl" @click="swiperInstance.slideNext()" :aria-label="label_next">
        <span class="swiper-button-next"></span>
      </button>
    </div>
  </div>
</template>

<script>
import { nextTick } from "vue";
import Swiper from 'swiper';

/**
 * Currently Selection is not Implemented. <br/>
 * To Work with it, you would need to provide the state : {value: <selected>, index: 0}
 *
 * @typeParam T - Handler Type
 *
 */
export default {
  name: 'CustomSwiper',
  setup() {
  },
  data() {
    return {
      swiperInstance: null,
      itemStorage: [],
      startIndex: 0,
      endIndex: 0,
      selectedValue: null,
      selectedIndex: null,
    };
  },
  emits: ['update:modelValue'],
  model: { // vue 2.2 specific
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  props: {
    /** @type {T[]} */
    items: {
      type: Array,
      default: ()=>[],
    },
    selectable: Boolean,
    grouped: Boolean,
    /** Important: customize with :items-per-view="<number>", cause that is a number */
    itemsPerView: {
      Type: Number,
      default: 1,
      validator: (value) => typeof value === "number",
    },
    preloadedGroupCount: {
      type: Number,
      default: 1,
      validator: (value) => typeof value === "number" && value >= 1,
    },
    continuesElements: Boolean,
    /** @type {function(indexes:Number[]):T[]} */
    continuesItemGenerator: {
      Type: Function,
      default: () => function(){ return []; },
    },
    navigationInline: Boolean,
    label_prev: Function,
    label_next: Function,
    modelValue: {
      Type: Object,
      default: () => null,
    },
    itemComparator: {
      Type: Function,
      default: () => (a,b) => a === b,
    },
    configurate: {
      Type: Function,
      default: () => a => a,
    }
  },
  computed: {
    /** @type {T[]} */
    loadedItems() {
      return this.itemStorage;
      // return valueHolder.value.slice(0,this.itemsPerView * 3); // Stack 1 for 'Before', 'Now' and 'After'
    },
    currentIndex() {
      if (!this.swiperInstance) {
        return this.startIndex;
      }
      return this.swiperInstance.activeIndex + this.startIndex;
    },
    nativeModel: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    }
  },
  mounted() {
    this.swiperInstance = new Swiper(this.$refs.swiperEl, this.config());
    // apply events
    if (this.continuesElements) {
      const initialLoad = [];

      // generate forwards and current, if that does not yet exist
      if (this._triggersEndLoader()) {
        const idx = this.currentIndex - 0;
        initialLoad.push(...this.createRange(this.endIndex + 1,(idx + this.itemsPerView - 1) + (this.itemsPerView * this.preloadedGroupCount)));
      }
      // generate backwards
      if (this._triggersStartLoader()) {
        initialLoad.unshift(...this.createRange(this.startIndex - (this.itemsPerView * this.preloadedGroupCount), this.startIndex - 1));
      }

      this.generateByIndexes(initialLoad);

      this.swiperInstance.on('slideChangeTransitionEnd',this.handleSlideChangeTransitionEnd);
    }
  },
  methods: {
    config() {
      this.itemStorage = this.items;
      this.endIndex = this.items.length - 1;

      let conf = {
        slidesPerView: this.itemsPerView,
        items: this.itemStorage,
        navigation: {
          enabled: true,
          nextEl: this.navigationInline ? '.swiper-button-next' : this.$refs.nextEl,
          prevEl: this.navigationInline ? '.swiper-button-prev' : this.$refs.prevEl,
        },
        normalizeSlideIndex: false,
        // roundLengths: true, // enable, if there are rendering issues
      };
      if (this.grouped) {
        conf['slidesPerGroup'] = this.itemsPerView;
      }
      if (this.continuesElements) {
        conf['virtual'] = {
          'enabled': true,
          'addSlidesAfter': (this.itemsPerView * this.preloadedGroupCount) + (this.itemsPerView - 1),
          'addSlidesBefore': this.itemsPerView * this.preloadedGroupCount,
          'cache': false,
        }
      }
      if (this.selectable) {
        conf['focusableElements'] = 'input, select, option, textarea, video, label'; // takes out the 'button' selector
      }
      return this.configurate(conf); // apply any external configurations
    },
    handleSlideChangeTransitionEnd(self) { // triggers, when the slide finished its animation
      if (self.isBeginning || this._triggersStartLoader()) {
        this.generate(this.currentIndex - (this.itemsPerView * this.preloadedGroupCount), this.startIndex - 1);
      }
      if (self.isEnd || this._triggersEndLoader()) {
        this.generate(this.endIndex + 1, ( this.currentIndex - 1 + this.itemsPerView ) + (this.itemsPerView * this.preloadedGroupCount));
      }
    },
    async generateByIndexes(args) {
      args.sort((a,b)=>a - b); // sort ascending
      if (args.length === 0) return; // skip call, if nothing needs attention
      let value = this.continuesItemGenerator(args); // request the new data
      // valid : value.length === arg.length
      if (value !== null && value !== undefined && typeof value === 'object' && value.length === args.length) {
        let goal = 0;
        let bulkPrepend = [];
        for (let arrIndex = 0; arrIndex < args.length; arrIndex++) {
          const index = args[arrIndex];
          const item = value[arrIndex];

          // if selection is enabled, find the selected element from the returned values
          if (this.selectable && this.selectedIndex === null && this.nativeModel !== undefined && this.itemComparator(this.nativeModel,item)) {
            this.selectedIndex = index;
          }
          if (index > 0) {
            this.appendItems([item]);
            this.endIndex = Math.max(this.endIndex, index);
          } else {
            bulkPrepend.push(item);
            const diff = this.startIndex - Math.min(this.startIndex, index);
            this.startIndex = Math.min(this.startIndex, index);
            goal += diff;
          }
        }
        if (bulkPrepend.length !== 0)
        {
          this.prependItems(bulkPrepend);
        }

        // console.log(`Currently at %o, Items Loaded Range from %o to %o (+%o Items)`, this.currentIndex, this.startIndex, this.endIndex, value.length);
        let _inst = this.swiperInstance;

        nextTick(async function() { /// await vue render
          _inst.updateSlides();
          if (goal !== 0) {
            _inst.slideTo(_inst.activeIndex + goal, 0, false);
          }
        });
      } else {
        console.error("Provided Different amount of output than input.");
      }
    },
    async generate(from,to) {
      // location of insertion
      let append = true;
      if (from < 0) {
        append = false;
      }
      let args = [];
      for (let i = from; i <= to; i++) {
        args.push(i);
      }
      if (args.length === 0) {
        return;
      }
      /** @type {[Object]} */
      let value = this.continuesItemGenerator(args);
      // valid : value.length === arg.length
      if (value !== null && value !== undefined && typeof value === 'object' && value.length === args.length) {
        let goal = NaN;
        if (this.selectable && this.selectedIndex === null && this.nativeModel !== undefined) {
          for (let arrIndex = 0; arrIndex < args.length; arrIndex++) {
            const index = args[arrIndex];
            const item = value[arrIndex];
            if (this.itemComparator(this.nativeModel,item)) {
              this.selectedIndex = index;
            }
          }
        }

        if (append) {
          this.appendItems(value);
          this.endIndex = to;
        } else {
          this.prependItems(value);
          this.startIndex = from;
          goal = Math.abs(to - from) + 1;
        }

        // console.log(`Currentently at %o, Items Loaded Range from %o to %o (+%o Items)`, this.currentIndex, this.startIndex, this.endIndex, Math.abs(to-from) + 1);
        let _inst = this.swiperInstance;

        nextTick(async function() { /// await vue render
          _inst.updateSlides();
          if (!isNaN(goal)) {
            _inst.slideTo(_inst.activeIndex + goal, 0, false);
          }
        });
      } else {
        console.error("Provided Different amount of output than input.");
      }
    },
    _triggersStartLoader() {
      return this.continuesElements && (this.startIndex > (this.currentIndex) - (this.itemsPerView * this.preloadedGroupCount));
    },
    _triggersEndLoader() {
      // isCorrectMode && endIndex < (index of last item visible) + (min preloaded page items.)
      return this.continuesElements && (this.endIndex < (this.currentIndex + this.itemsPerView - 1) + (this.itemsPerView * this.preloadedGroupCount));
    },
    prependItems(value) {
      this.itemStorage = [...value,...this.itemStorage]; // vue wants it like this (with the '=')
    },
    appendItems(value) {
      this.itemStorage = [...this.itemStorage,...value]; // vue wants it like this (with the '=')
    },
    isVisible(index) {
      return index >= this.currentIndex && (this.currentIndex + this.itemsPerView - 1) >= index;
    },
    isSelected(index) {
      return this.selectedIndex !== null && index === this.selectedIndex;
    },
    select(value, absoluteIndex) {
      if (this.selectable) {
        this.selectedIndex = absoluteIndex;
        this.nativeModel = value;
      }
    },
    createRange(from, to) {
      let values = [];
      for (let i = from; i <= to; i++) values.push(i);
      return values;
    },
  },
  watch: {},
  components: {},
}
</script>

<style scoped lang="scss">
.cSwiper:not(.cSwiper-navigationInline) {
  --swiper-navigation-sides-offset: 0;
  --swiper-navigation-top-offset: 0;
}

@media only screen and (max-width: 768px) {
  .swiper-button-prev,
  .swiper-button-next {
    --swiper-navigation-size: var(--swiper-navigation-size ,22px); /// size of the Navigation Arrows
  }
}

.cSwiper-nav-outside,
.cSwiper-nav-inside
{
  border: 0;
  background: transparent;

  & .swiper-button-prev, & .swiper-button-next {
    position: relative;
    margin-top: 0;
  }
}

/// adds some spaces between the elements and the navigation elements
.cSwiper-nav-outside {
  &:has(>.swiper-button-prev) {
    margin-right: var(--swiper-navigation-inner-offset,0px);
  }
  &:has(>.swiper-button-next) {
    margin-left: var(--swiper-navigation-inner-offset,0px);
  }
}

.cSwiper-nav-outside {
  position: relative;
  height: 100%;
  background: var(--main-color);
  color: var(--main-color-text);
  --swiper-navigation-color: var(--main-color-text);
  border-radius: var(--swiper-navigation-radius, 0px);
}
/// Take the Outline from the Focused element into the inside of the Element
#body .cSwiper *:focus-visible {
  outline-offset: -6px;
  outline-color: var(--main-color-text);
}
</style>
