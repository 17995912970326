<template>
  <div class="menuWrapper px-0" style="">
    <b-modal ref="articleDetailsModal" static hide-footer :title="articleWebDescription" title-tag="h3" :title-class="{'normalText':true,articleOutOfStock:!selectedArticleIsOnStock}" size="lg">
      <div class="articlePageWrapper" v-if="articleSelected">
        <article-page :callbackBack="closeArticlePage" :key="articlePageKey" :isFarLeft="isArticleFirst()" :isFarRight="isArticleLast()" :isOnStock="selectedArticleIsOnStock" :article="selectedArticle" :callbackPrevious="previousArticle" :callbackNext="nextArticle"></article-page>
      </div>
    </b-modal>

    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>

    <!-- <div class="col-12" style="padding:0px; border-bottom:0.5px solid var(--main-color);position: sticky; top:0;">             -->
    <!-- Sticky Header -->
    <div class="header-sticky noEars row w-100 noMargin" style="gap: 5px 0;">
      <!-- Mobile Search Input : Hidden on md+-->
      <form class="d-inline-block col-12 d-md-none py-0" @submit.prevent>
        <search v-show="config.SHOW_PRODUCT_SEARCH_BAR" class="position-relative" style="border:1px solid var(--body-color);margin: 5px 0;border-radius:10px" :aria-label="$t('field.search')">
          <input type="text" id="searchField" style="height:inherit;width:100%;" v-model="searchPhrase" :placeholder="$t('field.search')" role="searchbox" inputmode="search" accesskey="f">
          <div class="disableCollision position-absolute float-right" style="top:0;right:0;height:100%">
            <!-- todo text: clear -->
            <button v-if="searchPhrase != ''" type="reset" @click="clearSearch()" class="btn btnSearchField border-0">
              <font-awesome-icon :icon="['fas','times']" class="floatRight fa-xs" />
            </button>
            <!-- todo text: search -->
            <button type="submit" @click="doSearch()" style="margin-left:6px;" class="btn btnSearchField border-0">
              <font-awesome-icon :icon="['fas','search']" class="floatRight fa-2xs" />
            </button>
          </div>
        </search>
      </form>
      <!-- Category Selection -->
      <div :class="{'col-12':true,'col-md-9':config.SHOW_PRODUCT_SEARCH_BAR}" id="menuCategories" role="radiogroup">
        <div v-for="category in filterCategories(categories)" v-bind:key="category.id" :class="{[activeGroupClass]:currentCategory.id == category.id}">
          <a class="category" href="javascript:void(0)" @click="showCat(category.id)" role="radio" tabindex="0" :aria-selected="currentCategory.id == category.id" :aria-checked="currentCategory.id == category.id">{{ category.name }}</a>
        </div>
      </div>
      <!-- Desktop Search Input : Hidden on sm- -->
      <form class="d-none d-md-inline-block col-3 pl-0 py-0" @submit.prevent>
        <search v-show="config.SHOW_PRODUCT_SEARCH_BAR" class="position-relative" style="border:1px solid var(--body-color);margin: 5px 0;border-radius:10px;" :aria-label="$t('field.search')">
          <input type="text" id="searchField" style="height:inherit;width:100%;" v-model="searchPhrase" :placeholder="$t('field.search')" role="searchbox" inputmode="search" accesskey="f">
          <div class="disableCollision position-absolute float-right" style="top:0;right:0;height:100%">
            <!-- todo text: clear -->
            <button v-if="searchPhrase != ''" type="reset" @click="clearSearch()" class="btn btnSearchField border-0">
              <font-awesome-icon :icon="['fas','times']" class="floatRight fa-xs" />
            </button>
            <!-- todo text: search -->
            <button type="submit" @click="doSearch()" style="margin-left:6px;" class="btn btnSearchField border-0">
              <font-awesome-icon :icon="['fas','search']" class="floatRight fa-2xs" />
            </button>
          </div>
        </search>
      </form>

      <!-- Subcategory Quickfilter -->
      <div class="col-12" id="menuSubCategoriesWrapper" style="" role="radiogroup">
        <div style="border-bottom:0.5px solid var(--main-color);">
          <div class="col-1 categoryScrollIcon subCategoryScrollIcon" @click="scrollSubCategories(-1)">
            <i class="fa fa-chevron-left"></i>
          </div>

          <div class="col-1 categoryScrollIcon subCategoryScrollIcon" style="float:right" @click="scrollSubCategories(1)">
            <i class="fa fa-chevron-right"></i>
          </div>

          <div id="menuSubCategories">
            <div v-for="category in filterCategories(currentCategory.children)" v-bind:key="category.id" :class="{[activeGroupClass]:currentSubCategory.id === category.id}">
              <a class="category" href="javascript:void(0)" @click="showSubCat(category.id)" role="radio" tabindex="0" :aria-selected="currentSubCategory.id === category.id" :aria-checked="currentSubCategory.id === category.id">{{ category.name }}</a>
            </div>
          </div>
        </div>
      </div>
      <!-- / Subcategory Quickfilter -->

      <!-- Subcategory 2 Quickfilter -->
      <div class="col-12" id="menuSubSubCategoriesWrapper" style="border-bottom:0.5px solid var(--main-color);" role="radiogroup">
        <div class="col-1 categoryScrollIcon subCategoryScrollIcon subSubCategoryScrollIcon" @click="scrollSubSubCategories(-1)" tabindex="0" role="spinbutton">
          <i class="fa fa-chevron-left"></i>
        </div>

        <div id="menuSubSubCategories">
          <div v-for="category in filterCategories(currentSubCategory.children)" v-bind:key="category.id" :class="{[activeGroupClass]:currentSubSubCategory.id == category.id}">
            <a class="category" href="javascript:void(0)" @click="showSubSubCat(category.id)" role="radio" tabindex="0" :aria-selected="currentSubSubCategory.id === category.id" :aria-checked="currentSubSubCategory.id === category.id">{{ category.name }}</a>
          </div>

          <div class="col-1 categoryScrollIcon subCategoryScrollIcon subSubCategoryScrollIcon" style="float:right" @click="scrollSubSubCategories(1)">
            <i class="fa fa-chevron-right"></i>
          </div>
        </div>
      </div>
      <!-- / Subcategory 2 Quickfilter -->
    </div>
    <!-- / Sticky Header -->
    <div style="flex-wrap: wrap; width: 100%;" class="d-flex mt-1">

      <!-- Left Column : Product List -->
      <div class="col-lg-9 col-md-12 col-sm-12 col-xs-12" style="">
        <h3 class="groupTitle">{{getCurrentGroupTitle()}}</h3>

        <div id="menuProducts"  class="articleGroupBody" style="" v-if="productsLoaded" role="list">
          <article-card v-for="item in productArray" v-bind:key="item.id" :cartQuantity="checkItemCart(item.id)" :article="item" :callbackAdd="selectArticle" :callbackAddWeight="selectWeightArticle" :callbackRemove="removeArticle" :callbackSelected="articlePage"></article-card>
        </div>
      </div>
      <!-- / Left Column -->

      <!-- Rigth Column : (Desktop only) -->
      <div class="col-lg-3 col-md-3 col-sm-4 col-xs-12 pl-0">
        <!-- Order Card -->
        <div id="menuCart">
          <div class="col-12 shoppingCartSection" role="group">
            <h2 class="shoppingCartSectionTitle normalText"> {{ $t('subTitle.yourOrder') }} </h2>
            <span v-if="basketItems && basketItems.length == 0"> {{ $t('text.shoppingCartEmpty') }}</span>

            <!-- / Basket List -->
            <div role="list" v-if="basketItems && basketItems.length > 0">

              <!-- Basket Items -->
              <div id="menuCartElement" class="menuCartElement" v-for="item in basketItems" v-bind:key="item.id" role="listitem">

                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 noPadding">
                  <!-- Item Header -->
                  <div class="col-12" style="padding:0; padding-left:5px; text-align:left;font-size:14px;" v-html="trimDescriptionShort((item.prodText !== undefined) ? item.prodText :item.text)">

                  </div>
                  <!-- Item (optional) Description -->
                  <div class="col-12 text-black-50" v-if="item.hasOptions" style="padding:0; padding-left:10px;padding-bottom:5px; text-align:left;font-size:14px;" v-html="((item.optionText !== undefined) ? item.optionText : '')">

                  </div>
                  <!-- Item Weight or Volume -->
                  <div class="col-12 mainColor" style="padding:0; padding-left:5px; text-align:center; text-weight:bold;">
                    {{ item.formattedDiscountedPrice != undefined ? item.formattedDiscountedPrice : item.formattedTotalPrice}} {{item.weightOrVolume != undefined ? '/kg' : ''}}
                  </div>
                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex noPadding" style="">
                  <!-- "Remove 1" Button -->
                  <div class="col-4 align-content-center noPadding">
                    <div style="padding:0;" @click="removeItemQuantity(item.id, item.quantity)">
                      <svg class="minusIcon" viewBox="0 0 100 100">
                        <circle cx="50" cy="50" r="45" fill="none" stroke-width="5.5"></circle>
                        <line x1="32.5" y1="50" x2="67.5" y2="50" stroke-width="7"></line>
                      </svg>
                    </div>
                  </div>

                  <!-- Input Field Amount / Weight -->
                  <div class="col-4 mainColor noPadding align-content-center">
                    <!-- TODO: NEW FIELD! -->
                    <input v-if="config.SHOW_BASKET_QUANTITY_INPUT" class="qtyInput" @change="changeItemQuantity($event,item.prodId)" type="number" :value="item.weightOrVolume != undefined ? item.weightOrVolume * 1000 : item.quantity"/>
                    <div v-if="!config.SHOW_BASKET_QUANTITY_INPUT"> {{ item.weightOrVolume != undefined ? item.weightOrVolume * 1000 : item.quantity }} {{item.weightOrVolume != undefined ? 'g' : 'x'}}</div>
                  </div>

                  <!-- "Add 1" Button -->
                  <div class="col-4 noPadding align-content-center">
                    <div :class="(item.weightOrVolume != undefined) && quantityButtonDisabled" @click="(item.weightOrVolume != undefined) ? weightAlert() : addItemQuantity(item.id, item.quantity)">
                      <svg class="addIcon" viewBox="0 0 100 100">
                        <circle cx="50" cy="50" r="45" fill="none" stroke-width="5.5"></circle>
                        <line x1="32.5" y1="50" x2="67.5" y2="50" stroke-width="7"></line>
                        <line x1="50" y1="32.5" x2="50" y2="67.5" stroke-width="7"></line>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <!-- / Basket Item -->
            </div>
            <!-- / Basket List -->

            <div class="col-12 d-flex flex-wrap" v-if="subsidy != undefined && !(subsidy === '')">
              <div class="my-auto mx-sm-auto" style="font-size:14px;">{{$t('text.subsidyShortDescription')}}</div>
              <div class="my-auto ml-auto" style="font-size:18px; padding:2px;">{{subsidy}} </div>
            </div>

            <div class="col-12 d-flex flex-wrap" aria-level="3">
              <div class="my-auto mx-sm-auto" style="font-size:18px;">{{$t('sum')}}:</div>
              <div class="my-auto ml-auto" style="font-size:25px; padding:2px;">{{basketTotal}} </div>
            </div>

            <b-button class="btn btn-primary btn-secondary m-0 mt-2" style="width:100%;text-align:start;" @click="checkout()" v-if="basketItems && basketItems.length != 0"> {{ $t('button.proceed') }}</b-button>
          </div>
        </div>
      </div>
      <!-- / Right Colum -->
    </div>

    <!-- Basket Button -->
    <!-- lg none =} hide on large+ ; col =} use padding on both sides -->
    <!-- <GoToBasketButton :product-count="getCurrentOrderBasket.productCount" :total-price="getCurrentOrderBasket.totalPrice" :hidden="!basketItems" v-collapse:100="!basketItems"/> -->
    <GoToBasketButton :product-count="getCurrentOrderBasket.productCount" :total-price="getCurrentOrderBasket.totalPrice" :hidden="!basketItems" v-collapse:100="false" class="mt-1 d-lg-none col" counter-small/>
  </div>
</template>

<script>
import ArticleCard from '../main-components/ArticleCard.vue'
import VueLoading from 'vue-loading-overlay'
import ArticlePage from '../page-components/ArticlePage.vue'
import $ from 'jquery'
import Utils from "@/utils/utils";
import {nextTick} from "vue";
import {productService} from "../../services/api/productService";
import {basketService} from "../../services/api/basketService";
import {useCustomerStore} from "../../stores/customer";
import {useOrderStore} from "../../stores/order";
import {useBasketStore} from "@/stores/basket";
import {useAuthStore} from "@/stores/auth";
import {getShortOrderType} from "@/utils/orderUtils";
import {Mode} from "@/constants/constants";
import {accountService} from "@/services/api/accountService";
import {useAppStore} from "@/stores/app";
import GoToBasketButton from "@/components/sub-components/GoToBasketButton.vue";
import {useShopStore} from "@/stores/shop";

export default {
  setup() {
    const orderStore = useOrderStore();
    const customerStore = useCustomerStore();
    const basketStore = useBasketStore();
    const authStore = useAuthStore();
    const appStore = useAppStore();
    const shopStore = useShopStore();

    return {
      orderStore,
      customerStore,
      basketStore,
      authStore,
      appStore,
      shopStore
    };
  },
  computed: {
    getCurrentOrderBasket() {
      if (!this.basketItems) {
        console.warn("No valid selected meal plan found.");
        return { productCount: 0, totalPrice: 0.00 }; // Ensure valid structure
      }

      const order = this.orderStore.getOrder(this.appStore.currentOrderId);
      if (!order) {
        console.warn(`Order not found for ID: ${this.appStore.currentOrderId}`);
        return { productCount: 0, totalPrice: 0.00 }; // Return a valid empty structure
      }

      // Sum up the total quantity of all products
      const totalQuantity = order.products.reduce((sum, product) => sum + (product.quantity || 0), 0);

      return {
        productCount: totalQuantity,  // Total quantity of all products
        totalPrice: order.totalPrice || 0.00, // Total price
      };
    },
    getDietaryInfo() {
      return this.customerStore.getDietaryInfo;
    },
    config(){
      return window.config;
    }
  },
  data() {
    return {
      articlePageKey: 0,
      categories: [],
      offset: 0,
      articleWebDescription: "",
      limit: 20,
      searchPhrase:"",
      whereToSearch: 0,
      productsLoaded: false,
      currentCategory: {
        id : 0  ,
        children: []
      },
      articleOutOfStock:"articleCardDisabled",
      currentSubCategory: {
        id : 0  ,
        children: []
      },
      currentSubSubCategory: {
        id : 0  ,
        children: []
      },
      productArray:[
        {
          id: 0
        }
      ],
      basketItems: [],
      basketTotal: "0.00",
      selectedArticle:{},
      selectedArticleIsOnStock: false,
      articleSelected: false,
      quantityButtonDisabled: 'qtyBtnDisabled',
      activeGroupClass : "activeCategory",
      currentlyLoadingProducts: false,
      isLoading: false,
      subsidy: "",
      dietaryInfo: []
    }
  },
  inject:['setPageMetaTitle'],
  components: {
    GoToBasketButton,
    'article-card': ArticleCard,
    'loading': VueLoading,
    'article-page': ArticlePage,
  },
  watch: {
    '$i18n.locale': function(newVal, oldVal) {
      if(newVal != oldVal)
      {
        this.loadCategories();
      }
    },
  },
  async created() {
    await this.setPageMetaTitle(this.shopStore.getShopName);

    if (window.config.LAZY_LOAD_LIMIT !== undefined && window.config.LAZY_LOAD_LIMIT > 0) {
      this.limit = window.config.LAZY_LOAD_LIMIT;
    }
    if (window.config.USE_ALLERGEN_FILTERS && this.getDietaryInfo) {
      this.dietaryInfo = this.getDietaryInfo;
    }

    await this.loadCategories();
    // await orderService.calculateOrder(this.orderStore.orderType);

    const result = await accountService.afterLogin();

    setTimeout(() => {
      this.basketTile = window.shoppingTile;
      if (window.shoppingTile != undefined) {
        this.basketTotal = window.shoppingTile.formattedTotal;
      }
      this.basketItems = window.shoppingCart;
    }, 300);

    if (result.customer != undefined) {
      if (result.customer.type != undefined) {
        if (result.customer.type == 2) {
          window.isGuestCheckout = true;
        }
      }
    }

    this.$forceUpdate();

    $(".header").removeClass("header-big");
    $(".header").addClass("animTransition");
    $(".content").removeClass("header-big-content-margin");
    $(".content").addClass("header-small-content-margin");
    $("#content-wrapper").scrollTop = 0;
    $("#app").addClass("hideScroll");

    $(".content").bind("scroll", function (event) {
      this.handleScrollContent(event);
    });

    $("#content-wrapper").bind("scroll", function (event) {
      this.handleScrollContent(event);
    });

    $("#searchField").on('keyup', function (e) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        this.doSearch();
      }
    });

    setTimeout(() => {
      //this.updateShoppingCart();
      setTimeout(() => {
        this.basketTile = window.shoppingTile;
        this.basketItems = window.shoppingCart;
        this.subsidy = window.subsidy;
      }, 300);
    }, 300);
  },
  methods:{
    async loadCategories() {
      this.isLoading = true;
      const result = await productService.getCategories();

      this.categories = result.categories;
      if (this.currentCategory.id > 0) {
        if (this.currentSubCategory.id > 0) {
          this.showSubCat(this.currentSubCategory.id);
        } else {
          this.showCat(this.currentCategory.id);
        }
      } else if (this.categories?.length) {
        this.showCat(this.filterCategories(this.categories)[0].id);
      }
      this.isLoading = false;
    },
    isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
          !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    async removeItemQuantity(cartId, quantity) {
      this.isLoading = true;
      const newQuantity = quantity - 1;
      const result = await basketService.editBasket(cartId, newQuantity, this.orderStore.getOrderTypeById(this.appStore.currentOrderId));
      if (result.exception !== undefined) {
        if (result.exception.code === 64) {
          this.makeToast('danger', this.$t('notification.increaseItemQuantityMinError'));
          return;
        } else if (result.exception.code === 65) {
          this.makeToast('danger', this.$t('notification.increaseItemQuantityMaxError'));
          return;
        }
        this.makeToast('danger', this.$t('notification.addItemFailed'));
      }
      this.isLoading = false;


      window.shoppingCart = result.basketTile.basketItems;
      this.basketItems = result.basketTile.basketItems;
      this.basketTotal = result.basketTile.formattedTotal;

      if (result.basketTile.paymentsList != undefined) {
        this.subsidy = this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
        window.subsidy = this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
      } else {
        this.subsidy = "";
        window.subsidy = ""
      }

      this.orderStore.removeProductFromOrder(this.appStore.currentOrderId, cartId, this.currentCategory.id, ).addItem(cartId, newQuantity);
    },
    async addItemQuantity(cartId, quantity) {
      this.isLoading = true;
      await this.basketStore.editBasketItem(cartId, quantity + 1, this.orderStore.getOrderTypeById(this.appStore.currentOrderId), undefined, this.makeToast);
      this.isLoading = false;
    },
    async changeItemQuantity(element, articleId) {
      var value = element.srcElement.value;
      if (!this.isNumeric(value)) {
        this.makeToast('danger', this.$t('notification.inputQuantityNotValid'));
        element.srcElement.value = "1";
        return;
      }

      var cartId = 0;
      if (this.basketItems != undefined) {
        for (var i = 0; i < this.basketItems.length; i++) {
          if (this.basketItems[i].prodId == articleId) {
            cartId = this.basketItems[i].id;
          }
        }
      }

      this.isLoading = true;
      await this.basketStore.editBasketItem(cartId, value, this.orderStore.getOrderTypeById(this.appStore.currentOrderId), undefined, this.makeToast);
      if (value == 0) {
        await this.deleteItem(articleId, element);
        return;
      }
      this.isLoading = false;

      await this.orderStore.updateProductFromOrder(this.appStore.currentOrderId, cartId, value);
    },
    weightAlert(){
      this.makeToast('danger', this.$t('notification.weightArticleQuantityChange'))
    },
    getCurrentGroupTitle(){
      if(this.searchPhrase != "")
      {
        return this.$t('text.searchResults') + " '" + this.searchPhrase + "'";
      }

      if(this.currentSubSubCategory.id > 0)
      {
        return this.currentSubSubCategory.name;
      }

      if(this.currentSubCategory.id > 0)
      {
        return this.currentSubCategory.name;
      }

      if(this.currentCategory.id > 0)
      {
        return this.currentCategory.name;
      }
    },
    async closeArticlePage(total, refreshNeeded = false){
      if(total != "")
      {
        this.basketTotal = total;
      }
      var config = window.config;

      if (window.subsidy != undefined) {
        this.subsidy = window.subsidy;
      }else{
        this.subsidy = "";
      }

      await nextTick();
      this.$refs.articleDetailsModal.hide(); // closeModal
      this.basketItems = window.shoppingCart;
      this.$forceUpdate();
      await nextTick();

      if(refreshNeeded && config.FORCE_SHOW_ARTICLE_DETAILS_ON_ADD)
      {
        console.log("refresh needed");
        //this.reloadProducts();
      }
    },
    filterCategories(categories){
      if(Array.isArray(categories))
      {
        var filtered = categories.filter(function(x) { return x.numberOfProducts >= 0 })
            .sort((a, b) => ((a.sortOrder > b.sortOrder) && (a.sortOrder > 0 && b.sortOrder > 0)) ? 1 : -1)
            .sort((a, b) => (parseInt(a.custom3) > parseInt(b.custom3) ) ? 1 : -1);

        return filtered;
      }
      else
      {
        return categories;
      }

    },
    async loadMoreProducts() {
      var config = window.config;
      this.currentlyLoadingProducts = true;
      this.offset += this.limit;

      let categoryId = this.currentSubSubCategory.id > 0 ? this.currentSubSubCategory.id : (this.currentSubCategory.id > 0 ? this.currentSubCategory.id : this.currentCategory.id);
      const result = await productService.getProducts(this.limit, this.offset, config.ORDER_PRODUCTS_BY, false, true,
        false, false, false, this.getDietaryInfo, true,
        true, getShortOrderType(this.orderStore.orderType), this.searchPhrase, this.whereToSearch, categoryId);

      this.productArray.push(...result.products.productArray);
      this.currentlyLoadingProducts = false;
      window.imageBaseUrl = result.imageBaseURL;
    },
    clearSearch(){
      this.searchPhrase = "";
      this.doSearch();
    },
    doSearch()
    {
      this.offset = 0;
      if(this.searchPhrase == "")
        this.whereToSearch = 0;
      else
        this.whereToSearch = -99;

      console.log("searching for: " + this.searchPhrase);
      this.getAndFillProductsFromCategory(this.currentCategory);

    },
    checkout(){
      if (this.appStore.isMode(Mode.IMMEDIATE)) {
        this.$navigateToGuest(true);
      } else {
        this.$navigateToOrderConfirmation();
      }
    },
    previousArticle(article){
      // console.log("previous article");
      for(var i = 1; i < this.productArray.length; i++)
      {
        if(this.productArray[i].id == article.kkProdId)
        {
          this.getSelectedArticleInfo(this.productArray[i-1]);
          this.selectedArticle = this.productArray[i-1];
          this.articleSelected = true;
          this.articlePageKey++;
        }
      }
    },
    nextArticle(article){
      for(var i = 0; i < this.productArray.length - 1; i++)
      {
        if(this.productArray[i].id == article.kkProdId)
        {
          this.getSelectedArticleInfo(this.productArray[i+1]);
          this.selectedArticle = this.productArray[i+1];
          this.articleSelected = true;
          this.articlePageKey++;
        }
      }
    },
    async getSelectedArticleInfo(article) {
      this.loading = true;
      const result = await productService.getProductDetails(article.id, false, false, true, true, true);
      this.loading = false;

      if ((result.exception !== undefined) || (result.item !== undefined)) {
        this.makeToast('danger', this.$t('notification.getItemFailed'));
        return;
      }

      if (result.product.recipe != undefined) {
        var quantityParsed = parseInt(result.product.recipe);
        if (quantityParsed > 0) {
          this.selectedArticleIsOnStock = true;

        } else {
          this.selectedArticleIsOnStock = false;

        }
      } else {
        this.selectedArticleIsOnStock = true;

      }

      if (result.product.locked) {
        this.selectedArticleIsOnStock = false;
      }

      this.articleWebDescription = result.product.name;
    },
    articlePage(article, quantity, text) {
      this.selectedArticle = article;
      this.articleSelected = true;

      if(text != undefined)
        this.articleWebDescription = text;

      if(quantity > 0)
        this.selectedArticleIsOnStock = true;

      this.$refs.articleDetailsModal.show(); // openModal
    },
    selectWeightArticle(article, quantity) {
      this.articlePage(article, quantity);
    },
    async selectArticle(articleId, price, element) {
      this.isLoading = true;
      const result = await basketService.addProductToBasket(articleId, this.orderStore.getOrderTypeById(this.appStore.currentOrderId), this.authStore.isAuthenticated, 1, undefined);
      if ((result.exception !== undefined) || (result.item !== undefined)) {
        if (result.exception !== undefined)
          this.makeToast('danger', this.$t('notification.addItemFailed'));
        else
          this.makeToast('danger', this.$t('notification.addItemFailed'));
        return;
      }
      this.isLoading = false;

      window.shoppingCart = result.basketTile.basketItems;
      this.basketItems = result.basketTile.basketItems;
      this.basketTotal = result.basketTile.formattedTotal;


      if (result.basketTile.paymentsList != undefined) {
        this.subsidy = this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
        window.subsidy = this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
      } else {
        this.subsidy = "";
        window.subsidy = "";
      }
      if (element != undefined)
        element.shopCartQuantity = element.shopCartQuantity + 1;

      await this.orderStore.addProductToOrder(this.appStore.currentOrderId, articleId, price, 1);
    },
    async deleteItem(articleId, element) {

      var elementCartId = 0;
      if (window.shoppingCart != undefined) {
        for (var i = 0; i < window.shoppingCart.length; i++) {
          if (window.shoppingCart[i].prodId == articleId) {
            elementCartId = window.shoppingCart[i].id;
          }
        }
      }

      this.isLoading = true;
      const result = await basketService.removeProductFromBasket(elementCartId, this.orderStore.getOrderTypeById(this.appStore.currentOrderId));
      this.isLoading = false;

      if (result.exception !== undefined) {
        this.makeToast('danger', this.$t('notification.removeItemFailed'));
        return;
      }

      window.shoppingCart = result.basketTile.basketItems;
      this.basketItems = result.basketTile.basketItems;
      this.basketTotal = result.basketTile.formattedTotal;
      if (result.basketTile.paymentsList != undefined) {
        this.subsidy = this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
        window.subsidy = this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
      } else {
        this.subsidy = "";
        window.subsidy = "";
      }
      this.$forceUpdate();

      if (element != undefined) {
        element.shopCartQuantity = 0;
        this.$forceUpdate();
      } else {
        if (!window.config.FORCE_SHOW_ARTICLE_DETAILS_ON_ADD) {
          this.productsLoaded = false;

          setTimeout(() => {
            this.productsLoaded = true;
          }, 10);
        }
      }
    },
    async removeArticle(articleId, element) {
      this.isLoading = true;

      let cartId = 0;
      let currentCartQuantity = 0;

      if (this.basketItems != undefined) {
        for (var i = 0; i < this.basketItems.length; i++) {
          if (this.basketItems[i].prodId == articleId) {
            cartId = this.basketItems[i].id;
            currentCartQuantity = this.basketItems[i].quantity;
          }
        }
      }

      let newCartQuantity = currentCartQuantity - 1;
      const result = await basketService.removeProductFromBasket(cartId, newCartQuantity, this.orderStore.getOrderTypeById(this.appStore.currentOrderId));
      if (result.exception !== undefined) {
        if (result.exception.code === 64) {
          this.makeToast('danger', this.$t('notification.removeItemMinError'));
          return;
        } else if (result.exception.code === 65) {
          this.makeToast('danger', this.$t('removeItemMaxError'));
          return;
        }
        this.makeToast('danger', this.$t('notification.removeItemFailed'));
        return;
      }
      this.isLoading = false;

      window.shoppingCart = result.basketTile.basketItems;
      this.basketItems = result.basketTile.basketItems;
      this.basketTotal = result.basketTile.formattedTotal;

      if (result.basketTile.paymentsList != undefined) {
        this.subsidy = this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
        window.subsidy = this.formatCurrency(result.basketTile.paymentsList[0].paymentAmount);
      } else {
        this.subsidy = "";
        window.subsidy = "";
      }
      if (element != undefined)
        element.shopCartQuantity = newCartQuantity;

      await this.orderStore.removeProductFromOrder(this.appStore.currentOrderId, articleId, 1);

      if (newCartQuantity === 0) {
        await this.deleteItem(articleId, element);
      }
    },
    checkItemCart(prodId) {
      if(this.basketItems != undefined)
      {
        for(var i = 0; i < this.basketItems.length; i++)
        {
          if(this.basketItems[i].prodId == prodId)
          {
            return this.basketItems[i].quantity;
          }
        }
      }

      return 0;
    },
    async getAndFillProductsFromCategory(category) {
      var config = window.config;
      this.isLoading = true;
      this.productsLoaded = false;

      let categoryId = this.searchPhrase != "" ? undefined : category.id;
      const result = await productService.getProducts(this.limit, this.offset, config.ORDER_PRODUCTS_BY, true, true,
        true, true, true, this.getDietaryInfo, true,
        true, getShortOrderType(this.orderStore.orderType), this.searchPhrase, this.whereToSearch, categoryId);

      this.productArray = result.products.productArray;
      this.productsLoaded = true;
      window.imageBaseUrl = result.imageBaseURL;
      this.checkOverflow();
      this.checkOverflowSub();
      this.checkOverflowSubSub();
      this.$forceUpdate();
      this.isLoading = false;

      // TODO orders store adjust pickupTime if necessary
      // this.orderStore.setCurrentOrder(this.orderStore.getOrderTypeById(this.appStore.currentOrderId), result.pickupTime);
    },
    isArticleFirst(){
      if(this.productArray.indexOf(this.selectedArticle) == 0)
        return true;
      else
        return false;
    },
    isArticleLast(){
      if(this.productArray.indexOf(this.selectedArticle) == (this.productArray.length-1))
        return true;
      else
        return false;
    },
    resetFilters(){
      var myDiv = document.getElementById('contentSection');
      $("#contentSection").scrollTop(0);
      $("#app").scrollTop(0);

      if(myDiv != undefined)
      {
        myDiv.scrollTop = 0;
      }

      this.offset=0;
      this.searchPhrase = "";
      this.currentlyLoadingProducts = false;

    },
    showCat(categoryId) {
      this.resetFilters();
      this.currentCategory = this.categories.filter(function(x) { return x.id == categoryId })[0];
      this.currentSubCategory = {};
      this.getAndFillProductsFromCategory(this.currentCategory);
      var element = document.getElementById("menuSubCategoriesWrapper");
      var subelement = document.getElementById("menuSubSubCategoriesWrapper");
      if (subelement !== undefined && element !== undefined) {
        subelement.style.display = "none";
        if (this.currentCategory.children == undefined)
        {
          element.style.display = "none";
        }
        else
        {
          element.style.display = "block";
        }
      }
    },
    showSubCat(categoryId) {
      this.resetFilters();
      var catWithChildren = this.categories.filter(function(x) { return x.children != undefined });
      this.currentSubSubCategory = {
        id:0,
        children: []
      };
      for(var i = 0; i < catWithChildren.length; i++ )
      {
        var subCats = catWithChildren[i].children.filter(function(x) { return x.id == categoryId });
        if(subCats.length > 0)
        {
          this.currentSubCategory = subCats[0];
          this.getAndFillProductsFromCategory(this.currentSubCategory);
          var element = document.getElementById("menuSubSubCategoriesWrapper");

          if(this.currentSubCategory.children == undefined)
          {
            element.style.display = "none";
          }
          else
          {
            element.style.display = "block";
          }
        }
      }
    },
    showSubSubCat(categoryId) {
      this.resetFilters();
      var catWithChildren = this.currentSubCategory;
      var subCats = catWithChildren.children.filter(function(x) { return x.id == categoryId });

      if(subCats.length > 0)
      {
        this.currentSubSubCategory = subCats[0];
        this.getAndFillProductsFromCategory(this.currentSubSubCategory);
      }
    },
    formatCurrency(value){
      var formatter = new Intl.NumberFormat(this.config.PRICE_NUMBER_FORMAT, {style: 'currency', currency: window.config.SUBSIDY_CURRENCY});
      return formatter.format(value);
    },
    minimizeHeader() {
      $(".header").removeClass("header-big");
      $(".header").addClass("animTransition");
      $(".content").removeClass("header-big-content-margin");
      $(".content").addClass("header-small-content-margin");
    },
    trimDescriptionShort(description){
      if(description == undefined)
      {
        return "";
      }

      var config = window.config;
      var trimTo = config.TRIM_ARTICLE_DESCRIPTION_TO_SHORT;

      if(description.length <= trimTo)
      {
        return description;
      }

      return description.substring(0, trimTo) + "...";
    },
    handleScrollContent(event){
      try {
        var elem = $(event.currentTarget);
        if (elem[0].scrollHeight - elem.scrollTop() <= (elem.outerHeight() * 2.35 ) && !this.currentlyLoadingProducts) {
          console.log("LOADING MORE PRODUCTS!");
          this.loadMoreProducts();
        }
      }
      catch (exception_var) {
        console.log(exception_var);
      }
    },
    handleWheelContent(event) {
      if (Utils.isGreater(event.originalEvent.deltaY, 0))
      {
        if($(".header").hasClass("header-big"))
        {
          var myDiv = document.getElementById('content-wrapper');
          myDiv.scrollTop = 0;
          this.minimizeHeader();
        }
      }
    },
    scrollSubCategories(selector){
      var leftPos = $('#menuSubCategories').scrollLeft();
      var newLeftPos = 0;
      if(selector > 0)
        newLeftPos = leftPos + 350;
      else
        newLeftPos = leftPos - 350;

      $("#menuSubCategories").animate({ scrollLeft: newLeftPos }, 100);
    },

    scrollSubSubCategories(selector){
      var leftPos = $('#menuSubSubCategories').scrollLeft();
      var newLeftPos = 0;
      if(selector > 0)
        newLeftPos = leftPos + 350;
      else
        newLeftPos = leftPos - 350;

      $("#menuSubSubCategories").animate({ scrollLeft: newLeftPos }, 100);
    },

    checkOverflow()
    {
      if($("#menuCategories")[0] == undefined)
      {
        return;
      }

      if($("#menuCategories")[0].scrollWidth + 20 < $("#menuCategories").width() || $("#menuCategories")[0].scrollWidth + - 20 < $("#menuCategories").width())
      {
        $(".mainCategoryScrollIcon").css("display", "none");
      }
      else
      {
        $(".mainCategoryScrollIcon").css("display", "block");
      }
    },
    checkOverflowSub()
    {
      if($("#menuSubCategories")[0] == undefined)
      {
        return;
      }

      if($("#menuSubCategories")[0].scrollWidth + 10 < $("#menuSubCategories").width() || $("#menuSubCategories")[0].scrollWidth + -10 < $("#menuSubCategories").width())
      {
        $(".subCategoryScrollIcon").css("display", "none");
      }
      else
      {
        $(".subCategoryScrollIcon").css("display", "block");
      }
    },
    checkOverflowSubSub()
    {
      if($("#menuSubSubCategories")[0] == undefined)
      {
        return;
      }

      if($("#menuSubSubCategories")[0].scrollWidth + 10 < $("#menuSubSubCategories").width() || $("#menuSubSubCategories")[0].scrollWidth + -10 < $("#menuSubSubCategories").width())
      {
        $(".subSubCategoryScrollIcon").css("display", "none");
      }
      else
      {
        $(".subSubCategoryScrollIcon").css("display", "block");
      }
    }
  },
  // eslint-disable-next-line no-unused-vars
  beforeRouteLeave (to, from , next) {
    $("#app").removeClass("hideScroll");
    $(".content").unbind("scroll");

    next();
  },
}
</script>

<style scoped>
#content-wrapper{
  /* overflow-y:hidden !important; */
}

@media screen and (max-width: 1500px) {
  #bar-click {
    display:block;
  }
}
@media screen and (min-width: 1500px) {
  #bar-click {
    display:none;
  }
}

#menuCategories{
  background-color:var(--background-color);
  display: flex;
  color: gray;
  text-align:start;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  & div {
    display:flex;
    min-width:fit-content;
    padding: 5px 15px;
  }
}

#menuSubCategories{
  background-color:var(--background-color);
  display: flex;
  color: gray;
  text-align:start;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  padding-bottom: 8px;
  & div {
    min-height:30px;
    display:flex;
    min-width:fit-content;
    padding: 5px 15px;
  }
}

#menuSubSubCategories {
  background-color:var(--background-color);
  display: flex;
  color: gray;
  text-align:start;
  flex-wrap: nowrap;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  & div {
    min-height:30px;
    display:flex;
    min-width:fit-content;
    padding-left:15px;
    padding-right:15px;
  }
}

#menuProducts {
  background-color: var(--products-area-color);
}

#menuCart{
  /*padding:0px 5px 0px 15px;*/
}

@media  screen and (max-width:1000px) {
  #menuCart{
    display:none;
  }
}

.categoryScrollIcon{
  padding:10px 0 12px 0;
  width:3%;
}

.subCategoryScrollIcon{
  padding:0 0 0 0;
}

@media  screen and (max-width:770px) {
  .categoryScrollIcon{
    display:none !important;
  }
}

.groupTitle {
  background-color: var(--products-area-color);
  text-align:left;
  margin-bottom:0;
  text-transform: var(--categories-text-transform);
  padding-left: 10px;
  padding:10px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}

.shoppingCartSectionTitle{
  border-bottom: 3px solid var(--body-color);
  color:var(--main-color);
  font-weight:700;
  font-size:22px;
  width:100%;
  padding:3px;
  margin-bottom:5px;
}

.addIcon, .minusIcon{
  height:21px;
  stroke:var(--main-color-highlight);
}

.addIcon line, .minusIcon line{
  fill: white;
  stroke: white;
}

.addIcon circle, .minusIcon circle{
  fill: var(--main-color);
  stroke: var(--main-color);
}

.addIcon:hover circle, .minusIcon:hover circle{
  fill: white;
  stroke: var(--main-color);
}

.addIcon:hover line, .minusIcon:hover line{
  fill: white;
  stroke: var(--main-color);
}

.shoppingCartSection{
  padding:10px;
  box-shadow:var(--box-shadow);
}

.menuCartElement{
  /*height: 100px;*/
  padding-top: 7px;
  padding-bottom: 7px;
  border-bottom: 0.1em solid var(--body-color);
}

.searchContainer {
  height: 35px;
}

#searchField:focus{
  border:none !important;
  box-shadow:none !important;
}

.btnSearchField {
  vertical-align: unset;
  top: 50%;
  transform: translate(0, -50%);
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
}

.qtyInput {
  width: 100%;
  /*max-width: unset;*/
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.qtyInput{
  border: 0.2px solid var(--main-color);
}

.addIcon{
  padding-left:10px;
  padding-right:10px;
  float: right;
}

.minusIcon{
  float: left;
}

</style>
