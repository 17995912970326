import moment from "moment";
import {Currencies} from "@/constants/constants";

export function capitalizeFirstLetter(str) {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default class Utils {
    static formatCurrency(amount, currency, withSymbol = true, balanceSeparator = '.') {
        // Ensure the amount is a number with two decimal places
        const formattedAmount = parseFloat(amount).toFixed(2).replace('.', balanceSeparator);

        if (!withSymbol) {
            return formattedAmount;
        }

        // Handle formatting based on currency
        switch (currency) {
            case Currencies.EUR:
                return `${formattedAmount} €`;  // EUR after amount
            case Currencies.CHF:
                return `CHF ${formattedAmount}`; // CHF before amount
            default:
                return `${formattedAmount} ${currency}`; // Default to currency after amount
        }
    }

    static adjustInnerScrollHeight() {
        const innerScroll = document.querySelector('.innerScroll');
        const siteHeader = document.getElementById('siteHeader');
        const subPageHeader = document.querySelector('.subPageHeader');
        const headerContainer = document.querySelector('.header-container');
        const footer = document.querySelector('.footer');

        const siteHeaderHeight = siteHeader ? siteHeader.offsetHeight : 0;
        const headerContainerHeight= headerContainer ? headerContainer.offsetHeight : 0;
        const subPageHeaderHeight = subPageHeader ? subPageHeader.offsetHeight : 0;
        const footerHeight = footer ? footer.offsetHeight : 0;


        const availableHeight = window.innerHeight - siteHeaderHeight - subPageHeaderHeight - headerContainerHeight - footerHeight;

        if (innerScroll) {
            innerScroll.style.setProperty('height', `${availableHeight}px`, 'important');
        }
    }

    static getCurrencySymbol(currency) {
        // Handle formatting based on currency
        switch (currency) {
            case Currencies.EUR:
                return `€`;  // EUR after amount
            case Currencies.CHF:
                return `CHF`; // CHF before amount
            default:
                return `CHF`; // Default to currency after amount
        }
    }

    static getCurrencySeparator(currency) {
        // Handle formatting based on currency
        switch (currency) {
            case Currencies.EUR:
                return `,`;  // EUR after amount
            case Currencies.CHF:
                return `.`; // CHF before amount
            default:
                return `.`; // Default to currency after amount
        }
    }

    static getDecimalSeparator(formattedAmount = "0.00") {
        let lastDigitIndex = Utils.findDigitIndex(formattedAmount, formattedAmount.length - 1);

        if(lastDigitIndex >= 0)
        {
            let indexSeparator = Utils.findNoNDigitIndex(formattedAmount, lastDigitIndex);

            if(indexSeparator >= 0)
            {
                return formattedAmount.charAt(indexSeparator);
            }
        }

        //Default separator
        return '.'
    }

    static findDigitIndex(str, startIndex) {

        for (let i = startIndex; i >= 0; i--) {

            if (!isNaN(str[i]) && str[i] !== ' ') {
                return i;
            }
        }

        return -1;
    }

    static findNoNDigitIndex(str, startIndex) {

        for (let i = startIndex; i >= 0; i--) {

            if (isNaN(str[i])) {
                return i;
            }
        }

        return -1;
    }

    static parseTimeHHMMSS(timeStr) {
        let [hours, minutes, seconds] = timeStr.split(':');
        let date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(seconds);
        return date;
    }

    static isGreater(valueToCompare, value) {
        return valueToCompare != undefined && valueToCompare > value;
    }

    static isEmpty(value) {
        // Check for null or undefined
        if (value == null || value == undefined) return true;

        // Check for empty string
        if (typeof value === 'string' && value.trim() === '') return true;

        // Check for empty array
        if (Array.isArray(value) && value.length === 0) return true;

        // Check for empty object
        if (typeof value === 'object' && Object.keys(value).length === 0) return true;

        return false;
    }

    static getStartOfMonth(date) {
        return moment(date).startOf('month').format('YYYY-MM-DD');
    }

    static getEndOfMonth(date) {
        return moment(date).endOf('month').format('YYYY-MM-DD');
    }

    static getStartOfNextMonth() {
        return moment().add(1, 'months').startOf('month').format('YYYY-MM-DD');
    }

    static getEndOfNextMonth() {
        return moment().add(1, 'months').endOf('month').format('YYYY-MM-DD');
    }

    static getStartOfPreviousMonth() {
        return moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
    }

    static getEndOfPreviousMonth() {
        return moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
    }

    static getStartOfYear(date) {
        return moment(date).startOf('year').format('YYYY-MM-DD');
    }

    static getEndOfYear(date) {
        return moment(date).endOf('year').format('YYYY-MM-DD');
    }

    static getStartOfPreviousYear() {
        return moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
    }

    static getEndOfPreviousYear() {
        return moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
    }

    static getStartOfNextYear() {
        return moment().add(1, 'years').startOf('year').format('YYYY-MM-DD');
    }

    static getEndOfNextYear() {
        return moment().add(1, 'years').endOf('year').format('YYYY-MM-DD');
    }

    static getStartOfWeek(date) {
        return moment(date).startOf('week').format('YYYY-MM-DD');
    }

    static getEndOfWeek(date) {
        return moment(date).endOf('week').format('YYYY-MM-DD');
    }

    static getDateWithOffset(date, offset) {
        return moment(date).add(offset, 'days').format('YYYY-MM-DD');
    }

    static getCurrentDate() {
        return moment().format('YYYY-MM-DD');
    }

    static getStartOfDate(date) {
        return moment(date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
    }

    static getEndOfDate(date) {
        return moment(date).endOf('day').format('YYYY-MM-DD HH:mm:ss');
    }

    static getCurrentTime() {
        return moment().format('HH:mm');
    }

    static isDateBefore(date, comparisonDate) {
        const dateMoment = moment(date);
        const comparisonMoment = moment(comparisonDate);

        return dateMoment.isBefore(comparisonMoment, 'day'); // 'day' granularity for date comparison
    }

    static isDateBetween(date, startDate, endDate) {
        return moment(date).isBetween(startDate, endDate, null, '[]');
    }

    static isTimeBefore(time, comparisonTime) {
        const timeMoment = moment(time, 'HH:mm');
        const comparisonMoment = moment(comparisonTime, 'HH:mm');

        return timeMoment.isBefore(comparisonMoment);
    }

    static isTimeBetween(time, startTime, endTime) {
        const timeMoment = moment(time, 'HH:mm');
        const startMoment = moment(startTime, 'HH:mm');
        const endMoment = moment(endTime, 'HH:mm');

        // Check if time is between start and end time (inclusive)
        return timeMoment.isBetween(startMoment, endMoment, null, '[]');
    }

    static addMinutesToDate(date, mins) {
        // Create a moment object from the input date
        const parsedDate = moment(date);

        // Add the specified minutes
        const updatedMoment = parsedDate.add(mins, 'minutes');

        // Convert moment object to JavaScript Date object and return
        return updatedMoment.format('YYYY-MM-DDTHH:mm:ss')
    }

    static filterOpenDatesWithMinMax(data) {
        // Step 1: Filter where closed is false and map to relevant fields
        const filteredData = data
            .filter(item => !item.closed)
            .map(item => {
                return {
                    date: item.date,
                    timeslots: item.timeslots.map(slot => ({
                        fromTime: slot.fromtime,
                        toTime: slot.totime
                    }))
                };
            });

        // Step 2: Find the minimum and maximum dates
        const dates = filteredData.map(item => new Date(item.date));
        const minDate = new Date(Math.min(...dates));
        const maxDate = new Date(Math.max(...dates));

        // Step 3: Flag the min and max dates in the resulting data
        return filteredData.map(item => {
            const itemDate = new Date(item.date);
            return {
                ...item,
                isMinDate: itemDate.getTime() === minDate.getTime(),
                isMaxDate: itemDate.getTime() === maxDate.getTime(),
            };
        });
    }

    /** @param {Object | null} input */
    static isArray(input) {
       if (input === null || input === undefined || typeof input !== "object"){
         return false;
       }
       return input.constructor.name === 'Array' || input.length !== undefined;
    }
}
