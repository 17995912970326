<template>
  <div class="flex-grow-1 row noMargin">

    <!-- LABEL -->
    <div class=" col-12 shoppingCartEntryDescription" style="text-align:left;">
      <span v-html="(item.prodText !== undefined) ? item.prodText :item.text"></span>
      <div v-if="item.hasOptions" style="padding:0;text-align:left;">
        <span style="font-size:13px;padding:0;" v-html="item.optionText"></span>
      </div>
    </div>

    <!-- COMMENT FREETEXT -->
    <div v-if="item.freeText != undefined && item.freeText != ''" class=" col-12 shoppingCartEntryComment" style="text-align:left;">
      <span v-html="item.freeText"></span>
      <div v-if="item.hasOptions" style="padding:0;text-align:right;">
        <span style="font-size:13px;padding:0;" v-html="item.optionText"></span>
      </div>
    </div>

    <!-- DISCOUNT -->
    <div style="text-align: start;" v-if="item.discounts != undefined">
      <div v-for="discount in item.discounts" v-bind:key="discount.id">
        <div>
          <span style="padding-left:5%;width: 100%;display: block;text-align: start;">{{discount.description}}</span>
          <!-- <span class="shoppingCartDiscountsEntryValue">{{formatDiscount(discount.value)}}</span> -->
        </div>
      </div>
    </div>

    <!-- REMOVE -->
    <div class=" col-2 shoppingCartLine"  @click="callbackRemove(item)">
      <svg class="minusIcon" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="45" fill="none" stroke-width="5.5"></circle>
        <line x1="32.5" y1="50" x2="67.5" y2="50" stroke-width="7"></line>
      </svg>
    </div>

    <!-- QUANTITY FIELD -->
    <div v-if="!config.SHOW_BASKET_QUANTITY_INPUT" class=" col-3 mainColor shoppingCartLine" style="text-align:center">{{ item.weightOrVolume != undefined ? item.weightOrVolume * 1000 : item.quantity }} {{item.weightOrVolume != undefined ? 'g' : 'x'}}</div>
    <div v-if="config.SHOW_BASKET_QUANTITY_INPUT" class=" col-3 mainColor shoppingCartLine" style="text-align:center">
      <input class="qtyInput" @change="callbackEdit($event,item.id)" type="number" :value="item.weightOrVolume != undefined ? item.weightOrVolume * 1000 : item.quantity"/>
    </div>

    <!-- ADD -->
    <div class=" col-2 shoppingCartLine" :class="(item.weightOrVolume != undefined) && quantityButtonDisabled"  @click="item.weightOrVolume != undefined ? callbackAddWeight() : callbackAddNormal(item)">
      <svg class="addIcon" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="45" fill="none" stroke-width="5.5"></circle>
        <line x1="32.5" y1="50" x2="67.5" y2="50" stroke-width="7"></line>
        <line x1="50" y1="32.5" x2="50" y2="67.5" stroke-width="7"></line>
      </svg>
    </div>

    <!-- PRICE -->
    <div class=" col-3 mainColor shoppingCartLine" v-if="config.CART_ARTICLE_EXTRA_INFORMATION == true" style="padding-left: 0; padding-right: 0">
      <!-- if a discount IS NOT applied, there is no need to show two prices for the article -->
      <span v-if="item.formattedDiscountedPrice == undefined">
        {{ item.formattedTotalPrice != "" ? item.formattedTotalPrice : item.formattedPrice }}
      </span>
      <!-- if a discount IS applied, we show the old price crossed out, and above it the new Total price of the article -->
      <span v-if="item.formattedTotalDiscountedPrice != undefined">
        {{ item.formattedTotalDiscountedPrice}}<br/>
        <span style="color:gray;text-decoration: line-through;">{{ item.formattedTotalPrice != "" ? item.formattedTotalPrice : item.formattedPrice }} </span>
      </span>
    </div>

    <!-- DELETE -->
    <div class=" col-2 shoppingCartLine" v-if="config.CART_ARTICLE_EXTRA_INFORMATION == true" @click="callbackDelete(item)">
      <svg class="trashBinIcon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" focusable="false" width="24px" height="24px" style="-ms-transform: rotate(360deg); -webkit-transform: rotate(360deg); transform: rotate(360deg);" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path d="M9 3v1H4v2h1v13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6h1V4h-5V3H9m0 5h2v9H9V8m4 0h2v9h-2V8z" fill="gray"/></svg>
    </div>
  </div>
</template>



<script>
export default {
  data(){
    return {

    }
  },
  computed:{
    config(){
      return window.config;
    }
  },
  props:{
    item:{
      Type:Object,
      required:true
    },
    callbackRemove:{
      Type:Function
    },
    callbackDelete:{
      Type:Function
    },
    callbackAddNormal:{
      Type:Function
    },
    callbackAddWeight:{
      Type:Function
    },
    callbackEdit:{
      Type:Function
    }
  }
}
</script>


<style>
.shoppingCartEntryDescription {
  font-size:18px;
  font-weight:600;
  padding: 2px 12px 2px 12px !important;
}

.shoppingCartEntryComment {
  font-size:14px;
  font-weight:300;
  padding: 2px 12px 2px 12px !important;
  opacity:0.5;
}

.shoppingCartLine {
  padding-top:2px !important;
  padding-bottom:2px !important;
}
.addIcon{
  float:left;
}
.minusIcon{
  float:right;
}

.addIcon, .minusIcon{
  height:20px;
  stroke:var(--main-color-highlight);
}

.addIcon img {
  height:17px;
}

.addIcon line, .minusIcon line{
  fill: white;
  stroke: white;
}

.addIcon circle, .minusIcon circle{
  fill: var(--main-color);
  stroke: var(--main-color);
}

.addIcon:hover circle, .minusIcon:hover circle{
  fill: white;
  stroke: var(--main-color);
}

.addIcon:hover line, .minusIcon:hover line{
  fill: white;
  stroke: var(--main-color);
}

.trashBinIcon:hover path {
  fill:var(--main-color);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.qtyInput{
  border: 0.2px solid var(--main-color);
  max-width:60px;
}
</style>
