<template>
  <div id="mealPlans">
    <vue-loading v-if="isLoading" />

    <div id="mealPlan" v-if="reservation" class="d-flex gap10 flex-column">
      <div class="meal-container">
        <div class="meal-group">
          <h2 class="meal-group-title py-1">{{ $t('subTitle.yourOrder') }}</h2>
        </div>
        <div class="row-layout">
          <!-- Iterate through products -->
          <div v-for="product in reservation.items" :key="product.kkProdId">
            <div :id="product.kkProdId" class="meal-item">
              <img :src="product.image1" :alt="product.name" class="meal-image" />
              <div class="d-flex flex-column gap10 flex-grow-1 justify-content-between">
                <div class="meal-details" @click="viewProductDetails(product)">
                  <div class="meal-description">
                    <span>{{ product.name }}</span>
                    <div class="properties"></div>
                    <div class="allergens">
                      <div v-for="property in product.properties" :key="property.id">
                        <div>
                          <img :src="property.image" :alt="property.name" class="property-img" />
                        </div>
                      </div>
                      <div v-for="allergen in product.allergens" :key="allergen.id">
                        <div>
                          <img :src="allergen.image" :alt="allergen.name" class="allergen-img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="price" class="d-flex flex-row gap10 w-100 flex-wrap align-items-center">
                  <div
                      class="button"
                      @click="viewProductDetails(product)"
                      role="button"
                      tabindex="0"
                      aria-label="View product details"
                  >
                    <font-awesome-icon :icon="['fas', 'circle-info']" />
                  </div>
                  <span class="meal-quantity">x{{ product.quantity }}</span>
                  <span class="meal-price">{{ product.formattedPrice }}</span>
                  <span class="meal-item-total">{{ utils.formatCurrency(product.price * product.quantity, config.ACCOUNT_BALANCE_CURRENCY) }}</span>
                </div>
              </div>
            </div>
          </div>
          <div id="order-total" class="d-flex flex-row justify-content-between">
            <div style="background-color: red"
                class="button"
                @click="cancelOrder(reservation.guid)"
                role="button"
                tabindex="0"
                aria-label="Cancel order"
            >
              <font-awesome-icon :icon="['fas', 'ban']" />
            </div>
            <span>{{ $t('text.sum') }}:</span>
            <span class="total-amount">{{ utils.formatCurrency(reservation.total, config.ACCOUNT_BALANCE_CURRENCY) }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for product details -->
    <b-modal id="product-details-modal" hide-footer :title="selectedProduct ? selectedProduct.name : ''">
      <ProductDetails v-if="selectedProduct" :productId="selectedProduct.kkProdId" :viewMode="true" />
    </b-modal>
  </div>
</template>

<script>
import ProductDetails from "@/components/sub-components/ProductDetails.vue";
import {orderService} from "@/services/api/orderService";
import Utils from "../../../utils/utils";
import VueLoading from "vue-loading-overlay";
import {useShopStore} from "../../../stores/shop";

export default {
  name: "ReservationList",
  computed: {
    config() {
      return window.config
    },
    utils() {
      return Utils
    }
  },
  components: {
    VueLoading,
    ProductDetails,
  },
  data() {
    return {
      isLoading: false,
      selectedProduct: null,
    }
  },
  props: {
    reservation: {
      type: Object,
      required: true
    }
  },
  setup() {
    const shopStore = useShopStore();

    return {
      shopStore
    };
  },
  methods: {
    viewProductDetails(product) {
      this.selectedProduct = product;
      this.$bvModal.show("product-details-modal");
    },
    async cancelOrder(guid) {
      try {
        this.isLoading = true;  // Set loading state

        // Call the cancel order service
        const result = await orderService.cancelOrder(this.shopStore.wondId, guid, false);

        if (result.exception) {
          // Display error if cancelation fails
          this.makeToast("danger", this.$t("notification.cancelOrderError"));
        } else {
          this.$emit('reservation-canceled');
        }
      } catch (error) {
        // Handle any unexpected errors
        this.makeToast("danger", this.$t("notification.cancelOrderError"));
      } finally {
        this.isLoading = false;  // Reset loading state
      }
    },
  }
};
</script>

<style scoped lang="scss">
@import "@/css/customizer";


.meal-container {
  background-color: var(--page-container-background);
  border-radius: var(--button-border-radius);
  margin-top: 10px
}

.meal-group {
  background: custom-chain(mealplan-group-color-reservation,mealplan-group-color,var(--page-group-header));
  text-align: left;
  margin: 0 auto;
  padding: 0 10px;
  border-radius: var(--button-border-radius);
}

.meal-group-title {
  font-size: 20px;
  color: custom-chain(mealplan-group-color-text-reservation,mealplan-group-color-text,var(--page-group-title));
  display: flex;
  justify-content: space-between; /* Space between the two spans */
  align-items: center; /* Center the text vertically */
  width: 100%; /* Ensure it takes the full width */
}

.meal-item {
  display: flex;
  flex: 1 0 360px;
  gap: 10px;
  text-align: left;
  align-items: stretch;
  padding: 10px;
  border-radius: var(--button-border-radius);
  background: custom-chain(mealplan-group-background-reservation,mealplan-group-background,var(--page-group-item));
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition effect */
  height: 100%;
}

.meal-item:hover {
  background-color: #e0e0e0; /* Change background on hover */
  transform: scale(1.02); /* Slightly scale up the item */
  cursor: pointer; /* Add pointer cursor */
}

.meal-image {
  width: 120px;
  height: auto;
  margin-right: unset;
  border: custom-chain(mealplan-productimage-border,'');
  border-radius: custom-chain(mealplan-productimage-border-radius,images-border-radius,var(--button-border-radius));
  object-fit: cover;
}

.meal-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.meal-description {
  font-weight: bold;
  text-align: left;
}

.properties {
  filter: hue-rotate(45deg);
}

.allergens, .properties {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  margin-top: 5px;
}

.allergen-img,
.property-img {
  max-width: 30px;
}

.meal-price {
  color: indianred;
  font-size: 14px;
  align-self: center;
  flex-grow: 1;
  text-align: right;
}

.button {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  user-select: none; /* Prevent text selection */
  transition: background-color 0.3s; /* Smooth background change */
}

.button:hover,
.button:focus {
  background-color: #0056b3; /* Darker blue on hover/focus */
}

.button:active {
  background-color: #004494; /* Even darker on click */
}

.meal-item-total {
  font-weight: bold;
  color: custom-chain(mealplan-reservation-item-total,darkgreen);
  margin-left: auto;
}

.meal-quantity {
  font-size: 14px;
  color: custom-chain(mealplan-reservation-item-quantity,#555);
  margin-right: 10px;
}

#order-total {
  font-weight: bold;
  font-size: 18px;
  padding: 10px;
}

.total-amount {
  color: custom-chain(mealplan-reservation-total,darkred);
  font-size: 18px;
}
</style>
