import axiosClient from "@/apiClient";
import {extractSessionExpiry} from '@/store/SessionTimer'
import {useAuthStore} from "@/stores/auth";

export const apiUtils = {
    doGet(api, params) {
        return axiosClient.get(api, { params })
            .then(response => {
                const requiresLogin = response.data.requiresLogin !== undefined ? response.data.requiresLogin : false;
                console.log("get apiUtil " + api + ": " + requiresLogin);
                const authStore = useAuthStore();
                if (authStore.isLoggedIn) {
                    if (requiresLogin) {
                        authStore.setAuthenticated(false);
                        return;
                    }
                    extractSessionExpiry(response.headers['x-session-expiry']);
                }

                return response.data;
            })
            .catch(function (error) {
                console.error("Error fetching data:", error);
                throw error;
            });
    },
    doPost(api, params) {
        return axiosClient.post(api, params)
            .then(response => {
                const requiresLogin = response.data.requiresLogin !== undefined ? response.data.requiresLogin : false;
                console.log("post apiUtil " + api + ": " + requiresLogin);
                const authStore = useAuthStore();
                if (authStore.isLoggedIn) {
                    if (requiresLogin) {
                        authStore.setAuthenticated(false);
                        return;
                    }
                    extractSessionExpiry(response.headers['x-session-expiry']);
                }

                return response.data;
            })
            .catch(function (error) {
                console.error("Error posting data:", error);
                throw error;
            });
    }
}
