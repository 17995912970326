<template>
  <div id="template">
    <!-- dont add anything above here -->
    <div class="header-sticky noEars row">
      <!-- Subheader Like Element -->
      <div class="d-flex flex-grow-1 flex-nowrap px-3 def-main gap10" ref="headerText">
        <h2 class="text-left flex-grow-1 noMargin p-1 centerContent" style="font-size: 22px">Subheader Like Entity</h2>
        <Button class="flex-grow-0" preset-size>A [preset-size]</Button>
        <Button class="flex-grow-0" secondary preset-size>B [sc,preset-size]</Button>
      </div>

    </div>

    <FlexContainer title="Custom Title of First Container" #content>
      <!-- Custom titles and content for each container -->
      <span>This is the custom content for the first container.</span>
    </FlexContainer>

    <FlexContainer title="Custom Title for Second Container">
      <!-- Custom titles and content for each container -->
      <span>This is the custom content for the second container, but this time its without a wrapper, so the full size can be used.</span>
    </FlexContainer>

    <Dropdown
        label="Select an Option"
        :options="dropdownOptions"
        v-model="selectedOption"
        :required="true"
        placeholder="Choose an option..."
    />

    <div id="checkboxes">
      <Checkbox
          v-model="isChecked"
          label="I agree to the terms and conditions"
          checkboxSize="medium"
          :checkboxId="'termsCheckbox'"
      />
    </div>

    <div id="checkboxes">
      <MultiSelectDropdown :options="dropdownOptions" @update-options="handleUpdateOptions" />
    </div>

    <div id="alerts">
      <FlexAlert
          type="success"
          message="This is a success alert!"
          @close="handleClose('success')"
      />
      <FlexAlert
          type="error"
          message="This is an error alert!"
          @close="handleClose('error')"
      />
      <FlexAlert
          type="warning"
          message="This is a warning alert!"
          @close="handleClose('warning')"
      />
      <FlexAlert
          type="info"
          message="This is an info alert!"
          @close="handleClose('info')"
      />
    </div>

    <hr/>
    <h2 >Content Escaping Elements</h2>
    <div class="row pb-1"><Button>Button outside Container [no switches]</Button></div>
    <div><Button >Button in Container [no switches]</Button></div>

    <hr/>
    <h2 >Themed Buttons</h2>
    <div class="d-flex flex-row gap10 w-100">
      <div class="d-flex flex-column flex-nowrap gap10 w-100">
        <Button >Just a Button (no extras)</Button>
        <Button secondary>Button Secondary [secondary]</Button>
        <Button disabled>Disabled Button [disabled]</Button>
        <Button unstyled>Unstyled Button [unstyled]</Button>
        <Button icon="user" lg>Button (bigger) and Icon [icon=user,lg]</Button>
      </div>
      <div class="d-flex flex-column flex-nowrap gap10 w-100">
        <Button left >Button Left Aligned [left]</Button>
        <Button left secondary>Button Secondary and Left [secondary,left]</Button>
        <Button left disabled>Disabled Left Button [disabled,left]</Button>
        <Button left unstyled>Unstyled Left Button [unstyled,left]</Button>
        <Button left icon="user" lg>Left Button (bigger) and Icon [icon=user,left,lg]</Button>
      </div>
    </div>

    <hr/>
    <h2>Predefined Themes</h2>
    <div class="presized-grid-layout-4">
      <div v-for="(cls) in predef()" :key="cls" :class="{box:true,'centerContent':true,[cls]:true,'test-cc':true}" style="user-select: all;">
        .{{cls}}
        <br/>
        <small class="cc"></small>
<!--
        <pre class="text-left noMargin" style="color:inherit;user-select:text;width:60%;place-self: center;">
.&lt;class&gt; {
  @extend .{{cls}}
}</pre>
-->
      </div>
    </div>
    <hr/>
    <h2 class="">Composable Themes</h2>
    <div v-collapse:500="!isComponsableThemeOpen">
      <div class="presized-grid-layout-6" v-html="schemeGeneration()"></div>
    </div>
    <Button v-model="isComponsableThemeOpen" toggle collapsed class="mt-2">{{ isComponsableThemeOpen?'Hide':'Show' }}</Button>
    <hr/>
    <div class="text-left">
      <h2>Contrast Standarts</h2>
      <ul>
        <li>AAA = 4.5:1 (visualized green)</li>
        <ul>
          <li>Normal Text (&lt;18px / Bold &lt;14px)</li>
          <li>Interactable UI Components (Buttons & Icons)</li>
        </ul>
        <li>AA  = 3.0:1 (visualized orange)</li>
        <ul>
          <li>Large Text (&gt;18px / Bold &gt;14px)</li>
          <li>Non Interactable UI Components</li>
          <li>Non Text Components</li>
        </ul>
        <li>Garbage = &lt;3.0:1 (visualized red)</li>
        <ul>
          <li>Nothing (if Possible)</li>
        </ul>
      </ul>

    </div>
  </div>
</template>

<script>

import FlexContainer from "@/components/page-components/templates/FlexContainer.vue";
import FlexAlert from "@/components/page-components/templates/FlexAlert.vue";
import Dropdown from "@/components/barrier-free-template-components/Dropdown.vue";
import Checkbox from "@/components/page-components/templates/Checkbox.vue";
import MultiSelectDropdown from "@/components/page-components/templates/MultiSelectDropdown.vue";
import Button from "@/components/barrier-free-template-components/Button.vue"

export default {
  components: {
    FlexContainer,
    FlexAlert,
    Dropdown,
    Checkbox,
    MultiSelectDropdown,
    Button,
  },
  data() {
    return {
      groupLabel: 'Choose one of the options below',
      radioOptions: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
        { label: 'Option 3', value: 'option3' },
      ],
      selectedOption: '',
      dropdownOptions: [
        { value: 'option1', label: 'Option 1', selected: false },
        { value: 'option2', label: 'Option 2', selected: false },
        // Other options...
      ],
      options: [
        { value: 'option1', label: 'Option 1', selected: false },
        { value: 'option2', label: 'Option 2', selected: false },
        { value: 'option3', label: 'Option 3', selected: false },
      ],
      isChecked: false,
      isComponsableThemeOpen: false,
    };
  },
  methods: {
    handleClose(type) {
      console.log(`Closed ${type} alert`);
    },
    handleUpdateOptions(updatedOptions) {
      this.dropdownOptions = updatedOptions;
    },
    predef(){
      return ['main','focus','active','passive','secondary','normal'].flatMap(a=>[a,'inv-'+a]).flatMap(a=>'def-'+a);
    },
    schemeGeneration() {
      const colors = [ '--text-dark', '--main-color', '--focus-color', '--active-color', '--passive-color', '--secondary-color', '--text-light'];
      const size = colors.length;
      const template = (x,y) => "<div class=\"box fg-"+x+" bg-"+y+" test-cc\"><small>Text</small><br/>"+
        colors[x-1]+
        "<hr class='my-1'/><small>Background</small><br/>"+
        colors[y-1]+
        "<br/><small class='cc'></small>"+
      "</div>";
      let res = "";
      for (let i = 1; i <= size; i++) {
        for (let j = 1; j <= size; j++) {
          if (j==i){continue;}
          res += template(j,i);
        }
      }
      return res;
    },
  },
  setup(){
    function rgba(fg,a,bg=[255,255,255]){let out=[0,0,0];for(let i=0;i<3;i++){out[i]=((1-a)*bg[i])+(a*fg[i]);}return out;}
    function relativeLuminance(rgb){const srgb =[0,0,0];const RGB =[0,0,0];const coeff =[.2126,.7152,.0722];let L =0;for (let i=0;i<3;i++){srgb[i]=rgb[i]/255;RGB[i]=srgb[i] <=0.03928 ?srgb[i]/12.92 :Math.pow((srgb[i]+0.055)/1.055,2.4);L +=coeff[i] *RGB[i];}return L;}
    function contrastRatio(rgb1,rgb2){const L1 =relativeLuminance(rgb1);const L2 =relativeLuminance(rgb2);const contrast_ratio =L1 >L2 ?(L1 +0.05)/(L2 +0.05) :(L2 +0.05)/(L1 +0.05);return contrast_ratio;}
    function toColor(a){return Array.from(a.matchAll(/\d+/g),a=>a[0]-0);}
    function resolveProp(a,b='background-color'){let c=toColor(a.computedStyleMap().get(b).toString());let op=(typeof c[3]==='undefined'?1:c[3])*a.computedStyleMap().get('opacity').value;if(op==1){return c;}else{let pc=resolveProp(a.parentElement,b);if(op==0)return pc;return rgba(c,op,pc)}}
    function calc(){
      if (typeof document.body.computedStyleMap == 'function'){
        Array.from(document.querySelectorAll('.test-cc')).forEach(a=>{let cc=(contrastRatio(resolveProp(a,'background-color'),resolveProp(a,'color'))).toFixed(2);a.querySelector('.cc').innerText = cc;
          a.querySelector('.cc').classList.value = "cc";
          if(cc>=4.5){a.querySelector('.cc').classList.add('text-green')} // AAA
          if(cc<4.5){a.querySelector('.cc').classList.add('text-orange')} // AA
          if(cc<3){a.querySelector('.cc').classList.add('text-red')} // no standart
        });
      }
    }
    window.recalc = calc; // can be called from browser
    // calc();
    setTimeout(calc,2000);
  }
};
</script>

<style lang="scss">
  @use 'sass:list';

  $colors: --text-dark, --main-color, --focus-color, --active-color, --passive-color, --secondary-color, --text-light;
  @for $i from 1 through list.length($colors) {
    .fg-#{$i} {
      color: var(list.nth($colors, $i));
    }
    .bg-#{$i} {
      background-color: var(list.nth($colors, $i));
    }
  }
  .box {
    //aspect-ratio: 3 / 1;
    min-height: 75px;
    align-content: center;
    font-size: 12px;
  }
  .box hr {
    backdrop-filter: sepia( 1 ) invert( 1 ) grayscale( 1 );
    width: 90%;
  }

  $sizes: 4, 5, 6, 7, 8;
  @each $size in $sizes {
    .presized-grid-layout-#{$size} {
      display: grid;
      grid-template-columns: repeat($size, 1fr);
      gap: 5px;
    }
  }

  .text-green {
    background: white;
    color: green;
  }
  .text-orange {
    background: white;
    color: orange;
  }
  .text-red {
    background: white;
    color: red;
  }
  .cc {
    padding: 1px 3px;
    user-select: none;
  }

</style>
