<template>
  <div>
    <b-form @submit.stop.prevent class="noPadding">
      <b-form-row>
        <b-col cols="12" md="12">
          <b-form-group class="form-group fontField" label-for="passwordOld">
            <template v-slot:label>
              {{ $t('field.passwordOld') }} <span class="text-danger">*</span>
            </template>
            <b-input-group>
              <b-form-input v-if="hidePasswordOld" class="form-control form-control-lg" id="passwordOld" name="passwordOld" type="password" v-model="$v.form.passwordOld.$model"
                            :state="validateState('passwordOld')" :required="true" autofocus
                            aria-describedby="passwordOld-feedback" />
              <b-form-input v-else class="form-control form-control-lg" id="passwordOld" name="passwordOld" type="text" :required="true" v-model="$v.form.passwordOld.$model"
                            :state="validateState('passwordOld')"
                            aria-describedby="passwordOld-feedback" />
              <b-form-invalid-feedback class="text-left order-last" id="passwordOld-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
              <b-input-group-append>
                <b-button v-if="config.SHOW_PASSWORD_VISIBILITY_SWITCH" variant="outline-secondary" type="button" @click="showPasswordOld()" :aria-label="hidePasswordOld ? $t('ariaLabel.showPasswordOld') : $t('ariaLabel.hidePasswordOld')">
                  <b-icon-eye-fill v-if="hidePasswordOld" aria-hidden="true" :aria-label="$t('ariaLabel.showPasswordNew')"></b-icon-eye-fill>
                  <b-icon-eye-slash-fill v-else aria-hidden="true" :aria-label="$t('ariaLabel.hidePasswordNew')"></b-icon-eye-slash-fill>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="12" md="12">
          <b-form-group class="form-group fontField" label-for="passwordNew">
            <template v-slot:label>
              {{ $t('field.passwordNew') }} <span class="text-danger">*</span>
            </template>
            <b-input-group>
              <b-form-input v-if="hidePasswordNew" class="form-control form-control-lg" id="passwordNew" name="passwordNew" type="password" v-model="$v.form.passwordNew.$model"
                            :state="validateState('passwordNew')"
                            aria-describedby="passwordNew-feedback" :required="true" />
              <b-form-input v-else class="form-control form-control-lg" id="passwordNew" name="passwordNew" type="text" v-model="$v.form.passwordNew.$model"
                            :state="validateState('passwordNew')"
                            aria-describedby="passwordNew-feedback" :required="true" />
              <b-form-invalid-feedback class="text-left order-last" v-if="form.passwordNew && !$v.form.passwordNew.valid" id="passwordNew-feedback">{{ $t('validation.passwordPolicy') }}</b-form-invalid-feedback>
              <b-input-group-append>
                <b-button v-if="config.SHOW_PASSWORD_VISIBILITY_SWITCH" variant="outline-secondary" type="button" @click="showPasswordNew()" :aria-label="hidePasswordNew ? $t('ariaLabel.showPasswordNew') : $t('ariaLabel.hidePasswordNew')">
                  <b-icon-eye-fill v-if="hidePasswordNew" aria-hidden="true" :aria-label="$t('ariaLabel.showPasswordNew')"></b-icon-eye-fill>
                  <b-icon-eye-slash-fill v-else aria-hidden="true" :aria-label="$t('ariaLabel.hidePasswordNew')"></b-icon-eye-slash-fill>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col cols="12" md="12">
          <b-form-group class="form-group fontField" label-for="passwordNewRepeat">
            <template v-slot:label>
              {{ $t('field.passwordNewRepeat') }} <span class="text-danger">*</span>
            </template>
            <b-input-group>
              <b-form-input v-if="hidePasswordNewRepeat" class="form-control form-control-lg" id="passwordNewRepeat" name="passwordNewRepeat" type="password" v-model="$v.form.passwordNewRepeat.$model"
                            :state="validateState('passwordNewRepeat')"
                            aria-describedby="passwordNewRepeat-feedback" :required="true" />
              <b-form-input v-else class="form-control form-control-lg" id="passwordNewRepeat" name="passwordNewRepeat" type="text" v-model="$v.form.passwordNewRepeat.$model"
                            :state="validateState('passwordNewRepeat')"
                            aria-describedby="passwordNewRepeat-feedback" :required="true" />
              <b-form-invalid-feedback class="text-left order-last" id="passwordNewRepeat-feedback">{{ $t('validation.fieldWarningPasswordEqual') }}</b-form-invalid-feedback>
              <b-input-group-append>
                <b-button v-if="config.SHOW_PASSWORD_VISIBILITY_SWITCH" variant="outline-secondary" type="button" @click="showPasswordNewRepeat()" :aria-label="hidePasswordNewRepeat ? $t('ariaLabel.showPasswordRepeat') : $t('ariaLabel.hidePasswordRepeat')">
                  <b-icon-eye-fill v-if="hidePasswordNewRepeat" aria-hidden="true" :aria-label="$t('ariaLabel.showPasswordNew')"></b-icon-eye-fill>
                  <b-icon-eye-slash-fill v-else aria-hidden="true" :aria-label="$t('ariaLabel.hidePasswordNew')"></b-icon-eye-slash-fill>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col cols="12" md="12">
          <b-button type="submit" @click="onSubmit" :disabled="$v.$invalid" class="btn btn-primary btn-lg btn-block btnSubmit w-100 noMargin">{{$t('button.submit')}}</b-button>
        </b-col>
      </b-form-row>
    </b-form>
  </div>
</template>
<script>
import { required, requiredIf, sameAs} from 'vuelidate/lib/validators'
import { BIconEyeFill, BIconEyeSlashFill } from 'bootstrap-vue'
import {accountService} from "../../services/api/accountService";

export default {
  data() {
    return {
      form: {
        passwordOld: undefined,
        passwordNew: undefined,
        passwordNewRepeat: undefined,
      },
      good: "",
      hidePasswordOld: true,
      hidePasswordNew: true,
      hidePasswordNewRepeat: true,
    }
  },
  computed: {
    config() {
      return window.config;
    },
  },
  components: {
    BIconEyeFill,
    BIconEyeSlashFill,
  },
  validations: {
    form: {
      passwordOld: {
        required,
      },
      passwordNew: {
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.password.required),
        valid(value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          const hasMinLength = value.length >= window.config.CUSTOMER_DATA.FIELDS.password.minLength;
          const hasMaxLength = value.length <= window.config.CUSTOMER_DATA.FIELDS.password.maxLength;
          return (
              containsUppercase &&
              containsLowercase &&
              containsNumber &&
              containsSpecial &&
              hasMinLength &&
              hasMaxLength
          );
        },
      },
      passwordNewRepeat: {
        required,
        sameAsPassword: sameAs('passwordNew')
      }
    },
  },
  methods: {
    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    showPasswordOld() {
      this.hidePasswordOld = !this.hidePasswordOld;
    },
    showPasswordNew() {
      this.hidePasswordNew = !this.hidePasswordNew;
    },
    showPasswordNewRepeat() {
      this.hidePasswordNewRepeat = !this.hidePasswordNewRepeat;
    },
    async onSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.makeToast('danger', this.$t('notification.fillAllRequiredFields'));
        return;
      }

      const result = await accountService.changePassword(this.form.passwordOld, this.form.passwordNew, this.form.passwordNewRepeat);
      if (result.errorCode < 0) {
        this.makeToast('danger', result.errorMsg);
        return;
      }

      if (result.exception != undefined) {
        this.makeToast('danger', this.$t('notification.passwordPolicy'));
        return;
      }

      this.makeToast('success', this.$t('notification.passwordResetSuccessful'));

      this.$navigateToAccount(true);
    }
  },
}

</script>

<style scoped>
  input[type="password"]::-ms-reveal {
    display: none;
  }
</style>
