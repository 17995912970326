<template>
  <div class="articleCard"  @click.stop="showArticlePage" :style="'transition: height 1s ease-out;' + (article.locked ? 'box-shadow: none;' : '')" :class="(quantity < 1) && articleOutOfStock" role="listitem" :aria-label="article.description">
    <div class="articleCardImageGlow">
      <div v-if="hasArticleImage" class="articleCardImage" :style="'background-image:url(' + imageUrl + ');' + (article.locked ? '-webkit-filter: grayscale(100%); -webkit-filter: grayscale(1);  filter: grayscale(100%); filter: gray;' : '')" />
    </div>

    <div :class="truncateText ? 'fontArticleTitle articleCartTitle col-12 text-truncate' : 'fontArticleTitle articleCartTitle col-12'"  style="">
      {{ trimDescription(article.description) }}
    </div>

    <div class="noPadding noMargin" style="">
      <div class="col-12 noPadding noMargin" style="">
        <div class="fontArticlePrice articleCartPrice col-12" v-if="article.locked">{{ $t('text.articleLocked') }}</div>
        <div class="fontArticlePrice articleCartPrice col-12" style="" v-else-if="article.custom10.startsWith('MEN_')">
          <div v-if="article.price0 > 0">
            {{ article.formattedPrice }}
          </div>
          <div v-else>
            {{ $t('text.menuDefaultPriceReplacement')}}
          </div>
        </div>
        <div class="fontArticlePrice articleCartPrice col-12" style="" v-else>
          {{ article.formattedPrice }}
        </div>
      </div>

      <div class="col-12 noMargin articleCardButtonPlacer" >
        <div class="col-12 d-flex flex-row" v-if="!article.locked">
          <b-button class="col-4 btn-override onHover articleCardButton zOver" @click.stop="infos" v-if="isValidSelection && quantity > 0">
            <font-awesome-icon class="addIconMenu" :icon="['fas', 'circle-info']" />
          </b-button>
          <div :class="{'col-4':true,[isDeleteButtonVisible?'d-none':'d-inline-block']:true}"></div>
          <b-button class="col-4 btn-override onHover articleCardButton zOver" style="color: var(--danger)" @click.stop="removeArticle" v-show="isDeleteButtonVisible">
            <font-awesome-icon class="addIconMenu" :icon="['fas', 'trash-can']" />
          </b-button>
          <b-button class="col-4 btn-override onHover articleCardButton zOver" @click.stop="addClick" v-if="isValidSelection && quantity > 0">
            <svg class="addIconMenu" xmlns="http://www.w3.org/2000/svg" width="40.971" height="28.709" viewBox="0 0 40 28">
              <g transform="translate(-1490.289 -272.683)">
                <path d="M1518.26,276.8h-21.411l-.85-4.119h-5.709v2.74h3.477l3.529,17.065h17.2v-2.739h-12.179l13.237-2.128Zm-19.2,10.693-1.645-7.952h17.333l-1.412,5.658Z" fill="currentColor"></path>
                <path d="M1500.583,294.538a2.958,2.958,0,1,0,2.957,2.959h0A2.96,2.96,0,0,0,1500.583,294.538Zm0,4.119a1.161,1.161,0,1,1,1.161-1.161A1.162,1.162,0,0,1,1500.583,298.657Z" transform="translate(-0.405 -1.208)" fill="currentColor"></path>
                <path d="M1512.593,294.693a2.958,2.958,0,1,0,2.957,2.959h0A2.961,2.961,0,0,0,1512.593,294.693Zm0,4.119h0a1.161,1.161,0,1,1,1.161-1.161A1.162,1.162,0,0,1,1512.593,298.812Z" transform="translate(-1.069 -1.217)" fill="currentColor"></path>
              </g>
              <!-- TODO -->
              <!--<circle cx="25" cy="17" r="10.4817748" style="fill: var(--main-color)" v-if="shopCartQuantity > 0" />-->
              <text dominant-baseline="middle" text-anchor="middle" stroke="var(--bg)" stroke-width="3" font-size="14" x="24" y="19" v-if="shopCartQuantity > 0">{{ shopCartQuantity }}</text>
              <text dominant-baseline="middle" text-anchor="middle" fill="currentColor" font-size="14" x="24" y="19" v-if="shopCartQuantity > 0">{{ shopCartQuantity }}</text>
            </svg>
          </b-button>
          <b-button class="btn-override articleCardButton" @click.stop v-if="quantity < 1">{{ $t('button.notAvailable') }}</b-button>
        </div>
      </div>
    </div>

    <article-detail @click.stop="stopTheEvent" :article="article" v-if="showDetail" :callbackIsValid="setIsValid"></article-detail>
  </div>
</template>

<script>
import $ from 'jquery'
import ArticleDetail from './ArticleDetails.vue'

export default {
  data() {
    return {
      showDetail: true,
      inCart: false,
      isValidSelection: false,
      quantity: 100,
      articleOutOfStock: "articleCardDisabled",
      decimalQuantity: false,
      shopCartQuantity:0,
    }
  },
  name:'article-card',
  props: {
    callbackAdd: {
      Type: Function
    },
    callbackRemove: {
      Type: Function
    },
    callbackSelected: {
      Type: Function
    },
    callbackAddWeight: {
      Type: Function
    },
    article:{
      Type: Object,
      required: true
    },
    cartQuantity:{
      Type:Number
    }
  },
  components:{
    'article-detail' : ArticleDetail
  },
  computed:{
    hasArticleImage(){
      return this.article.image != undefined;
    },
    imageUrl(){
      return this.baseUrl() + this.article.image;
    },
    isInShoppingCart() {
      return this.shopCartQuantity > 0 ? true : false;
    },
    truncateText(){
      return window.config.ARTICLE_CARD_TRUNCATE_DESCRIPTION;
    },
    isDeleteButtonVisible(){
      return this.isInShoppingCart && this.isValidSelection && this.quantity > 0;
    },
  },
  mounted(){
    this.shopCartQuantity = this.cartQuantity;
  },
  async created() {
    if (this.article.items == undefined) {
      this.isValidSelection = true;
    }
    this.quantity = 100;
  },
  methods: {
    infos(event) {
      this.showArticlePage(event);
    },
    addClick(event){
      if(this.article.locked) {
        return;
      }
      else if (this.article.custom6 == 1)
      {
        this.showArticlePage(event)
      }
      else
      {
        this.selectArticle(event);
      }
    },
    baseUrl(){
      return window.imageBaseUrl;
    },
    trimDescription(description){
      var config = window.config;
      var trimTo = config.TRIM_ARTICLE_DESCRIPTION_TO;

      if(description.length <= trimTo)
      {
        return description;
      }

      return description.substring(0, trimTo) + "...";
    },
    setIsValid(isValid){
      this.isValidSelection = isValid;
    },
    showArticlePage() {
      let article = this.article;
      if(article != undefined && article.locked) return;
      if(this.quantity > 0)
      {
        this.callbackSelected(article, this.quantity, article.description);
      }
    },
    removeArticle(event){
      event.preventDefault();
      this.callbackRemove(this.article.id, this);
    },
    selectArticle(event) {
      event.preventDefault();
      if(this.article.decimalQuantity)
      {
        this.callbackAddWeight(this.article, this.quantity);
      }
      else
      {
        this.callbackAdd(this.article.id, this.article.price0, this);
      }

      $('.articleCardButtonPlacer').trigger('blur');
    },
    stopTheEvent: (event) => event.stopPropagation()
  }
}
</script>

<style scoped>

.btn-override {
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  border-radius:0px;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 0.5px solid transparent;
  /* padding: 0.375rem 0.75rem; */
  font-size: 1rem;
  line-height: 1.5;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.onHover {
  --bg: white;
  color: var(--main-color);
  background-color: var(--bg);
}

.onHover:hover {
  color: white;
  /*fill: var(--main-color);*/
  --bg: var(--main-color);
}

.onHoverInvert {
  --bg: var(--main-color);
  background-color: var(--bg);
  color: white;
}

.onHoverInvert:hover {
  --bg: white;
  color: var(--main-color);
  /*border: 0.5px solid var(--main-color);*/
}

@media screen and (max-width:768px) {
  .onHoverInvert:hover {
    --bg: var(--main-color);
    color: white;
    /*border: 0.5px solid var(--main-color);*/
  }
}

.articleCardButtonPlacer,
.articleCardButtonPlacer div {
  padding:0;
}


.articleCartPrice {
  /* padding: 0px 0% 0px 0px; */
  text-align:center;
}

.articleCardImageGlow {
  max-height: 100%;
  height: 150px;
  background-size: var(--article-card-image-fit);
  background-repeat:no-repeat;
  background-position:center;
}

@media screen and (max-width:776px) {
  .articleCardImageGlow {
    height:100px;
  }
}

@media screen and (max-width:415px) {
  .articleCardImageGlow {
    height:100px;
  }
}

.addIconMenu,
.minusIconMenu{
  height: 22px;
  margin: 5px 0;
}

.articleCardImage {
  max-height:100%;
  height:100%;
  background-size: var(--article-card-image-fit);
  background-repeat:no-repeat;
  background-position:center;
}

</style>
