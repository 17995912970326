<template>
  <div
      :class="['multiselect-dropdown', customClass]"
      role="combobox"
      :aria-expanded="isOpen"
      aria-haspopup="listbox"
      :aria-controls="'dropdown-list'"
      @keydown.enter.prevent="toggleDropdown"
      @keydown.space.prevent="toggleDropdown"
      tabindex="0"
      @keydown.esc="closeDropdown"
  >
    <!-- Selected Items as Bubbles -->
    <div class="selected-bubbles" v-if="selectedOptions.length">
      <div
          v-for="option in selectedOptions"
          :key="option.value"
          class="selected-bubble"
          @click="removeOption(option)"
          tabindex="0"
          role="button"
          :aria-label="`Remove ${option.label}`"
          @keydown.enter.prevent="removeOption(option)"
          @keydown.space.prevent="removeOption(option)"
      >
        {{ option.label }}
        <span class="remove-bubble" aria-hidden="true">×</span>
      </div>
    </div>

    <!-- Trigger Button -->
    <div
        class="dropdown-trigger"
        @click="toggleDropdown"
        tabindex="0"
        :aria-expanded="isOpen"
        role="button"
        :aria-label="dropdownLabel || 'Dropdown'"
    >
      <span>{{ dropdownLabelText }}</span>
      <span class="dropdown-arrow">▼</span>
    </div>

    <!-- Dropdown List -->
    <ul
        v-show="isOpen"
    class="dropdown-list"
    id="dropdown-list"
    role="listbox"
    :aria-multiselectable="true"
    @focusout="handleFocusOut"
    ref="dropdownList"
    >
    <li
        v-for="(option, index) in options"
        :key="option.value"
        class="dropdown-item"
        role="option"
        tabindex="0"
        :aria-selected="option.selected"
        @click.prevent="toggleOption(option)"
        @keydown.enter.prevent="toggleOption(option)"
        @keydown.space.prevent="toggleOption(option)"
        @keydown="handleKeyDown(index)"
        ref="option"
    >
      <Checkbox
          :modelValue="option.selected"
          :label="option.label"
          :aria-label="`Select ${option.label}`"
          :checkboxId="'checkbox-' + option.value"
          @update:modelValue="updateOptionSelection(option)"
      />
      <span class="option-label">{{ option.label }}</span>
    </li>
    </ul>
  </div>
</template>

<script>
import Checkbox from "@/components/page-components/templates/Checkbox.vue";

export default {
  components: {
    Checkbox,
  },
  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    dropdownLabel: {
      type: String,
      default: 'Select options',
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
      focusedIndex: -1,
    };
  },
  computed: {
    selectedOptions() {
      return this.options.filter(option => option.selected);
    },
    dropdownLabelText() {
      return this.selectedOptions.length
          ? this.selectedOptions.map(opt => opt.label).join(', ')
          : this.dropdownLabel;
    },
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        this.$nextTick(() => {
          this.focusedIndex = 0; // Set to first option
          this.$refs.option[this.focusedIndex].focus(); // Focus the first option
        });
      } else {
        this.closeDropdown();
      }
    },
    closeDropdown() {
      this.isOpen = false;
      this.focusedIndex = -1; // Reset focus index
    },
    toggleOption(option) {
      option.selected = !option.selected;
      this.$emit('update-options', this.options);
    },
    updateOptionSelection(option, value) {
      option.selected = value;
      this.$emit('update-options', this.options);
    },
    removeOption(option) {
      option.selected = false;
      this.$emit('update-options', this.options);
    },
    handleFocusOut(event) {
      const dropdown = this.$el;
      const relatedTarget = event.relatedTarget || document.activeElement;
      if (!dropdown.contains(relatedTarget)) {
        this.closeDropdown();
      }
    },
    handleKeyDown() {
      const event = window.event;
      if (this.isOpen) {
        if (event.key === 'ArrowDown') {
          event.preventDefault();
          this.focusedIndex = (this.focusedIndex + 1) % this.options.length;
          this.$refs.option[this.focusedIndex].focus();
        } else if (event.key === 'ArrowUp') {
          event.preventDefault();
          this.focusedIndex = (this.focusedIndex - 1 + this.options.length) % this.options.length;
          this.$refs.option[this.focusedIndex].focus();
        }
      }
    },
  },
};
</script>

<style scoped>
.multiselect-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown-trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  cursor: pointer;
  user-select: none;
}

.dropdown-arrow {
  margin-left: auto;
}

.dropdown-list {
  position: absolute;
  padding: 0;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 1000;
}

.selected-bubbles {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px 0;
}

.selected-bubble {
  background-color: #007bff;
  color: #fff;
  border-radius: 15px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

.selected-bubble:hover {
  background-color: #0056b3;
}

.remove-bubble {
  margin-left: 8px;
  cursor: pointer;
  font-weight: bold;
}

.dropdown-item {
  padding: 10px;
  display: flex;
  align-items: center;
}

.dropdown-item:hover {
  background-color: #f9f9f9;
}

.option-label {
  margin-left: 10px; /* Space between checkbox and text */
}
</style>
