<template>
  <CustomSwiper ref="swiper"
                class="cSwiperDate"
                v-bind="$attrs"
                :continues-item-generator="generator"
                selectable
                v-model="nativeModel"
                :item-comparator="(a,b)=>a.getTime() === b.getTime()"
                :preloaded-group-count="3"
                :configurate="config"
  >
    <template v-slot:default="{ value, visible, selected, onSelect }">
      <div class="day-wrapper">
        <button
          class="day-button text-decoration-none w-100"
          :class="{['active']:selected}"
          :role="visible?'button':'group'"
          :tabindex="visible?'0':'-1'"
          :aria-label="label_current(value)"
          href="javascript:void(0)"
          @click="onSelect()"
        >
          <div class="date-vertical">
            <span class="weekday">{{ formatterWeekday(value) }}</span>
            <span class="day">{{ value.getDate() }}</span>
            <span class="month">{{ formatterMonth(value) }}</span>
          </div>
        </button>
      </div>
    </template>
  </CustomSwiper>
</template>

<script>
import CustomSwiper from "@/components/layout-components/Swiper.vue";

/**
 *
 * @inheritDoc CustomSwiper
 */
export default {
  inheritAttrs: false, // disable attribute applying on random divs
  components:{
    CustomSwiper
  },
  setup(){},
  data() {
    return {
      viewDateStart: undefined, // baseDate -> viewDateStart (if needed, then clamped to monday)
    };
  },
  emits: ['update:modelValue'],
  model: { // vue 2.0 modelValue
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  props: {
    baseDate: { // initial date Displayed (if componseAsWeek, then the date just needs to be in the Week)
      type: Date,
      default: () => this.modelValue,
    },
    composeAsWeek: Boolean, // Should it group by Week? And Does the Week start on Monday?
    modelValue: {
      Type: Object,
      default: null,
    },
  },
  computed:{
    currentLocale() {
      return this.$i18n.locale.replace('_', '-');
    },
    formatterInstanceWeekday(){
      return new Intl.DateTimeFormat(this.currentLocale, { weekday: 'short' });
    },
    formatterInstanceMonth(){
      return new Intl.DateTimeFormat(this.currentLocale, { month: 'short' });
    },
    formatterInstanceFull(){
      return new Intl.DateTimeFormat(this.currentLocale, { weekday: 'long', day: 'numeric', month: 'narrow', year: '2-digit' });
    },
    nativeModel: { // vue 2 support for v-model
      get() {return this.modelValue;},
      set(value) {this.$emit('update:modelValue', value);}
    },
    ignoreWeekend(){ // incase the week only wants to show <7 days, the weekends are tried to be excluded from view
      return this.composeAsWeek && this.itemsPerView !== 7;
    },
    itemsPerView(){
      if (this._super() !== undefined) {
        return this._super().itemsPerView;
      } else {
        return this.$attrs.itemsPerView || this.$attrs['items-per-view'] || 7; // vue 2 specific: $attrs are not parsed,
      }
    },
  },
  mounted() {
    // this.test(); /// Unit Test for Weekend Skipping Date Generator
  },
  created() {
    if (this.ignoreWeekend && this.getWeekday(this.baseDate, true) >= this.itemsPerView) {
      // If the current day is not visible in the list, skip to next Monday
      this.viewDateStart = this.offsetDate(this.baseDate, 7 - this.getWeekday(this.baseDate, true)); // Adds Days: 2 for Saturday, and 1 for Sunday
    } else if (this.composeAsWeek) {
      // Go to Current Week Monday
      this.viewDateStart = this.getMonday(this.baseDate);
    }
  },
  methods: {
    _super() { // hacky way to get the Super Class. computed prop does not work, since the $refs is not a change listener proxy
      return this.$refs.swiper;
    },
    // Get Monday of the current week
    getMonday(date) {
      return this.offsetDate(date,-this.getWeekday(date, true));
    },
    isWeekend(date) {
      return this.getWeekday(date, true) >= 5;
    },
    /** @returns indexof (`Sunday`(eu=false), `Monday`, `Thuesday`, `Wednesday`, `Thirdsday`, `Friday`, `Saturday`, `Sunday`(eu=true)): number between 0 - 6 */
    getWeekday(date, eu = false) {
      if (eu) {
        return (date.getDay() + 6) % 7;
      }
      return date.getDay();
    },
    offsetDate(date,dayCount){
      // kindof correct: date = date + offset
      const clone = new Date(date.getTime());
      clone.setDate(clone.getDate() + dayCount);
      return clone;
    },
    offsetDateWithoutWeekends(date, dayCount) {
      const daysPerWeek = this.itemsPerView;
      const skipDaysPerWeek = 7 - daysPerWeek;
      // const mod7 = ( a ) => ( 7 + ( a % 7 ) ) % 7; /// != (a)=>a%7, since minus works different now
      // const modB = ( a , b ) => ( b + ( a % b ) ) % b;

      /// copy date
      const clone = new Date(date.getTime());
      /// get the weekday @range (0-6)
      const daysSinceMonday = this.getWeekday(clone,true);
      // console.assert("Mo" === this.formatterWeekday(this.offsetDate(clone,-daysSinceMonday)), "Failed! Last Monday does not match offset for "+daysSinceMonday+" days.");

      /// amount of days to offset @ range (0-6)
      // const daysToNextMonday = (7 - daysSinceMonday) % 7;
      // console.assert("Mo" === this.formatterWeekday(this.offsetDate(clone,daysToNextMonday)), "Failed! Next Monday does not match offset for "+daysToNextMonday+" days.");

      /// Add Amount of days (daysSinceMonday) before today (0) to dayCount
      const dayCountCorrectedToMonday = daysSinceMonday + dayCount;

      // const t_discardedWeekDay = mod7(dayCount);
      // const t_v = this.formatterWeekday(this.offsetDate(clone,dayCount - daysSinceMonday - t_discardedWeekDay)); // random days - days to monday - offset divisible by 7 === monday
      // console.assert("Mo" === t_v, "Failed Expected Day: Monday but was "+t_v);

      /// now each week we traverse, skip amount of days = 2; Or simply said: for each 5.th day, add 2 days
      const weekCount = Math.floor(dayCountCorrectedToMonday / daysPerWeek);

      const daysSkipped = weekCount * skipDaysPerWeek;

      const correctedValue = dayCount + daysSkipped;

      // if (modB(dayCount, daysPerWeek) === 0) { // expected: where the daycount since / or to Monday
      //   let expectedWeekday = this.formatterWeekday(clone);
      //   if (this.isWeekend(clone)) {
      //     expectedWeekday = "Mo";
      //   }
      //   const formatterWeekday1 = this.formatterWeekday(this.offsetDate(clone, correctedValue));
      //   console.assert(expectedWeekday === formatterWeekday1, "Failed! First day of the Week "+weekCount+" is not Monday, but "+formatterWeekday1+ " ["+dayCountCorrectedToMonday+"]");
      // }
      // console.assert(!["Sa","So"].includes(this.formatterWeekday(this.offsetDate(clone,correctedValue))), "Failed! There was a Weekend not Skipped! ["+correctedValue+","+dayCount+"]");

      return this.offsetDate(date, correctedValue);
    },
    formatterWeekday(dt) {
      return this.formatterInstanceWeekday.format(dt);
    },
    formatterMonth(dt) {
      return this.formatterInstanceMonth.format(dt);
    },
    generator(dateIndex) {
      if (this.ignoreWeekend) {
        return dateIndex.map(this.t(function(index) {
          return this.offsetDateWithoutWeekends(this.viewDateStart, index);
        }));
      } else {
        return dateIndex.map(this.t(function(index) {
          return this.offsetDate(this.viewDateStart, index);
        }));
      }
    },
    /** @type {Function(item:Date):String} */
    label_current(item) {
      // todo add label
      return this.$t('', this.formatterInstanceFull.format(item));
    },
    /** tool for `setTimeout(t(function(){this.<?>();}),0)` to keep the `this`  */
    t(f) {
      const _t = f.bind(this);
      return function thisProxy(...a){return _t(...a);};
    },
    test() {
      const modB = ( a , b ) => ( b + ( a % b ) ) % b;

      console.clear();
      console.group("Test Case 1: Starting on Monday");
      (",".repeat(20).split(",")).map((_,i,p)=>i-Math.trunc(p.length / 2)).map(this.t(function(v){
        const date = (new Date("2024-12-10T10:00:00Z"));
        date.setDate(2);
        const test = this.offsetDateWithoutWeekends(date,v);
        const wd = this.formatterWeekday(test,v);
        if (modB(v,5)===0) {
          console.assert("Mo" === wd, "I Expected as Result a Monday as offset");
        }
      }));
      console.groupEnd();

      console.group("Test Case 2: Starting on Dienstag");
      (",".repeat(20).split(",")).map((_,i,p)=>i-Math.trunc(p.length / 2)).map(this.t(function(v){
        const date = (new Date("2024-12-10T10:00:00Z"));
        date.setDate(3);
        const test = this.offsetDateWithoutWeekends(date,v);
        const wd = this.formatterWeekday(test,v);
        if (modB(v,5)===4){
          console.assert("Mo" === wd, "I Expected as Result a Monday as offset");
        }

      }));
      console.groupEnd();

      console.group("Test Case 3: Starting on Saturday");
      (",".repeat(20).split(",")).map((_,i,p)=>i-Math.trunc(p.length / 2)).map(this.t(function(v){
        const date = (new Date("2024-12-10T10:00:00Z"));
        date.setDate(7);
        const test = this.offsetDateWithoutWeekends(date,v);
        const wd = this.formatterWeekday(test,v);
        if (modB(v,5) === 0) {
          console.assert("Mo" === wd, "I Expected as Result a Monday as offset");
        }
      }));
      console.groupEnd();
    },
    config(conf){
      conf['spaceBetween'] = '1px';
      return conf;
    },
  },
};
</script>

<style>

.cSwiperDate {
  /* Configure Date Swiper */
  --swiper-navigation-size: 22px; /* General Navigation Arrow Size */
  --swiper-navigation-radius: 0; /* Border-Radius for Outside Nav Buttons */
  --swiper-navigation-inner-offset: 1px; /* Space Between Nav Buttons and Slider */
}

.day-button {
  flex: 1; /* Each day button fills equal space */
  padding: 10px 0; /* Adjust padding for better touch targets */
  /*margin: 0 1px; !* Small margin between buttons *!*/
  border: none;
  /*border: 1px solid var(--background);*/
  border-radius: var(--swiper-navigation-radius, 10px);
  background-color: var(--main-color);
  color: var(--main-color-text);
  /*transition: background-color 0.3s, transform 0.2s; !* Add transition for scaling *!*/
  display: flex; /* Allow flex for vertical alignment */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */

  &.active {
    background-color: var(--active-color);
    color: var(--active-color-text);
  }
  &:hover,
  &:focus
  {
    background-color: var(--focus-color);
    color: var(--focus-color-text);
  }
}

.date-vertical {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center items horizontally */
}

.day {
  font-size: 16px;
}

.weekday,
.month {
  font-size: 14px;
}

@media (min-width: 769px) {
  .date-vertical { /* for desktop: */
    flex-direction: row; /* Stack elements horizontally */
    column-gap: .5rem; /* Add some Gaps between the Items */
  }
}
</style>
