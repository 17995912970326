<template>
  <div
      class="toolbar-container"
      ref="toolbarContainer"
      @mousedown="startDrag"
      @mouseup="stopDrag"
      @mouseleave="stopDrag"
      @mousemove="drag"
  >
    <!-- Subcategory Quickfilter -->
    <div id="menuSubCategoriesWrapper" style="" class="toolbar" role="radiogroup">
      <div class="toolbar">
        <!--
        <div class="col-1 categoryScrollIcon subCategoryScrollIcon" @click="scrollSubCategories(-1)">
          <i class="fa fa-chevron-left"></i>
        </div>

        <div class="col-1 categoryScrollIcon subCategoryScrollIcon" style="float:right" @click="scrollSubCategories(1)">
          <i class="fa fa-chevron-right"></i>
        </div>
        -->

        <div id="menuSubCategories" class="d-flex flex-row text-left">
          <div v-for="(item, index) in toolbarItems" :key="index" :class="{['py-1 px-2']:true,[activeGroupClass]:isItemSelected(item, index)}">
            <a class="category" href="javascript:void(0)" @click="handleClick(index)" role="radio" tabindex="0" draggable="false" :aria-selected="isItemSelected(item, index)" :aria-checked="isItemSelected(item, index)">{{ item.description || item.name }}</a>
          </div>
        </div>
      </div>
    </div>
    <!-- / Subcategory Quickfilter -->

    <!--
    <div class="toolbar" ref="toolbar">
      <button
          v-for="(item, index) in toolbarItems"
          :key="index"
          :class="{ active: activeItem === index }"
          @click="handleClick(index)"
          class="toolbar-button"
      >
        {{ item.description || item.name }}
      </button>
    </div>
    -->
  </div>
</template>

<script>
  export default {
    props: {
      toolbarItems: {
        type: Array,
        required: true,
      },
      selectedItemId: {
        type: Number,
        required: false,
      },
    },
    data() {
      return {
        activeItem: 0, // Tracks the currently active item
        isDragging: false, // Track if the user is dragging the toolbar
        startX: 0, // Starting mouse X position
        scrollLeft: 0, // Toolbar's scroll position before dragging
        activeGroupClass: 'activeCategory',
      };
    },
    methods: {
      isItemSelected(item, index)
      {
        if(this.selectedItemId < 0 && index === 0)
        {
          return true;
        }

        return item.id === this.selectedItemId;
      },
      handleClick(index) {
        this.activeItem = index;
        this.$emit('item-click', index);
      },
      startDrag(event) {
        // Set the initial drag state
        this.isDragging = true;
        this.startX = event.pageX - this.$refs.toolbarContainer.offsetLeft;
        this.scrollLeft = this.$refs.toolbarContainer.scrollLeft;
      },
      stopDrag() {
        this.isDragging = false;
      },
      drag(event) {
        if (!this.isDragging) return;
        event.preventDefault();
        const x = event.pageX - this.$refs.toolbarContainer.offsetLeft;
        const walk = (x - this.startX) * 2; // Scroll speed
        this.$refs.toolbarContainer.scrollLeft = this.scrollLeft - walk;
      },
    },
  };
</script>

<style scoped>
  .toolbar-container {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    cursor: grab;
    user-select: none; /* Prevent text selection while dragging */
    padding-bottom: 5px;
    border-bottom: 1px solid var(--main-color);
  }

  .toolbar-container:active {
    cursor: grabbing;
  }

  .toolbar {
    /*
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
    */
  }

  .toolbar-button {
    flex-shrink: 0;
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 10px;
    background-color: var(--toolbar-background);
    color: var(--toolbar-text);
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .toolbar-button.active {
    background-color: var(--toolbar-active);
    color: var(--toolbar-text);
  }

  .toolbar-button:hover {
    background-color: var(--toolbar-active);
    color: var(--toolbar-text);
  }
</style>
