import { render, staticRenderFns } from "./OrderHistoryPage.vue?vue&type=template&id=0f255fe5&scoped=true"
import script from "./OrderHistoryPage.vue?vue&type=script&lang=js"
export * from "./OrderHistoryPage.vue?vue&type=script&lang=js"
import style0 from "./OrderHistoryPage.vue?vue&type=style&index=0&id=0f255fe5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f255fe5",
  null
  
)

export default component.exports