<template>
  <div class="col-12 flowWrapper" v-if="!tableMode && config.SHOW_FLOWPOINT_INDICATOR">
    <div class="col-12 flowPointContainer" style="padding:0;">
      <div class="flowPoint" :class="(currentPoint >= 1) && activeFlowPoint" style="text-align:right"  @click="pointClick(1)">
        <a>
          <font-awesome-icon :icon="['fas', 'shopping-cart']" :class="(currentPoint == 1) && activePointImage"/>
        </a>
        <div class="bar-right"></div>
      </div>

      <div class="flowPoint" :class="(currentPoint >= 2) && activeFlowPoint"  @click="pointClick(2)">
        <div class="bar-left"></div>
        <a>
          <font-awesome-icon :icon="['fas', 'eye']" :class="(currentPoint == 2) && activePointImage" />
        </a>
        <div class="bar-right"></div>
      </div>

      <div v-if="addressVisible" class="flowPoint" :class="(currentPoint >= 3 && isDelivery) && activeFlowPoint"  @click="pointClick(3)">
        <div class="bar-left"></div>
        <a>
          <font-awesome-icon :icon="['fas', 'envelope']" :class="(currentPoint == 3) && activePointImage" />
        </a>
        <div class="bar-right"></div>
      </div>

      <div class="flowPoint" :class="(currentPoint >= 4) && activeFlowPoint" @click="pointClick(4)">
        <div class="bar-left"></div>
        <a >
          <font-awesome-icon :icon="['fas', 'credit-card']" :class="(currentPoint == 4) && activePointImage"/>
        </a>
        <div class="bar-right"></div>
      </div>

      <div class="flowPoint" :class="(currentPoint >= 5) && activeFlowPoint"  style="text-align:left" @click="pointClick(5)">
        <div class="bar-left"></div>
        <a>
          <font-awesome-icon :icon="['fas', 'ticket']" :class="(currentPoint == 5) && activePointImage" />
        </a>
      </div>

    </div>
    <div class="col-12 flowPointContainer flowPointLabels" style="padding:0;">
      <div class="flowPoint flowPointLabel" :class="(currentPoint >= 1) && activeFlowPoint" @click="pointClick(1)">
        {{ $t('subTitle.flowPoint1') }}
      </div>

      <div class="flowPoint flowPointLabel" :class="(currentPoint >= 2) && activeFlowPoint" @click="pointClick(2)">
        {{ $t('subTitle.flowPoint2') }}
      </div>

      <div v-if="addressVisible" class="flowPoint flowPointLabel" :class="(currentPoint >= 3 && isDelivery) && activeFlowPoint" @click="pointClick(3)">
        {{ $t('subTitle.flowPoint3') }}
      </div>

      <div class="flowPoint flowPointLabel" :class="(currentPoint >= 4) && activeFlowPoint" @click="pointClick(4)">
        {{ $t('subTitle.flowPoint4') }}
      </div>

      <div class="flowPoint flowPointLabel" :class="(currentPoint >= 5) && activeFlowPoint" @click="pointClick(5)">
        {{ $t('subTitle.flowPoint5') }}
      </div>
    </div>

  </div>
</template>

<script>
import {nextTick} from "vue";
import {useOrderStore} from "@/stores/order";
import {Mode, OrderTypes} from "@/constants/constants";
import {useAppStore} from "@/stores/app";

export default {
  setup(){
    const appStore = useAppStore();
    const orderType = useOrderStore();
    return {
      appStore,
      // /** @type {String,} */
      orderType: orderType.getOrderType,
    }
  },
  data() {
    return {
      activeFlowPoint : "flowPointActive",
      activePointImage : "flowPointActiveImage",
      tableMode: false,
      isDelivery: false,
    }
  },
  props:{
    currentPoint: {
      Type:Number
    }
  },
  computed:{
    config(){
      return window.config;
    },
    addressVisible(){
      return this.orderType !== OrderTypes.DINE_IN && this.orderType !== OrderTypes.TAKEAWAY;
    },
  },
  async mounted(){
    await nextTick();
    this.tableMode = this.appStore.hasMode === Mode.TABLE;
    this.isDelivery = OrderTypes.DELIVERY === this.orderType;
  },
  methods: {
    pointClick(index) {
      switch(index) {
        case 1: {
          this.$navigateToMenu();
          break;
        }
        case 2: {
          this.$navigateToOrderConfirmation();
          break;
        }
        case 3: {
          if(this.addressVisible)
            this.$navigateToAddress();
          break;
        }
        case 4: {
          this.$navigateToOrderPayment();
          break;
        }
        case 5: {
          break;
        }
      }
    }
  }
}
</script>

<style scoped>
.flowPoint {
  width:20%;
  display:inline-block;
  color:#bebebe;
  padding:10px 0;
  border:none;
  margin-left:0px;
  margin-right:0px;
  padding-left:0px;
  font-size:30px;
  padding-right:0px;
}

.flowPoint:hover{
  cursor:pointer;
}

.flowPointLabel {
  font-size:14px;
}

.flowWrapper {
  margin-top:50px;
  margin-bottom:30px;
  padding:0;
}

@media screen and (max-width: 770px) {
  .flowPointLabel {
    font-size:10px;
  }

  .flowPointLabels {
    display:none;
  }

  .flowWrapper {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.flowPointActive {
  color: var(--main-color);
  border:none;
}



.flowPointActiveImage {
  border-bottom:4px solid var(--main-color);
  /*padding-bottom:10px;*/
}

.bar-right, .bar-left {
  height:1px;
  width: calc(50% - 17px);
  background: #333;
  display:inline-block;
  border:none;
  margin-bottom: 10px;
  margin-left:0px;
  margin-right:0px;
  padding-left:0px;
  padding-right:0px;
}
</style>
