<template>
  <div class="global-header-styles scrollOffsetable">
    <div class="global-header">
      <div class="header-left">
        <!-- Show Back Button or Sidebar Toggle -->
        <button :aria-label="$t('button.back')" v-if="showBackButton" @click="goBack" class="back-button">
          <font-awesome-icon :icon="['fas', 'arrow-left']" />
        </button>
        <ToggleSidebar v-if="config.TOGGLE_SIDEBAR.enabled && !showBackButton"></ToggleSidebar>
      </div>

      <!-- Middle Section: Current Page Title -->
      <div class="header-middle d-flex flex-column">
        <h1 class="page-title" :aria-label="$t(this.$route.meta.ariaTitle || '')">{{ $t(this.$route.meta.title || '') }}</h1>
        <!-- todo - aria label übersetzen -->
        <small v-if="showMetaPageSub">{{ getPageMetaTitle }}</small>
      </div>

      <!-- Right Section: Logo -->
      <div class="header-right">
        <logo-image :class="logoImageClass" id="logo-image-wrapper"></logo-image>
      </div>
    </div>
  </div>
</template>

<script>
  import ToggleSidebar from "@/components/layout-components/ToggleSidebar.vue";
  import LogoImage from "@/components/sub-components/LogoImage.vue";

  export default {
    components: {
      ToggleSidebar,
      LogoImage,
    },
    data() {
      return {
        currentPageTitle: '',
        metaPageSub: undefined,
      };
    },
    inject:['getPageMetaTitle','resetPageMetaTitle'],
    computed: {
      config() {
        return window.config;
      },
      showBackButton() {
        // Check if the route has a back button defined in its meta and there's history to go back
        return this.$route.meta.showBackButton && this.$router.history.current !== this.$router.history.base;
      },
      logoImageClass(){
        let defaultClasses=['logo'];
        return defaultClasses.join(" ");
      },
      showMetaPageSub() {
        return this.getPageMetaTitle !== undefined && this.getPageMetaTitle.length > 0;
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1); // Navigate back to the previous page
      },
    },
    watch: {
      // Watch route changes and update the page title accordingly
      $route(to) {
        this.currentPageTitle = to.meta.title || '';
        this.resetPageMetaTitle();
      },
    },
  };
</script>

<style scoped>
  .global-header-styles {
    background: var(--global-header-color);
  }
  .global-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    color: var(--global-header-element-color);
    height: Max(10vh,55px);
    position: unset;
  }

  .header-left,
  .header-right {
    flex-basis: 15%; /* Set equal width for left and right sections */
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align items to the start for the left section */
  }

  .header-right {
    justify-content: right;
  }

  .header-middle {
    flex-grow: 1; /* Allow the middle section to grow and take available space */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px; /* Add padding to prevent overlap */
  }

  .page-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
  }

  .logo {
    height: 40px;
    /*cursor: pointer;*/
  }

  .back-button,
  .basket-button,
  .sidebar-toggle {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    margin-right: 10px;
    color: var(--global-header-element-color);
  }

  .sidebar-toggle {
    font-size: 1.5rem;
  }

  /* Responsive adjustments */
  @media (max-width: 768px) {
    .page-title {
      font-size: 1.25rem;
    }

    .logo {
      height: 30px;
    }

    .header-left,
    .header-right {
      flex-basis: 20%; /* Increase size for smaller screens */
    }

    .header-middle {
      padding: 0 5px; /* Reduce padding on smaller screens */
    }
  }
</style>
