import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', {
  state: () => ({
    mode: null,
    workflow: null,
    redirectRouteName: null, // Store route name instead of path
    redirectRouteParams: null, // Store route params if needed
    currentOrderId: null,
    currentMealPlanOrderId: null,
  }),
  getters: {
    hasWorkflow: (state) => !!state.workflow,
    uppercaseMode: (state) => state.mode ? state.mode.toUpperCase() : null,
  },
  actions: {
    setMode(mode) {
      this.mode = mode;
    },
    isMode(mode) {
      return this.mode === mode;
    },
    setWorkflow(workflow) {
      this.workflow = workflow;
    },
    isWorkflow(workflow) {
      return this.workflow === workflow;
    },
    // Store route name and params before login redirect
    setRedirectRoute(routeName, routeParams = null) {
      this.redirectRouteName = routeName;
      this.redirectRouteParams = routeParams;
    },
    setCurrentOrderId(orderId) {
      this.currentOrderId = orderId;
    },
    setCurrentMealPlanOrderId(orderId) {
      this.currentMealPlanOrderId = orderId;
    },
    // Clear after redirection
    clearRedirectRoute() {
      this.redirectRouteName = null;
      this.redirectRouteParams = null;
    }
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'app',
        storage: sessionStorage,
      },
    ],
  },
});
