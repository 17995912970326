<template>
    <div class="checkoutFinishedWrapper">
        <!-- <flow :currentPoint="5"> </flow> -->

        <div class="fontTitle" style="text-align:center;">
            {{ $t('subTitle.checkoutFinishedMessage') }}
        </div>


        <div class="fontOrderSuccessMessage" v-if="orderType === 'delivery' && !isTableMode">
            {{ $t('text.checkoutFinishedMessageBodyDelivery') }}
        </div>

        <div class="fontOrderSuccessMessage" v-if="orderType === 'takeaway' && !isTableMode">
            {{ $t('text.checkoutFinishedMessageBodyTakeaway') }}
            <br/>
            {{ pickupTime }}
            <br/>
            <br/>
            <div>
                {{ $t('text.pickupSpot') }}:
                <br/>
                {{ this.shopStore.getShopName }}
                <br/>
                <span v-html="this.shopStore.getShopNotes"></span>
            </div>
        </div>

        <div class="fontOrderSuccessMessage" v-if="orderType === 'dinein' && !isTableMode">
            {{ $t('text.checkoutFinishedMessageBodyDinein') }}
            <br/>
            {{ pickupTime }}
            <br/>
            <br/>
            <div>
                {{ $t('text.pickupSpot') }}:
                <br/>
                {{ this.shopStore.getShopName }}
                <br/>
                <span v-html="this.shopStore.getShopNotes"></span>
            </div>
        </div>

        <div class="fontOrderSuccessMessage" v-if="isTableMode">
            {{ $t('text.checkoutFinishedMessageBodyTableMode') }}
            <br/>
        </div>

        <div class="fontOrderSuccessMessage" v-if="isImmediateMode">
          {{ $t('text.checkoutFinishedMessageBodyTableMode') }}
          <br/>
          <b style="font-size: 42px">{{ pickupNumber }}</b>
          <br/>
          {{ $t('text.checkoutFinishedMessageBodyTableMode2') }}
        </div>

        <b-button type="button" v-if="!isTableMode && !isGuestMode && !isImmediateMode && config.ORDER_HISTORY.enabled" @click="orderOverviewPage" class="btn btn-primary">{{$t('button.orderHistory')}}</b-button>
        <b-button type="button" @click="newOrder" class="btn btn-primary">{{$t('button.newOrder')}}</b-button>
    </div>
</template>

<script>
import {accountService} from "@/services/api/accountService";
import { useShopStore } from "@/stores/shop";
import {useAppStore} from "@/stores/app";
import {Mode} from "@/constants/constants";
import {useTableStore} from "@/stores/table";
import {useOrderStore} from "@/stores/order";

export default {
  setup() {
    const shopStore = useShopStore();
    const appStore = useAppStore();
    const tableStore = useTableStore();
    const orderStore = useOrderStore();

    return { shopStore, appStore, tableStore, orderStore };
  },
  data() {
    return {
      orderType: null,
      pickupTime: null,
      pickupNumber: null
    }
  },
  components:{
  },
  created() {
    const order = this.orderStore.getOrder(this.appStore.currentOrderId);
    if (order) {
      this.orderType = order.orderType;
      this.pickupTime = order.pickupTime;
      this.pickupNumber = order.pickupNumber;
    }

    if(this.isGuestMode() || this.isImmediateMode)
    {
        this.logout();
    }
  },
  computed: {
      config() {
        return window.config;
      },
      isGuestMode(){
        return this.appStore.isMode(Mode.GUEST);
      },
      isTableMode() {
        return this.appStore.isMode(Mode.TABLE);
      },
      isImmediateMode() {
        return this.appStore.isMode(Mode.IMMEDIATE);
      },
  },
  methods: {
    async logout() {
      const result = await accountService.logout();
      if (result.error) {
        this.makeToast('danger', result.message);
      }
    },
    orderOverviewPage() {
      this.$navigateToOrderHistory();
    },
    newOrder()
    {
      if (this.isTableMode) {
        this.tableStore.resetTable();
        this.$navigateToLanding();
      } else if (this.isImmediateMode) {
        this.$navigateToLanding();
      } else {
        this.$navigateToOrderShops();
      }
    }
  },
}
</script>

<style scoped>
</style>
