<template>
    <div style="height:100%; width:100%; z-index:999999999999;">
        <div style="top:45%;position:inherit;" v-if="success">
            <p>{{ message }}</p>
            <b-button @click="$router.push({path:'/Shop'})" class="btn btn-primary">{{ $t('button.backToShop') }}</b-button>
        </div>
    </div>
</template>

<script>
import {accountService} from "../../services/api/accountService";

export default {
    data() {
        return {
            success: null,
            message: "",
        }
    },
    props:{
        k: {
            Type: String
        },
        m: {
            Type: String
        },
        i: {
            Type: String
        }
    },
    async beforeMount() {
      const result = await accountService.enable(this.k, this.m, this.i);

      this.success = true;

      if (result.errorCode == 0) {
        this.message = this.$t('text.emailConfirmed1') + " " + result.emailAddr + " " + this.$t('text.emailConfirmed2');
        return;
      }
      this.message = result.message;
    }
}

</script>
