<template>
  <div class="creditCardPaymentWrapper" id="credit-card-payment">

    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>

    <iframe frameborder="0" width="100%" height="1000px" :onload="onLoad" style="margin-top:70px;" id="ExternalPaymentFormFrame" name="ExternalPaymentFormFrame" :src="redirectUrl"></iframe>

  </div>
</template>

<script>
import VueLoading from 'vue-loading-overlay'
import {paymentService} from "@/services/api/paymentService";
import {PaymentProviders} from "@/constants/constants";

export default {
  data() {
    return {
      isLoading: true,
      redirectUrl: ""
    }
  },
  methods: {
    async startCheckout() {
      try {
        const response = await paymentService.initPayment(PaymentProviders.NOVALNET);
        this.redirectUrl = response.redirectURL;

        document.getElementById('ExternalPaymentFormFrame').onload = function() {
          this.onLoad();
        }

        this.scrollFrameIntoView();
      } catch (error) {
        console.error(error);
        alert("Error occurred. Look at console for details");
      }
    },
    scrollFrameIntoView() {
      setTimeout(() => {
        var objDiv = document.getElementsByClassName("content")[0];
        var iFrame = document.getElementById("ExternalPaymentFormFrame");

        if (window.screen.width < 700) {
          objDiv.scrollTop = objDiv.scrollHeight;
          if (iFrame != null)
            iFrame.scrollIntoView({behavior: "smooth"});
          setTimeout(() => {
            objDiv.scrollBy({
              top: -100,
              left: 0,
              behavior: 'smooth'
            });
          }, 800);
        } else {
          objDiv.scrollTop = objDiv.scrollHeight;
          if (iFrame != null)
            iFrame.scrollIntoView({behavior: "smooth"});
          setTimeout(() => {
            objDiv.scrollBy({
              top: -100,
              left: 0,
              behavior: 'smooth'
            });
          }, 800);
        }
      }, 200);
    },
    onLoad() {
      this.isLoading = false;
    }
  },
  components: {
    'loading': VueLoading
  },
  async mounted() {
    await this.startCheckout();
  }
}
</script>
