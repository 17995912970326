<template>
  <div>
    <img id="myImage" :src="backgroundImagePath">

    <div>
      <div class="col-12" style="padding-top:15%">
        <img style="max-width:150px;width:100%" :src="logoPath"/>
      </div>

      <div class="col-12 fontTitle fontLandingTitle">
        {{ $t('title.landingPage') }}
      </div>

      <div class="col-12 fontContent fontLandingContent" v-if="tableId">
        <p>{{ $t('text.landingPageWithTableId', { table: tableId }) }}</p>
      </div>

      <div class="col-12 fontContent fontLandingContent" v-if="orderImmediate && ordersPossible">
        {{ $t('text.landingPageBodyWithoutTableId') }}
      </div>

      <div class="col-12 fontContent fontLandingContent" v-if="orderImmediate && !ordersPossible">
        {{ $t('text.landingPageBlockedText') }}
      </div>

      <div class="col-12 btnLandingWrapper" v-if="ordersPossible">
        <b-button class="btn btn-primary btnLanding" @click="doNavigationByAppMode">{{ $t('button.landingOrder') }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Utils from "@/utils/utils";
import {Mode, OrderTypes, Workflow} from "@/constants/constants";
import {orderService} from "@/services/api/orderService";
import {useLocaleStore} from "../../stores/locale";
import {useAppStore} from "../../stores/app";
import {useOrderStore} from "../../stores/order";
import {useMealPlanStore} from "@/stores/mealPlan";
import {useShopStore} from "@/stores/shop";
import {useTableStore} from "@/stores/table";
import {getCurrentDateTimeISO} from "@/utils/dateUtils";

export default {
  data() {
    return {
      ordersPossible: true,
    };
  },
  props: {
    shopId: {
      Type: Number
    },
    tableId: {
      Type: String
    },
    orderImmediate: {
      Type: Boolean
    },
    isMealPlan: {
      Type: Boolean
    },
    locale: {
      Type: String
    }
  },
  setup() {
    const localeStore = useLocaleStore();
    const appStore = useAppStore();
    const orderStore = useOrderStore();
    const mealPlanStore= useMealPlanStore();
    const shopStore = useShopStore();
    const tableStore = useTableStore();

    return {
      localeStore,
      appStore,
      orderStore,
      mealPlanStore,
      shopStore,
      tableStore
    };
  },
  computed: {
    backgroundImagePath() {
      return process.env.BASE_URL + 'settings/resources/LandingBackground.jpg';
    },
    logoPath() {
      return process.env.BASE_URL + 'settings/resources/logo.png';
    },
  },
  methods: {
    async changeLanguage(newLocale) {
      await this.localeStore.setLocale(newLocale);
    },
    checkForceLogin() {
      if (window.config.FORCE_LOGIN_BEFORE_USE) {
        console.log("User not authenticated, redirecting to Login");
        this.$navigateToLogin();
      }
    },
    async doNavigationByAppMode() {
      if (this.shopId) {
        if (this.orderImmediate) {
          await this.doImmediateOrder();
          await this.doCheckIfOrdersAreCurrentlyPossible();

          if (this.isMealPlan) {
            this.$navigateToMealPlanner(true);
          } else {
            this.$navigateToMenu(true);
          }
        } else if (this.tableId) {
          Object.assign(window.config, window.configTablemode);

          await this.selectTableByQRCode(this.shopId, this.tableId);
          this.checkForceLogin();
          this.$navigateToMenu(true);
        } else {
          this.checkForceLogin();
          if (this.isMealPlan) {
            this.$navigateToMealPlanner(true);
          } else {
            this.$navigateToShop(true);
          }
        }
      } else {
        this.$navigateToErrorLanding();
      }
    },
    async doCheckIfOrdersAreCurrentlyPossible() {
      let hasTimeSlotForDay = false;

      const result = await orderService.getTimeSlots();
      const availabilities = result.availabilityList;
      if (availabilities === undefined) {
        this.makeToast('danger', this.$t('notification.noTimeslotsAvailable'));
        this.$navigateToErrorLanding();
      } else {
        const mapDayWithTimeslots = new Map(
            availabilities.map(obj => {
              return [new Date(obj.date).toLocaleDateString(), obj.timeslots];
            })
        );

        const currentDate = new Date();
        if (mapDayWithTimeslots.size > 0) {
          const timeslots = mapDayWithTimeslots.get(currentDate.toLocaleDateString());
          if (timeslots.length > 0) {
            timeslots.some(timeslot => {
              const dateFrom = Utils.parseTimeHHMMSS(timeslot.fromtime);
              const dateTo = Utils.parseTimeHHMMSS(timeslot.totime);
              if (currentDate <= dateTo && currentDate >= dateFrom) {
                hasTimeSlotForDay = true;
              }
            });
          }
        }

        if (hasTimeSlotForDay)
        {
          hasTimeSlotForDay = false;

          const resultOcc = await orderService.getTimeSlotOccupancies(1, currentDate);
          const occupancies = resultOcc.timeSlots;
          if (occupancies === undefined) {
            this.makeToast('danger', this.$t('notification.noTimeslotsAvailable'));
            this.$navigateToErrorLanding();
          } else {
            occupancies.some(timeslot => {
              const dateFrom = Utils.parseTimeHHMMSS(timeslot.fromTime);
              const dateTo = Utils.parseTimeHHMMSS(timeslot.toTime);
              console.log(dateFrom + " " + dateTo + " " + currentDate);
              if (currentDate <= dateTo && currentDate >= dateFrom && timeslot.slots > 0 && timeslot.usable > 0) {
                hasTimeSlotForDay = true;
              }
            });
          }

          if (hasTimeSlotForDay) {
            this.ordersPossible = true;
          }
        }
      }
    },
    async doImmediateOrder() {
      const result = await orderService.immediateOrder(this.shopId);
      if (result.exception !== undefined) {
        this.makeToast('danger', this.$t('notification.failedGeneric'));
        this.$navigateToErrorLanding();
      }
    },
    async selectTableByQRCode(shopCode, tableCode) {
      const result = await orderService.selectTableByQRCode(shopCode, tableCode)
      if (result.exception !== undefined) {
        this.makeToast('danger', this.$t('notification.failedGeneric'));
        this.$navigateToErrorLanding();
      }

      const order = this.orderStore.addOrder(this.shopId, OrderTypes.DINE_IN, getCurrentDateTimeISO());
      this.appStore.setCurrentOrderId(order.orderId);

      this.shopStore.setShop(result.shop.id, result.shop.custom1, result.shop.custom2, result.shop.name, result.shop.image, result.shop.custom6);
    },
  },
  created() {
    if (this.shopId) {
      if (this.orderImmediate) {
        if (this.isMealPlan) {
          this.appStore.setWorkflow(Workflow.MEAL_PLAN);
        } else {
          this.appStore.setWorkflow(Workflow.ORDER);
        }
        this.appStore.setMode(Mode.IMMEDIATE);
      } else if (this.tableId) {
        this.appStore.setWorkflow(Workflow.ORDER);
        this.appStore.setMode(Mode.TABLE);

        this.tableStore.setTable(this.tableId, this.shopId);
      } else {
        if (this.isMealPlan) {
          this.appStore.setWorkflow(Workflow.MEAL_PLAN);
        } else {
          this.appStore.setWorkflow(Workflow.ORDER);
        }
        this.appStore.setMode(Mode.SHOP);
      }
    }
  }
};
</script>
