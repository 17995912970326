<template>
  <div id="mealPlans">
    <vue-loading v-if="isLoading" />

    <div id="mealPlan" v-if="mealPlan" class="d-flex gap10 flex-column">
      <div v-for="group in mealPlan.groups" :key="group.description" class="meal-container">
        <div class="meal-group">
          <h2 class="meal-group-title py-1" style="font-size:20px;">
            <span class="text-left">{{ group.description }}</span>
            <span class="text-right">
              <strong>{{ productCountByGroup(group) }}</strong> / <strong>{{ group.maxProducts }}</strong>
              <span v-if="group.minProducts > 0" class="mealplan-group-requirement"> ({{ $t('text.minProducts') }}: {{ group.minProducts }})</span>
            </span>
          </h2>
        </div>
        <div class="grid-layout">
          <div
              v-for="product in group.items"
              :key="product?.kkProdId"
              :class="{ 'disabled-item': !isMealPlanValid || product.locked }"
          >
            <div :id="product.kkProdId" class="meal-item">
              <img v-if="product.image1" :src="product.image1" :alt="product.name" class="meal-image" @click="viewProductDetails(group, product, getMaxAvailableForProduct(group, product))" />
              <div class="d-flex flex-column gap10 flex-grow-1 justify-content-between">
                <div class="meal-details" @click="viewProductDetails(group, product, getMaxAvailableForProduct(group, product))">
                  <div class="meal-description">
                    <span>{{ product.name }}</span>
                    <div class="properties"></div>
                    <div class="allergens">
                      <div v-for="property in product.properties" :key="property.id">
                        <div>
                          <img v-if="property.image" :src="property.image" :alt="property.name" class="property-img" />
                        </div>
                      </div>
                      <div v-for="allergen in product.allergens" :key="allergen.id">
                        <div>
                          <img v-if="allergen.image" :src="allergen.image" :alt="allergen.name" class="allergen-img" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row gap10 w-100 flex-wrap">
                  <div
                      class="button"
                      :class="{ disabled: !isMealPlanValid }"
                      @click="viewProductDetails(group, product, getMaxAvailableForProduct(group, product))"
                      role="button"
                      tabindex="0"
                      aria-label="View product details"
                  >
                    <font-awesome-icon :icon="['fas', 'circle-info']" />
                  </div>
                  <SpinButton
                    :class="{ disabled: !isMealPlanValid || product.locked }"
                    :input-disabled="true"
                    :min=0
                    :max="getMaxAvailableForProduct(group, product)"
                    :modelValue="selectedProductsCount[group.description]?.[product.kkProdId] || 0"
                    @update:modelValue="(quantity) => handleProductUpdate(product, group, quantity)"
                    role="button"
                    tabindex="0"
                    aria-label="Add or remove product from basket"
                    v-if="canOrder"
                  />
                  <span class="meal-price" style="white-space: nowrap">{{ product.formattedPrice }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal for product details -->
      <b-modal id="product-details-modal" hide-footer :title="selectedProduct ? selectedProduct.name : ''">
        <ProductDetails
          v-if="selectedProduct && selectedProductGroup"
          :productId="selectedProduct.kkProdId"
          :max-quantity="selectedProductMaxQuantity"
          :min-quantity="selectedProductMinQuantity"
          :actual-quantity="selectedProductActualQuantity"
          :group="selectedProductGroup"
          :view-mode="!canOrder"
          @add-to-basket="updateProductCount"
          @close="closeModal" />
      </b-modal>

    </div>
  </div>
</template>

<script>
import ProductDetails from "@/components/sub-components/ProductDetails.vue";
import { basketService } from "@/services/api/basketService";
import VueLoading from "vue-loading-overlay";
import SpinButton from "@/components/page-components/templates/SpinButton.vue";
import {useMealPlanStore} from "@/stores/mealPlan";
import {OrderTypes} from "@/constants/constants";
import {useAppStore} from "@/stores/app";
import {useShopStore} from "@/stores/shop";

export default {
  name: "MealPlan",
  components: {
    SpinButton,
    VueLoading,
    ProductDetails,
  },
  props: {
    mealPlan: {
      type: Object,
      required: true,
    },
    reservationDateTime: {
      type: Date,
      required: true,
    }
  },
  emits:['changed'],
  data() {
    return {
      isLoading: false,
      selectedProduct: {},
      selectedProductGroup: {},
      selectedProductsCount: {}, // Track selected products count by group
      selectedProductMaxQuantity: 0,
      selectedProductActualQuantity: 0,
      selectedProductMinQuantity: 0
    };
  },
  computed: {
    isMealPlanValid() {
      if (!this.mealPlan) {
        return false;
      }

      const currentTime = new Date();
      const mealPlanStartDate = this.getMealPlanStartDate(this.mealPlan);
      return currentTime < mealPlanStartDate;
    },
    maxProductsForGroups() {
      return this.mealPlan.groups.map(group => {
        return {
          group,
          maxAvailableForProducts: group.items.map(product => {
            const currentGroupCount = this.productCountByGroup(group);
            const currentProductCount = this.selectedProductsCount[group.description]?.[product.kkProdId] || 0;

            return {
              product,
              maxAvailable: Math.max(0, group.maxProducts - currentGroupCount + currentProductCount),
            };
          })
        };
      });
    },
    canOrder(){
      return this.config.MEAL_PLAN.withOrdering;
    },
    /** @file public/settings/config/default.js */
    config() {
      return window.config;
    }
  },
  setup() {
    const mealPlanStore = useMealPlanStore();
    const appStore = useAppStore();
    const shopStore =useShopStore()

    return {
      mealPlanStore,
      appStore,
      shopStore
    };
  },
  mounted() {
    this.initializeSelectedProducts();
  },
  methods: {
    initializeSelectedProducts() {
      const order = this.mealPlanStore.getOrder(this.appStore.currentMealPlanOrderId);
      if (order) {
        this.selectedProductsCount = {};

        order.products.forEach(product => {
          const group = this.mealPlan.groups.find(g => g.id === product.groupId);

          if (group) {
            if (!this.selectedProductsCount[group.description]) {
              this.$set(this.selectedProductsCount, group.description, {});
            }

            this.$set(this.selectedProductsCount[group.description], product.id, product.quantity);
          }
        });
      }
    },
    closeModal() {
      this.$bvModal.hide("product-details-modal");
    },
    async doSubmitForMenuItems() {
      let count = 0;
      let optLines = [];

      if (this.selectedValues.length == 0) {
        let opt = "optdiv" + this.articleObj.prodId;
        let optLineDesc = {optLine: opt, prodId: this.articleObj.prodId};
        optLines.push(optLineDesc);
      }

      for (let j = 0; j < this.selectedValues.length; j++) {
        count++;
        let optLine = this.selectedValues[j].orderString + '_' + count;
        let optLineDescription = {optLine: optLine, prodId: this.selectedValues[j].prodId};
        optLines.push(optLineDescription);
      }

      let paramsNoError = {
        scroll: 0,
        freeText: this.articleComment,
        weightOrVolume: this.decimalQuantity ? this.weightOfArticle / 1000 : undefined,
      };

      if (optLines.length > 0) {
        for (var x = 0; x < optLines.length; x++) {
          var paramName = optLines[x].optLine;
          paramsNoError[paramName] = optLines[x].prodId;
        }
        paramsNoError.kkjson = this.encode64(JSON.stringify(paramsNoError));
      }

      const quantity = this.decimalQuantity ? undefined : this.menge;
      const result = await basketService.addProductToBasket(this.articleObj.kkProdId, OrderTypes.DINE_IN, this.authStore.isAuthenticated, quantity, paramsNoError);
      await this.handleSubmitResult(result);
    },
    handleProductUpdate(product, group, quantity) {
      // Check if the product has children
      if (product.hasChildren) {
        // If the product has children, open product details
        this.viewProductDetails(group, product);
      } else {
        // If no children, update the product count
        this.updateProductCount(group, product.kkProdId, quantity, product.price);
      }
    },
    updateProductCount(group, productId, quantity, price) {
      if (!this.selectedProductsCount[group.description]) {
        this.$set(this.selectedProductsCount, group.description, {});
      }
      if (!this.selectedProductsCount[group.description][productId]) {
        this.$set(this.selectedProductsCount[group.description], productId, 0);
      }

      const currentQuantity = this.productCountByGroup(group, productId);

      if (quantity > currentQuantity) {
        const difference = quantity - currentQuantity;
        this.addToBasket(group, productId, difference, price);
      } else if (quantity < currentQuantity) {
        const difference = currentQuantity - quantity;
        this.removeFromBasket(group, productId, difference);
      }

      // Ensure reactivity
      this.$set(this.selectedProductsCount[group.description], productId, quantity);

      // Emit change event
      this.$emit('changed', Object.values(this.selectedProductsCount)
        .flatMap(Object.values)
        .reduce((a, b) => a + b, 0));
    },
    productCountByGroup(group, productId) {
      if (!this.selectedProductsCount[group.description]) {
        return 0;
      }

      if (productId) {
        return this.selectedProductsCount[group.description][productId] || 0;
      } else {
        return Object.values(this.selectedProductsCount[group.description]).reduce((sum, qty) => sum + qty, 0);
      }
    },
    isGroupMaxReached(group) {
      return this.productCountByGroup(group) >= group.maxProducts;
    },
    addToBasket(group, productId, quantity, price) {
      // TODO comment field
      const result = basketService.addProductToBasket(productId, OrderTypes.DINE_IN, false, quantity);
      if ((result.exception !== undefined)) {
        if (result.exception.code === 64)
        {
          this.makeToast('danger', this.$t('notification.addItemMinError'));
        }
        else if (result.exception.code === 65) {
          this.makeToast('danger', this.$t('notification.addItemMaxError'));
        }
        else {
          this.makeToast('danger', this.$t('notification.addItemFailed'));
        }
      }

      const order = this.mealPlanStore.addOrder(this.shopStore.getShopId, OrderTypes.DINE_IN, this.mealPlan.id, this.reservationDateTime);
      this.appStore.setCurrentMealPlanOrderId(order.orderId);
      console.log(`Stored orderId: ${order.orderId} in appStore`);

      this.mealPlanStore.addProductToOrder(this.appStore.currentMealPlanOrderId, productId, price, quantity, {
        id: group.id,
        name: group.description,
        minProducts: group.minProducts,
        maxProducts: group.maxProducts
      });

      // Ensure the group exists
      if (!this.selectedProductsCount[group.description]) {
        this.$set(this.selectedProductsCount, group.description, {});
      }
      this.$set(this.selectedProductsCount[group.description], productId, this.productCountByGroup(group, productId) + quantity);
    },
    removeFromBasket(group, productId, quantity) {
      const result = basketService.removeProductFromBasketByProductId(productId, quantity, OrderTypes.DINE_IN);
      if (result.exception !== undefined) {
        if (result.exception.code === 64) {
          this.makeToast('danger', this.$t('notification.removeItemMinError'));
          return;
        } else if (result.exception.code === 65) {
          this.makeToast('danger', this.$t('removeItemMaxError'));
          return;
        }
        this.makeToast('danger', this.$t('notification.removeItemFailed'));
        return;
      }

      // Ensure the group exists
      if (!this.selectedProductsCount[group.description]) {
        this.$set(this.selectedProductsCount, group.description, {});
      }

      const currentQuantity = this.productCountByGroup(group, productId);
      this.$set(this.selectedProductsCount[group.description], productId, currentQuantity - quantity);

      this.mealPlanStore.removeProductFromOrder(this.appStore.currentMealPlanOrderId, productId, quantity);
    },
    getMealPlanStartDate(mealPlan) {
      const validDate = new Date(mealPlan.dateValid);

      if(mealPlan.formattedStart)
      {
        const [startHour, startMinute] = mealPlan.formattedStart.split(':');
        validDate.setHours(startHour, startMinute, 0, 0);
      }
      else
      {
        validDate.setHours(0, 0, 0, 0);
      }

      return validDate;
    },
    getMaxAvailableForProduct(group, product) {
      const groupData = this.maxProductsForGroups.find(
        g => g.group.description === group.description
      );

      if (!groupData) return 0;

      const productData = groupData.maxAvailableForProducts.find(
        p => p.product.kkProdId === product.kkProdId
      );

      return productData ? productData.maxAvailable : 0;
    },
    viewProductDetails(group, product, maxProduct, acutalQuantity) {
      if (this.isMealPlanValid && !product.locked && !this.isGroupMaxReached(group)) {
        this.selectedProduct = product;
        this.selectedProductGroup = group;
        this.selectedProductMaxQuantity = maxProduct;
        this.selectedProductMinQuantity = acutalQuantity === 0 && maxProduct !== 0 ? 1 : 0;
        this.selectedProductActualQuantity = acutalQuantity === 0 && maxProduct !== 0 ? 1 : acutalQuantity;
        this.$bvModal.show("product-details-modal");
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/css/customizer';

/* Style updates for disabled state */
.button.disabled {
  background-color: #d3d3d3; /* Light gray to indicate disabled */
  pointer-events: none;
}

.disabled-item {
  opacity: 0.5;
  pointer-events: none;
}

.meal-container {
  background-color: var(--page-container-background);
  border-radius: var(--button-border-radius);
  margin-top: 10px
}

.meal-group {
  background: custom-chain(mealplan-group-color,var(--page-group-header));
  margin: 0 auto;
  padding: 0 10px;
  border-radius: var(--button-border-radius);
}

.meal-group-title {
  font-size: 16px;
  color: custom-chain(mealplan-group-color-text,var(--page-group-title));
  display: flex;
  justify-content: space-between; /* Space between the two spans */
  align-items: center; /* Center the text vertically */
  width: 100%; /* Ensure it takes the full width */
}

.mealplan-group-requirement {
  color: custom-chain(mealplan-group-color-requirement,red);
}

.text-left {
  flex: 1; /* Allow the left text to grow */
  text-align: left; /* Align left text */
}

.text-right {
  text-align: right; /* Align right text */
}

.meal-item {
  display: flex;
  flex: 1 0 360px;
  gap: 10px;
  text-align: left;
  align-items: stretch;
  padding: 10px;
  border-radius: var(--button-border-radius);
  background-color: var(--page-group-item);
  transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transition effect */
  height: 100%;
}

.meal-item:hover {
  background-color: #e0e0e0;
  transform: scale(1.02);
  cursor: pointer;
}

.meal-image {
  width: 120px;
  height: auto;
  border: custom-chain('mealplan-productimage-border','');
  border-radius: custom-chain('mealplan-productimage-border-radius','images-border-radius',var(--button-border-radius));
  object-fit: cover;
}

.meal-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.meal-description {
  font-weight: bold;
  text-align: left;
}

.properties {
  filter: hue-rotate(45deg);
}

.allergens, .properties {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  margin-top: 5px;
}

.allergen-img,
.property-img {
  max-width: 30px;
}

.meal-price {
  color: custom-chain(mealplan-meal-price-text,var(--background-text));
  font-size: 14px;
  align-self: center;
  flex-grow: 1;
  text-align: right;
}

.button {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s;
}

.button:hover,
.button:focus {
  background-color: #0056b3;
}

.button:active {
  background-color: #004494;
}
</style>
