<template>
  <div>
    <loading
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="true"
        loader="bars"
    ></loading>

    <div id="shop">
      <div class="shopDetail">
        <div class="shopDetailCard">
          <div class="shopSelectionImage">
            <img :src="shopImageUrl" :alt="$t('table.shop')" class="imageCoversParent"/>
          </div>
          <div class="shopCardData text-left">
            <p class="mainColor" style="font-weight: 500;">{{ shopName }}</p>

            <div v-html="formattedShopAddr" class="text-left"></div>
          </div>
        </div>
      </div>
      <div class="shopOptions">

        <!-- Order Type Dropdown -->
        <Dropdown
          :label="$t('field.orderType')"
          :options="orderTypeOptions"
          v-model="selectedOrderType"
          :required="true"
          placeholder="Choose an option..."
          sortable="asc"
          @selection-changed="handleSelectedDate"
        />

        <!-- Date Dropdown -->
        <Dropdown
          v-if="dateOptions.length"
          :label="$t('field.date')"
          :options="dateOptions"
          v-model="selectedDate"
          :required="true"
          placeholder="Choose an option..."
          @selection-changed="handleSelectedDate"
        />

        <!-- Time Slot Dropdown -->
        <Dropdown
          v-if="timeSlotOptions.length"
          :label="$t('field.time')"
          :options="timeSlotOptions"
          v-model="selectedTimeSlot"
          :required="true"
          placeholder="Choose an option..."
        />

        <Button :disabled="!this.selectedOrderType || !this.selectedDate || !this.selectedTimeSlot" @click="submit">{{ $t('button.confirm') }}</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { useOrderStore } from "@/stores/order";
import { orderService } from "@/services/api/orderService";
import { shopService } from "@/services/api/shopService";
import loading from "vue-loading-overlay";
import Dropdown from "@/components/barrier-free-template-components/Dropdown.vue";
import Button from "@/components/barrier-free-template-components/Button.vue";
import {useAppStore} from "@/stores/app";
import {useShopStore} from "@/stores/shop";
import {getOrderTypeByNumber} from "@/utils/orderUtils";
import {Workflow} from "@/constants/constants";
import {capitalizeFirstLetter} from "@/utils/utils";

export default {
  components: {Button, Dropdown, loading },
  inject: ["setPageMetaTitle"],
  setup() {
    const orderStore = useOrderStore();
    const appStore = useAppStore();
    const shopStore = useShopStore();

    return { orderStore, appStore, shopStore };
  },
  data() {
    return {
      isLoading: false,
      selectedOrderType: null,
      selectedDate: null,
      selectedTimeSlot: null,
      orderTypeOptions: [],
      dateOptions: [],
      timeSlotOptions: [],
      formattedShopAddr: null,
      shopImageUrl: null,
      shopName: null,
    };
  },
  methods: {
    async fetchTimeSlots() {
      this.isLoading = true;
      try {
        const result = await orderService.getTimeSlots();

        if (!result.availabilityList || !result.orderTypes) {
          this.makeToast("danger", this.$t("notification.noTimeslotsAvailable"));
          return;
        }

        this.formattedShopAddr = result.formattedShopAddr;

        // Map and set order types
        this.orderTypeOptions = result.orderTypes
            .filter((entry) => entry.name !== "none")
            .map((entry) => ({
              value: entry.code,
              text: capitalizeFirstLetter(entry.name),
            }));

        // Preselect the first order type
        if (this.orderTypeOptions.length > 0) {
          this.selectedOrderType = this.orderTypeOptions[0].value;
        }

        // Map and set available dates
        this.dateOptions = result.availabilityList
            .filter((entry) => !entry.closed)
            .map((entry) => ({
              value: entry.date,
              text: new Date(entry.date).toLocaleDateString(),
            }));

        // Preselect the first order type
        if (this.dateOptions.length > 0) {
          this.selectedDate = this.dateOptions[0].value;
        }
      } finally {
        this.isLoading = false;
      }
    },
    async selectShop(shopId) {
      this.isLoading = true;
      try {
        await shopService.selectShop(shopId);

        this.shopImageUrl = this.shopStore?.imageUrl;
        this.shopName = this.shopStore?.name;
      } finally {
        this.isLoading = false;
      }
    },
    async fetchTimeSlotOccupancies(orderType, date) {
      this.isLoading = true;
      try {
        const result = await orderService.getTimeSlotOccupancies(orderType, date);

        if (!result.timeSlots || result.timeSlots.length === 0) {
          this.makeToast("danger", this.$t("notification.noTimeslotsAvailable"));
          return;
        }

        // Map and set time slots
        this.timeSlotOptions = result.timeSlots.map((slot) => ({
          value: `${slot.fromTime}-${slot.toTime}`,
          text: `${slot.fromTime} - ${slot.toTime}`,
        }));

        // Preselect the first time slot
        if (this.timeSlotOptions.length > 0) {
          this.selectedTimeSlot = this.timeSlotOptions[0].value;
        }
      } finally {
        this.isLoading = false;
      }
    },
    handleSelectedDate() {
      if (this.selectedDate) {
        this.fetchTimeSlotOccupancies(this.selectedOrderType, this.selectedDate);
      }
    },
    submit() {
      // Find the selected time slot
      const selectedSlot = this.timeSlotOptions.find(
          (slot) => slot.value === this.selectedTimeSlot
      );

      if (!selectedSlot) {
        this.makeToast("danger", this.$t("notification.invalidTimeSlot"));
        return;
      }

      // Extract the time part (e.g., '16:01:00') from the selectedSlot
      const toTime = selectedSlot.value.split('-')[1] || '00:00:00';

      // Parse selectedDate into a Date object
      const date = new Date(this.selectedDate);

      if (isNaN(date)) {
        this.makeToast("danger", this.$t("notification.invalidDate"));
        return;
      }

      // Split the time into hours, minutes, and seconds
      const [hours, minutes, seconds] = toTime.split(':').map((part) => parseInt(part, 10) || 0);

      // Update the Date object with the selected time
      date.setHours(hours, minutes, seconds);

      // Format the resulting Date object into an ISO string or a backend-expected format
      const pickupTime = this.toLocalISOString(date);

      // Call the order service to select the time slot
      orderService.selectTimeSlot(pickupTime);

      if (this.appStore.isWorkflow(Workflow.ORDER)) {
        const selectedOrderType = getOrderTypeByNumber(this.selectedOrderType);
        const order = this.orderStore.addOrder(this.shopStore.getShopId, selectedOrderType, pickupTime);
        this.appStore.setCurrentOrderId(order.orderId);
      }

      this.$navigateToMenu();
    },
    toLocalISOString(date) {
      const offset = date.getTimezoneOffset() * 60000;
      const localDate = new Date(date - offset);
      return localDate.toISOString().slice(0, -1);
    }
  },
  async created() {
    try {
      await this.selectShop(this.shopStore.getShopId);
      await this.fetchTimeSlots();
      await this.fetchTimeSlotOccupancies(this.selectedOrderType, this.selectedDate);

      const shopName = this.shopName;
      if (typeof shopName === "string") {
        await this.setPageMetaTitle(shopName);
      }
    } catch (error) {
      console.error("Error during component initialization:", error);
    }
  },
};
</script>

<style scoped lang="scss">
@import '@/css/customizer';

.orderOptionsTitle {
  font-weight: bold;
  font-size: 20px;
}

.shopSelectionImage{
  height: 200px;
}
.shopSelectionImage>img{
  border-radius: custom-chain(images-border-radius,var(--button-border-radius));
}

#shop {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;

  & > * {
    flex: 1 0 320px;
    //flex-grow: 1;
  }
}
@media screen and (max-width: 768px) {
  #shop{
    flex-direction: column;
    & > * {
      flex: 0; /* reset flex and base */
    }
  }
}

</style>
