import { apiUtils } from "@/utils/apiUtils";

export const addressService = {
  async getById(addressId) {
    try {
      const result = await apiUtils.doGet('/TCPEditAddrJ.action', {
        json: "true",
        addrId: addressId,
      });
      return result;
    } catch (error) {
      console.error("Error on getting address by id:", error);
      throw error;
    }
  },
  async getAll() {
    try {
      const result = await apiUtils.doGet('/TCPAddrBookJ.action', {
        json: "true",
      });
      return result;
    } catch (error) {
      console.error("Error on getting addresses:", error);
      throw error;
    }
  },
  async createAddress(gender, firstName, lastName, city, company, countryId, postcode, state,
                    streetAddress, streetAddress1, suburb, emailAddress, telephoneNumber, telephoneNumber1) {
    try {
      const result = await apiUtils.doPost('/TCPNewAddrSubmitJ.action', {
        json: "true",
        countryChange: "0",
        gender: gender,
        firstName: firstName,
        lastName: lastName,
        city: city,
        company: company,
        countryId: countryId.toString(),
        postcode: postcode,
        state: state,
        streetAddress: streetAddress,
        streetAddress1: streetAddress1,
        suburb: suburb,
        emailAddrOptional: emailAddress,
        telephoneNumber: telephoneNumber,
        telephoneNumber1: telephoneNumber1,
      });
      return result;
    } catch (error) {
      console.error("Error on creating address:", error);
      throw error;
    }
  },
  async editAddress(gender, firstName, lastName, city, company, countryId, postcode, state,
                    streetAddress, streetAddress1, suburb, emailAddress, telephoneNumber, telephoneNumber1) {
    try {
      const result = await apiUtils.doPost('/TCPEditAddrSubmitJ.action', {
        json: "true",
        countryChange: "0",
        gender: gender,
        firstName: firstName,
        lastName: lastName,
        city: city,
        company: company,
        countryId: countryId.toString(),
        postcode: postcode,
        state: state,
        streetAddress: streetAddress,
        streetAddress1: streetAddress1,
        suburb: suburb,
        emailAddrOptional: emailAddress,
        telephoneNumber: telephoneNumber,
        telephoneNumber1: telephoneNumber1,
      });
      return result;
    } catch (error) {
      console.error("Error on editing address:", error);
      throw error;
    }
  },
  async deleteById(addressId) {
    try {
      const result = await apiUtils.doPost('/TCPDeleteAddrSubmitJ.action', {
        json: "true",
        addrId: addressId,
      });
      return result;
    } catch (error) {
      console.error("Error on deleting address:", error);
      throw error;
    }
  },
  async changeById(addressId) {
    try {
      const result = await apiUtils.doPost('/TCPCheckoutChangeDeliveryAddrJ.action', {
        json: "true",
        addrId: addressId,
      });
      return result;
    } catch (error) {
      console.error("Error on changing address:", error);
      throw error;
    }
  },
};
