import {apiUtils} from "@/utils/apiUtils";
import moment from "moment/moment";

export const orderService = {
  async getTimeSlots() {
    try {
      const result = await apiUtils.doGet('/TCPTimeJ.action', {
        json: "true",
        enableCaching: false,
        crcIn: "e046f966"
      });
      return result;
    } catch (error) {
      console.error("Error on getting time slots:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  },
  async selectTimeSlot(pickupTime) {
    try {
      const result = await apiUtils.doPost('/TCPTimeSubmitJ.action', {
        json: 'true',
        pickupTime: pickupTime
      });
      return result;
    } catch (error) {
      console.error("Error on selecting time slot:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  },
  async getTimeSlotOccupancies(orderType, date) {
      try {
          const result = await apiUtils.doGet('/TCPGetTimeSlotOccupancyJ.action', {
              json: "true",
              orderType: orderType,
              day: moment(date.day).format("YYYY-MM-DD")
          });
          return result;
      } catch (error) {
          console.error("Error on getting time slot occupancies:", error);
          throw error;
      }
  },
  async cancelOrder(shopId, guid, returnPrepayBalance) {
      try {
          const result = await apiUtils.doPost('/TCPDeleteOrderSubmitJ.action', {
              json: "true",
              shopId: shopId,
              guid: guid,
              operation: "D",  // Operation 'D' to delete the order
              returnPrepayBalance: returnPrepayBalance,
          });

          return result;
      } catch (error) {
          console.error("Error cancelling the order:", error);
          throw error; // Re-throw the error to handle it in the component
      }
  },
  async immediateOrder(shopId) {
      try {
          const result = await apiUtils.doPost('/TCPImmediateOrderJ.action', {
              "json": "true",
              "shopCode": shopId,
          });
          return result;
      } catch (error) {
          console.error("Error on immediate order:", error);
          throw error; // Re-throw the error to handle it in the component
      }
  },
  async selectTableByQRCode(shopCode, tableCode) {
      try {
          const result = await apiUtils.doGet('/TCPSelectTableByQRCodeJ.action', {
              "json": "true",
              "billNum": "1",
              "shopCode": shopCode,
              "tableCode": tableCode
          });

          return result;
      } catch (error) {
          console.error("Error selecting table by QR-Code:", error);
          throw error;
      }
  },
  async getOrders(dateFrom, dateTo) {
    try {
      const result = await apiUtils.doGet('/TCPRetrieveOrdersJ.action', {
        dateFrom: dateFrom,
        dateTo: dateTo,
      });
      return result;
    } catch (error) {
      console.error("Error getting orders:", error);
      throw error;
    }
  },
  async getOrderDetails(guid, shopId) {
    try {
      const result = await apiUtils.doGet('/TCPOrderDetailsJ.action', {
        json: "true",
        guid: guid,
        shopId: shopId
      });
      return result;
    } catch (error) {
      console.error("Error getting order details:", error);
      throw error;
    }
  },
  async calculateOrder(orderType, options = {}) {
    try {
      return await apiUtils.doPost('/TCPCalculateOrderJ.action', {
        json: "true",
        orderType: orderType,
        ...options
      });
    } catch (error) {
      console.error("Error calculating the order:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  },
  async addItemsToOrder(emptyBasket) {
    try {
      return await apiUtils.doPost('/TCPAddItemsToOrderJ.action', {
        json: "true",
        emptyBasket: emptyBasket,
      });
    } catch (error) {
      console.error("Error add items to order:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  },
  async getCurrentOrder(orderType, calculateOrder, promotionBarcode) {
    try {
      return await apiUtils.doGet('/TCPConfirmOrderJ.action', {
        json: "true",
        orderType: orderType,
        calculateOrder: calculateOrder,
        promotionBarcode: promotionBarcode,
      });
    } catch (error) {
      console.error("Error on getting current order:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  },
  async confirm(orderType, comment, promotionBarcode, calculateOrder, options = {}) {
    try {
      return await apiUtils.doPost('/TCPConfirmOrderSubmitJ.action', {
        json: "true",
        orderType: orderType,
        promotionBarcode: promotionBarcode,
        calculateOrder: calculateOrder,
        ...options,
      });
    } catch (error) {
      console.error("Error on confirm order:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  },
  async addTip(amount, calculateOrder) {
    try {
      return await apiUtils.doPost('/TCPConfirmOrderSubmitJ.action', {
        json: "true",
        tip: amount,
        calculateOrder: calculateOrder,
      });
    } catch (error) {
      console.error("Error on adding tip to order:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  },
  async checkout() {
    try {
      return await apiUtils.doGet('/TCPCheckoutFinishedJ.action', {
        json: "true",
      });
    } catch (error) {
      console.error("Error on checking out order:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  },
};
