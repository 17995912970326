<template>
  <section class="meal-plan-confirmation" aria-labelledby="basket-summary-title">
    <!--<h2 id="basket-summary-title" class="title">Basket Summary</h2>-->

    <!-- Basket Items -->
    <ul class="basket-list">
      <li v-for="item in basketTile.basketItems" :key="item.id" class="basket-item">
        <!-- Product Image -->
        <img
          :src="item.prodImgSrc"
          :alt="item.prodName"
          class="product-image"
          loading="lazy"
        />

        <div class="d-flex flex-column w-100">
          <!-- Product Details -->
          <div class="product-info">
            <span class="product-text">{{ item.prodText }}</span>
          </div>
          <!-- todo : Append Product Details if any here (example Burger Menu: Selected Desert and Drink) -->

          <div class="d-flex flex-row justify-content-end">
            <!-- todo translate -->
            <span class="price px-1" aria-label="Quantity">{{ item.formattedPrice }}</span>

            <!-- Product Quantity -->
            <!-- todo translate -->
            <span class="product-quantity" aria-label="Quantity">x{{ item.quantity }}</span>

            <!-- Product Price -->
            <!-- todo translate -->
            <span class="product-price" aria-label="Price">{{ item.formattedTotalPrice }}</span>
          </div>
        </div>

      </li>
    </ul>

    <!-- Basket Total -->
    <h3 class="basket-total normalText mb-0">
      <span>{{ $t('text.sum') }}:</span>
      <span class="total-price">{{ basketTile.formattedTotal }}</span>
    </h3>

    <Button @click="handlePayment">{{ $t('button.confirm') }}</Button>
  </section>
</template>

<script>
import { orderService } from "@/services/api/orderService";
import { OrderTypes } from "@/constants/constants";
import { useMealPlanStore } from "@/stores/mealPlan";
import Button from "@/components/barrier-free-template-components/Button.vue";
import {useShopStore} from "@/stores/shop";
import {useAppStore} from "@/stores/app";

export default {
  components: { Button },
  inject: ["setPageMetaTitle"],
  data() {
    return {
      /** @type {BasketTile} */
      basketTile: {},
      isLoading: false,
    };
  },
  setup() {
    const appStore = useAppStore();
    const mealPlanStore = useMealPlanStore();
    const shopStore = useShopStore();

    return { appStore, mealPlanStore, shopStore };
  },
  async created() {
    await this.setPageMetaTitle(this.shopStore.name);
    await this.fetchBasketData();
  },
  methods: {
    async fetchBasketData() {
      try {
        let mealPlanId, reservationDateTime;
        const order = this.mealPlanStore.getOrder(this.appStore.currentMealPlanOrderId);
        if (order) {
          mealPlanId = order.mealPlanId;
          reservationDateTime = order.reservationDateTime;
        }

        this.isLoading = true;
        /** @type {AfterLoginResponse} */
        const result = await orderService.calculateOrder(OrderTypes.DINE_IN, {
          promotionBarcode: this.promoBarcode,
          comment: this.comment,
          mealPlanId: mealPlanId ? mealPlanId : undefined,
          reservationDateTime: reservationDateTime ? reservationDateTime : undefined
        });
        this.isLoading = false;

        if (result.exception) {
          this.makeToast("danger", this.$t("exceptionMessage"));
          return;
        }

        if (result.errorCode > 0) {
          this.makeToast("danger", result.errorMsg);
          return;
        }

        if (!result.basketTile.basketItems || result.basketTile.basketItems.length === 0) {
          this.makeToast("danger", this.$t("notification.noProducts"));
        } else {
          this.basketTile = result.basketTile;
        }
      } catch (error) {
        this.makeToast("danger", this.$t("error.general"));
      }
    },
    handlePayment() {
      this.$navigateToMealPlanPayment();
    },
  },
};
</script>

<style scoped>
.meal-plan-confirmation {
  padding: 16px;
  background: #fff;
  border-radius: 8px; /* any reason to be round? */
  /*box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);*/
}

.basket-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.basket-item {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #ddd;
  justify-content: space-between;
  gap: 12px;
}

.product-image {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
}

.product-info {
  flex-grow: 1;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.product-quantity {
  font-size: 16px;
  color: #222;
  min-width: 40px;
  text-align: center;
}

.product-price {
  font-size: 16px;
  font-weight: bold;
  color: #222;
  min-width: 80px;
  text-align: right;
}

.basket-total {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: bold;
  padding: 12px;
}

.total-price {
  color: #0057b8;
}
</style>
