import { ref } from 'vue'

const activeTimerBase = ref(0); // this property is global; its value should not be lost on navigation and between components. (but will be lost on page reload)
const appIdle = ref(false); // this property is needed, since Date.now() is not a reactive component dependency

export function SessionTimer(){
  const activeTimer = ref(0);
  const sessionUpdater = ref(0);

  function updateState(){
      if (activeTimerBase.value === 0) {
          return;
      }
      activeTimer.value = activeTimerBase.value - Date.now(); // do calculation here
      appIdle.value = (activeTimer.value <= 0); // trigger all isAppIdle() listeners
  }

  function onMount(){
    updateState();
    sessionUpdater.value = setInterval(updateState, 4000);// updates reactive value every 5 seconds => updates the DOM Element also every 5 seconds
  }

  function onUnmount(){
      clearInterval(sessionUpdater.value);
  }

  return { activeTimer,onMount,onUnmount };
}

export function isAppIdle() {
  if (activeTimerBase.value === 0)
    return false;
  return appIdle.value;
}

export function refreshTimerBase(expiryTimeInMilliseconds) {
    activeTimerBase.value = expiryTimeInMilliseconds;
}

export function removeTimerBase() {
    activeTimerBase.value = 0;
    appIdle.value = false;
}

export function extractSessionExpiry(sessionExpiry) {
  if (sessionExpiry) {
      refreshTimerBase(Number(sessionExpiry));
  } else {
      console.warn("X-Session-Expiry header not found.");
  }
}
