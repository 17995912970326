import { render, staticRenderFns } from "./MealPlan.vue?vue&type=template&id=5062f595&scoped=true"
import script from "./MealPlan.vue?vue&type=script&lang=js"
export * from "./MealPlan.vue?vue&type=script&lang=js"
import style0 from "./MealPlan.vue?vue&type=style&index=0&id=5062f595&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5062f595",
  null
  
)

export default component.exports