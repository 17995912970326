export default {
  install(Vue) {
    function navigate(router, routeName, params = {}, query = {}, withReplace = false) {
      const method = withReplace ? 'replace' : 'push';
      router[method]({name: routeName, params, query}).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          console.info(err.message);
        }
      });
    }

    // General navigation method
    Vue.prototype.$navigate = function(routeName, options = {}) {
      const { params = {}, query = {}, withReplace = false } = options;
      navigate(this.$router, routeName, params, query, withReplace);
    };

    // Specific routes
    Vue.prototype.$navigateToDefaultStartPage = function(pageName = 'Dashboard') {
      this.$navigate(pageName, { withReplace: true });
    };

    Vue.prototype.$navigateToDashboard = function(withReplace = false) {
      this.$navigate('Dashboard', { withReplace });
    };

    Vue.prototype.$navigateToRegister = function() {
      this.$navigate('Register');
    };

    Vue.prototype.$navigateToRegisterAsChild = function() {
      this.$navigate('RegisterAsChild');
    };

    Vue.prototype.$navigateToRegisterAsParent = function() {
      this.$navigate('RegisterAsParent');
    };

    Vue.prototype.$navigateToGuest = function() {
      this.$navigate('Guest', { withReplace: true });
    };

    Vue.prototype.$navigateToForgotPassword = function() {
      this.$navigate('ForgotPassword');
    };

    Vue.prototype.$navigateToOrderShops = function(withReplace = false) {
      this.$navigate('OrderShops', { withReplace });
    };

    Vue.prototype.$navigateToMealPlanShops = function(withReplace = false) {
      this.$navigate('MealPlanShops', { withReplace });
    };

    Vue.prototype.$navigateToShop = function(withReplace = false) {
      this.$navigate('Shop', { withReplace });
    };

    Vue.prototype.$navigateToMenu = function(withReplace = false) {
      this.$navigate('Menu', { withReplace });
    };

    Vue.prototype.$navigateToAccount = function(withReplace = false) {
      this.$navigate('Account', { withReplace });
    };

    Vue.prototype.$navigateToTablePay = function() {
      this.$navigate('TablePay');
    };

    Vue.prototype.$navigateToTemplate = function() {
      this.$navigate('Template');
    };

    Vue.prototype.$navigateToCustomerCard = function(withReplace = false) {
      this.$navigate('CustomerCard', { withReplace });
    };

    Vue.prototype.$navigateToPasswordReset = function() {
      this.$navigate('PasswordReset');
    };

    Vue.prototype.$navigateToAddress = function() {
      this.$navigate('Address');
    };

    Vue.prototype.$navigateToEditAddress = function(addressId) {
      const params = addressId ? { addressId } : {};
      this.$navigate('EditAddress', { params });
    };

    Vue.prototype.$navigateToEditCustomer = function() {
      this.$navigate('EditCustomer');
    };

    Vue.prototype.$navigateToTopUp = function(customerId = 0) {
      const params = customerId ? { customerId } : {};
      this.$navigate('TopUp', { params });
    };

    Vue.prototype.$navigateToAutoTopUp = function(customerId) {
      const params = customerId ? { customerId } : {};
      this.$navigate('TopUp', { params });
    };

    Vue.prototype.$navigateToOrderHistory = function() {
      this.$navigate('OrderHistory');
    };

    Vue.prototype.$navigateToTransactionHistory = function(isChild = false, customerId = 0) {
        if (isChild && customerId > 0) {
            this.$router.push({ name: 'TransactionHistory', params: { customerId: customerId }, query: { isChild: isChild } });
        } else {
            this.$router.push({ name: 'TransactionHistory' });
        }
    };

    Vue.prototype.$navigateToMealPlanner = function(withReplace = false) {
      this.$navigate('MealPlanner', { withReplace });
    };

    Vue.prototype.$navigateToStudentAccounts = function(withReplace = false) {
      this.$navigate('StudentAccounts', { withReplace });
    };

    Vue.prototype.$navigateToOrderConfirmation = function() {
      this.$navigate('OrderConfirmation');
    };

    Vue.prototype.$navigateToMealPlanConfirmation = function() {
      this.$navigate('MealPlanConfirmation');
    };

    Vue.prototype.$navigateToMealPlanPayment = function() {
      this.$navigate('MealPlanPayment');
    };

    Vue.prototype.$navigateToOrderPayment = function() {
      this.$navigate('OrderPayment');
    };

    Vue.prototype.$navigateToErrorSSO = function() {
      this.$navigate('ErrorSSO', { withReplace: true });
    };

    Vue.prototype.$navigateToErrorLanding = function(sessionTimeout = false) {
      const params = sessionTimeout ? { sessionTimeout } : {};
      this.$navigate('ErrorLanding', { params, withReplace: true });
    };

    Vue.prototype.$navigateToCheckoutFinished = function() {
      this.$navigate('CheckoutFinished', { withReplace: true });
    };

    Vue.prototype.$navigateToLogin = function() {
      this.$navigate('Login', { withReplace: true });
    };

    Vue.prototype.$navigateToLanding = function() {
      this.$navigate('Landing', { withReplace: true });
    };
  },
};
