<template>
  <div class="qrLandingPageWrapper">
    <!-- Or set an image as the background -->
    <img id="myImage" :src="backgroundImagePath">

    <div class="qrLandingPageBody" style="padding-top:15%">
      <div class="col-12 fontTitle fontLandingTitle">
        {{ $t('title.landingGenericError') }}
      </div>
      <div class="col-12 fontContent fontLandingContent">
        {{ landingErrorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import {useAppStore} from "@/stores/app";
import {Mode} from "@/constants/constants";

export default {
  setup() {
    const appStore = useAppStore();

    return {
      appStore,
    };
  },
  props: {
    sessionTimeout: {
      type: Boolean,
    }
  },
  computed: {
    backgroundImagePath() {
      return process.env.BASE_URL + 'settings/resources/ErrorLandingBackground.jpg';
    },
    landingErrorMessage() {
      if (this.sessionTimeout) {
        if (this.appStore.isMode(Mode.TABLE)) {
          return this.$t('notification.landingSessionError');
        }
      }
      return this.$t('notification.landingGenericError');
    },
  },
};
</script>

<style>
#myImage {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  max-width: 100%;
}

.fontLandingTitle {
  font-family: var(--fonts-promo-font-family);
  text-align: center;
  font-size: 35px;
  color: var(--landing-text-color);
  margin-top: 3vh;
}

.fontLandingContent {
  color: var(--landing-text-color);
  margin-top: 3vh;
}

.qrLandingPageBody {
  z-index: 1000001;
}
</style>
