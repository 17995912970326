import { defineStore } from 'pinia';

export const useOrderStore = defineStore('order', {
  state: () => ({
    orders: [],
  }),

  actions: {

    addOrder(shopId, orderType, pickupTime) {
      // Check if an existing order matches the given criteria
      let order = this.orders.find(order =>
        order.shopId === shopId &&
        order.orderType === orderType &&
        order.pickupTime === pickupTime
      );

      // If order exists, return it (prevents duplicate orders)
      if (order) {
        return order;
      }

      // Otherwise, create a new order
      const newOrderId = Date.now().toString(); // Unique orderId

      order = {
        orderId: newOrderId,
        shopId,
        orderType,
        pickupTime,
        products: [],
        totalPrice: 0.00,
      };

      this.orders.push(order);
      return order;
    },

    addProductToOrder(orderId, productId, price, quantity) {
      const order = this.getOrder(orderId);
      if (!order) {
        console.warn(`Order with ID ${orderId} not found.`);
        return;
      }

      // Find product in the order
      const existingProduct = order.products.find(product => product.id === productId);
      if (existingProduct) {
        existingProduct.quantity += quantity;
        existingProduct.price = price;
      } else {
        order.products.push({ id: productId, price, quantity });
      }

      // Update total price
      order.totalPrice = order.products.reduce((sum, p) => sum + p.price * p.quantity, 0);
    },

    removeProductFromOrder(orderId, productId, quantityToRemove) {
      const order = this.getOrder(orderId);
      if (!order) {
        console.warn(`Order with ID ${orderId} not found.`);
        return;
      }

      // Find the product in the order
      const productIndex = order.products.findIndex(product => product.id === productId);
      if (productIndex === -1) {
        console.warn(`Product with ID ${productId} not found in order.`);
        return;
      }

      const product = order.products[productIndex];
      if (product.quantity > quantityToRemove) {
        product.quantity -= quantityToRemove;
      } else {
        order.products.splice(productIndex, 1);
      }

      // Recalculate total price
      order.totalPrice = order.products.reduce((sum, p) => sum + p.price * p.quantity, 0);

      // If order is empty, remove it
      if (order.products.length === 0) {
        this.orders = this.orders.filter(o => o.orderId !== orderId);
      }
    },

    updateProductFromOrder(orderId, productId, newQuantity) {
      const order = this.getOrder(orderId);
      if (!order) {
        console.warn(`Order with ID ${orderId} not found.`);
        return;
      }

      const productIndex = order.products.findIndex(product => product.id === productId);
      if (productIndex === -1) {
        console.warn(`Product with ID ${productId} not found in order.`);
        return;
      }

      if (newQuantity > 0) {
        order.products[productIndex].quantity = newQuantity;
      } else {
        order.products.splice(productIndex, 1);
      }

      // Recalculate total price
      order.totalPrice = order.products.reduce((sum, p) => sum + p.price * p.quantity, 0);

      // Remove order if empty
      if (order.products.length === 0) {
        this.orders = this.orders.filter(o => o.orderId !== orderId);
      }
    },

    setPickupNumberForOrder(orderId, pickupNumber) {
      const order = this.getOrder(orderId);
      if (!order) {
        console.warn(`Order with ID ${orderId} not found.`);
        return;
      }
      order.pickupNumber = pickupNumber;
    },

    remove(orderId) {
      this.orders = this.orders.filter(o => o.orderId !== orderId);
    },

    reset() {
      this.orders = [];
    },
  },

  getters: {
    getOrders: state => state.orders,
    getOrder: state => orderId => state.orders.find(o => o.orderId === orderId),
    getOrderTypeById: (state) => (orderId) => {
      const order = state.orders.find(o => o.orderId === orderId);
      return order ? order.orderType : null;
    },
  },

  persist: {
    enabled: true,
    strategies: [
      {
        key: 'order',
        storage: localStorage,
      },
    ],
  },
});
