import { apiUtils } from "@/utils/apiUtils";

export const customerService = {
  /** @returns {Promise<{wondCustomer:WondCustomer}>} */
  async editCustomerDietaryAttributes(attributes) {
      try {
          const result = await apiUtils.doPost('/TCPEditCustomerDietaryAttributesSubmitJ.action', {
              json: "true",
              attributes: attributes
          });

          return result;
      } catch (error) {
          console.error("Error editing customer dietary attributes:", error);
          throw error;
      }
  },
  async changeCustomerLocale(locale) {
      try {
          const result = await apiUtils.doPost('/TCPSetLocaleJ.action', {
              json: "true",
              appLocale: locale,
          });

          return result;
      } catch (error) {
          console.error("Error changing customer locale:", error);
          throw error;
      }
  },
  async getCustomer() {
    try {
      const result = await apiUtils.doGet('/TCPEditCustomerJ.action', {
        json: "true",
      });
      return result;
    } catch (error) {
      console.error("Error on getting customer:", error);
      throw error;
    }
  },
  async configureRecurringPayment(active, thresholdValue, rechargeValue) {
    try {
      const result = await apiUtils.doPost('/TCPEditCustomerSubmitJ.action', {
        json: "true",
        recurringPayment: {
          active: active,
          thresholdValue: thresholdValue,
          rechargeValue: rechargeValue,
        }
      });
      return result;
    } catch (error) {
      console.error("Error on setting recurring payment configuration:", error);
      throw error;
    }
  },
  async editCustomer(firstName, lastName, telephoneNumber, streetAddress, postcode, state, city, countryId, notes1, notes2, notes3, notes4, otherInfos) {
    try {
      const result = await apiUtils.doPost('/TCPEditCustomerSubmitJ.action', {
        json: "true",
        firstName: firstName,
        lastName: lastName,
        telephoneNumber: telephoneNumber,
        streetAddress: streetAddress,
        postcode: postcode,
        state: state,
        city: city,
        countryId: countryId,
        countryChange: "0",
        notes1: notes1,
        notes2: notes2,
        notes3: notes3,
        notes4: notes4,
        otherInfos: otherInfos,
        privacy: 1,
        tc: 1,
      });

      return result;
    } catch (error) {
      console.error("Error on getting customer:", error);
      throw error;
    }
  },
};
