import {apiUtils} from "@/utils/apiUtils";

export const transactionService = {
    async getTransactions(dateFrom, dateTo) {
      try {
        const result = await apiUtils.doGet('/TCPGetTransactionsJ.action', {
          json: "true",
          showDetails: "true",
          dateFromStr: dateFrom,
          dateToStr: dateTo
        });

        return result;
      } catch (error) {
        console.error("Error on getting transactions:", error);
        throw error; // Re-throw the error to handle it in the component
      }
    },
};
