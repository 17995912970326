<template>
    <div>
      <loading :active.sync="isLoading"
               :can-cancel="true"
               :is-full-page="true"
               loader='bars'></loading>

        <div class="d-flex flex-column flex-wrap gap10">

          <b-button type="button" v-if="address && !address.setAsPrimaryBool" @click="deleteAddress" class="btn btn-danger floatRight noMargin w-100">
            <font-awesome-icon :icon="['fas', 'trash-can']" />
            {{ $t('button.deleteAddress') }}
          </b-button>

          <address-form :address="address" :callbackClose="close" class="row noPadding" autofocus></address-form>

        </div>
    </div>
</template>
<script>
import {addressService} from "../../services/api/addressService";
import AddressForm from "../main-components/AddressForm.vue";
import loading from "vue-loading-overlay";

export default {
  data(){
    return {
      isLoading: false,
      address: null,
    }
  },
  props: {
    addressId: {
      type: [String, Number],
    },
  },
  components: {
    loading,
    'address-form': AddressForm,
  },
  methods:{
      close(){
        this.$navigateToAccount(true);
      },
      deleteAddress(){
        addressService.deleteById(this.addressId);
        this.makeToast('success', this.$t('notification.deleteSuccessful'));
      },
  },
  async mounted() {
    if (this.addressId) {
      this.isLoading = true;
      const result = await addressService.getById(this.addressId);
      this.address = result;
      this.isLoading = false;
    }
  }
}
</script>
<style scoped>

</style>
