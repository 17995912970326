<template>
  <div>
    <b-spinner v-if="loading" label="Authenticating..."></b-spinner>
  </div>
</template>

<script>
import Utils from "@/utils/utils";
import {UserTypes, Workflow} from "@/constants/constants";
import {useLocaleStore} from "@/stores/locale";
import {useAuthStore} from "@/stores/auth";
import {useAppStore} from "@/stores/app";
import {orderService} from "@/services/api/orderService";
import {useOrderStore} from "@/stores/order";
import {useCustomerStore} from "@/stores/customer";
import {accountService} from "@/services/api/accountService";

export default {
  data() {
    return {
      loading: true,
    };
  },
  props: {
    shopId: {
      Type: String
    },
    tableId: {
      Type: String
    },
    jwt: {
      Type:String,
      Default: "",
    },
    code: {
      Type:String,
      Default: "",
    },
    validationType: {
      Type:String,
      Default: "",
    },
    orderImmediate: {
      Type: Boolean
    },
    isMealPlan: {
      Type: Boolean
    },
  },
  setup() {
    const localeStore = useLocaleStore();
    const authStore = useAuthStore();
    const appStore = useAppStore();
    const orderStore = useOrderStore();
    const customerStore = useCustomerStore();

    return {
      localeStore,
      authStore,
      appStore,
      orderStore,
      customerStore
    };
  },
  methods: {
    async changeLanguage(newLocale) {
      await this.localeStore.setLocale(newLocale);
    },
    doNavigationByAppMode(userType) {
      let workflow;
      if (this.orderImmediate) {
        workflow = Workflow.ORDER.WITHOUT_SHOP_SELECTION;

        if (this.isMealPlan) {
          this.$navigateToMealPlanner(true);
        } else {
          this.$navigateToMenu(true);
        }
      } else if (this.tableId) {
        workflow = Workflow.ORDER.AT_TABLE;
        this.selectTableByQRCode();
        this.$navigateToMenu(true);
      } else if (this.shopId) {
        workflow = Workflow.ORDER.WITH_SHOP_SELECTION;

        if (this.isMealPlan) {
          this.$navigateToMealPlanner(true);
        } else {
          this.$navigateToMenu(true);
        }
      } else if (UserTypes.PARENT === userType) {
        workflow = Workflow.SCHOOL;
        this.$navigateToStudentAccounts(true);
      } else {
        workflow = Workflow.DEFAULT;
        if (window.config.DEFAULT_START_PAGE.enabled) {
          this.$navigateToDefaultStartPage(window.config.DEFAULT_START_PAGE.name);
        } else {
          this.$navigateToDashboard(true);
        }
      }
      this.appStore.setWorkflow(workflow);
    }, async handleSSO() {
      try {
        const result = await accountService.loginWithJWT(this.jwt, this.code, this.shopId, this.validationType);
        if (result.exception !== undefined || result.errorCode < 0) {
          this.$navigateToErrorSSO();
          return;
        }

        if (window.config.USE_ALLERGEN_FILTERS && result.wondCustomer.dietaryInfo !== undefined) {
          this.customerStore.setDietaryInfo(result.wondCustomer.dietaryInfo);
        }

        this.authStore.setAuthenticated(true);

        if (window.config.USE_EXTERNAL_LANGUAGE) {
          const newLocale = !Utils.isEmpty(result.customer.locale) ? result.customer.locale : this.config.LANGUAGE_LIST[0].value;
          await this.changeLanguage(newLocale.replace('-', '_'));
        }

        this.doNavigationByAppMode(result.customer.custom3);
      } catch (error) {
        this.$navigateToErrorSSO();
      }
    },
    async selectTableByQRCode() {
      const result = await orderService.selectTableByQRCode(this.shopId, this.tableId)
      if (result.exception !== undefined) {
        this.makeToast('danger', this.$t('notification.failedGeneric'));
      }

      this.orderStore.setTableId(this.tableId);
    },
  },
  created() {
    this.handleSSO();
  },
};
</script>
