<template>
  <div class="editCustomerPageWrapper" style="padding-left: 15px; padding-right: 15px;">
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>

    <div class="">
      <date-search-table :useFutureFilters="true" :callback="retrieveOrders" style="padding-left: 0; padding-right: 0;"  />
      <b-container fluid>
        <b-row class="d-none d-md-flex">
          <h3 class="normalText sr-only">Christian - Bitte Text Erstellen : Tranaktions Tabelle</h3>
          <b-table-simple small borderless responsive striped hover class="roundedTable"
                          :tbody-tr-attr="rowIdentifier">
            <b-thead head-variant="dark">
              <b-tr>
                <b-th class="text-left">{{ $t('text.transDate') }}</b-th>
                <b-th class="text-left">{{ $t('table.shop') }}</b-th>
                <b-th class="text-left">{{ $t('table.status') }}</b-th>
                <b-th class="text-right">{{ $t('table.amount') }}</b-th>
                <b-th class="text-left"></b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="!Utils.isEmpty(recentOrders)" class="va-middle">
              <b-tr v-for="item in recentOrders" v-bind:key="item.guid" @click="openOrderDetails(item.guid, item.shopId)" v-on:keydown.enter="openOrderDetails(item.guid, item.shopId)">
                <b-td class="text-left">{{ item.formattedDate }}</b-td>
                <b-td class="text-left">{{ item.shopDescription }}</b-td>
                <b-td class="text-left">{{ item.status }}</b-td>
                <b-td class="text-right">{{ item.formattedTotal }}</b-td>
                <b-td class="d-flex align-items-center justify-content-center">
                  <b-button class="btn btn-block btn-sm" v-b-tooltip :title="$t('button.details')" @click="openOrderDetails(item.guid, item.shopId)" :aria-label="item.formattedDate">
                    <font-awesome-icon class="addIconMenu" :icon="['fas', 'circle-info']" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td class="text-left" colspan="5">{{ $t('table.noData') }}</b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot v-if="!Utils.isEmpty(recentOrders)" foot-variant="dark">
              <b-tr>
                <b-td class="text-left" variant="secondary" colspan="3"><b>{{ $t('table.sum') }}</b></b-td>
                <b-td class="text-right" variant="secondary"><b>{{ ordersFormattedTotal }}</b></b-td>
                <b-td variant="secondary"/>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </b-row>
        <b-row class="d-md-none">
          <h3 class="normalText sr-only">Christian - Bitte Text Erstellen : Tranaktions Tabelle</h3>
          <b-table-simple small borderless responsive striped hover class="roundedTable"
                          :tbody-tr-attr="rowIdentifier">
            <b-thead head-variant="dark">
              <b-tr>
                <b-th class="text-left">{{ $t('table.date') }}</b-th>
                <b-th class="text-left">{{ $t('table.shop') }}</b-th>
                <b-th class="text-left">{{ $t('table.status') }}</b-th>
                <b-th class="text-right">{{ $t('table.amount') }}</b-th>
                <b-th class="text-left"></b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="!Utils.isEmpty(recentOrders)" class="va-middle">
              <b-tr v-for="item in recentOrders" v-bind:key="item.guid" @click="openOrderDetails(item.guid, item.shopId)" v-on:keydown.enter="openOrderDetails(item.guid, item.shopId)">
                <b-td class="text-left">{{ item.formattedDate }}</b-td>
                <b-td class="text-left">{{ item.status }}</b-td>
                <b-td class="text-right">{{ item.formattedTotal }}</b-td>
                <b-td class="d-flex align-items-center justify-content-center">
                  <b-button class="btn btn-block btn-sm" v-b-tooltip :title="$t('button.details')" @click="openOrderDetails(item.guid, item.shopId)" :aria-label="item.formattedDate">
                    <font-awesome-icon class="addIconMenu" :icon="['fas', 'circle-info']" />
                  </b-button>
                </b-td>
                <b-td/>
              </b-tr>
            </b-tbody>
            <b-tbody v-else>
              <b-tr>
                <b-td class="text-left" colspan="5">{{ $t('table.noData') }}</b-td>
              </b-tr>
            </b-tbody>
            <b-tfoot v-if="!Utils.isEmpty(recentOrders)" foot-variant="dark">
              <b-tr>
                <b-td class="text-left" variant="secondary"><b>{{ $t('table.sum') }}</b></b-td>
                <b-td class="text-right" variant="secondary" colspan="2"><b>{{ ordersFormattedTotal }}</b></b-td>
                <b-td variant="secondary" colspan="3"></b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </b-row>
      </b-container>
    </div>

    <modal ref="orderDetailsModal">
      <template v-slot:header>
        <h2 id="modalTitle">{{ $t('title.order') + " " + currentOrderSelected.formattedDate}}</h2>
      </template>

      <template v-slot:body>
        <order-details :order="currentOrderSelected" hide-caption></order-details>
        <div class="align-items-center justify-content-between">
          <b-button class="btn btn-secondary mr-2" @click="$refs.orderDetailsModal.closeModal()">{{ $t("button.cancel") }}</b-button>
        </div>
      </template>
    </modal>
    <!--<modal ref="orderDetailsModal">
      <template v-slot:header>
        <h2 id="modalTitle">{{ $t('title.order') }}</h2>
      </template>

      <template v-slot:body>
        <order-details :order="currentOrderSelected" :callback-close="closeOrderDetails"></order-details>
        <b-button v-if="config.SHOW_ACCOUNT_STORNO" class="btn btn-secondary mr-2" @click="$refs.orderDetailsModal.closeModal()">{{ $t("button.cancel") }}</b-button>
        <b-button v-if="config.SHOW_ACCOUNT_STORNO" class="btn btn-danger" @click="cancelOrder()">{{ $t("button.cancel") }}
        </b-button>
      </template>
    </modal>-->
  </div>
</template>

<script>

import OrderDetails from '../sub-components/OrderDetails.vue'
import Modal from "../sub-components/ModalAccessible.vue";
import Utils from "@/utils/utils";
import DateSearchTable from "@/components/main-components/DateSearchTable.vue";
import Swal from "sweetalert2";
import VueLoading from "vue-loading-overlay";
import {orderService} from "../../services/api/orderService";

export default {
  data() {
    return {
      isLoading: false,
      /** @type {[{barcode:string,date:string,formattedDate:string,formattedTotal:string,guid:string,mealplanId:number,shopCode:string,shopDescription:string,shopId:number,status:string,statusCode:string,success:boolean,totalAmount:number,transComment:string}]} */
      recentOrders: [],
      /** @type {{barcode:string,date:string,formattedDate:string,formattedTotal:string,guid:string,mealplanId:number,shopCode:string,shopDescription:string,shopId:number,status:string,statusCode:string,success:boolean,totalAmount:number,transComment:string}} */
      currentOrderSelected: {},
      ordersFormattedTotal: 0.00,
    }
  },
  computed: {
    Utils() {
      return Utils
    },
    config() {
      return window.config;
    }
  },
  components: {
    'loading': VueLoading,
    DateSearchTable,
    'modal': Modal,
    'order-details': OrderDetails,
  },
  methods: {
    /** @param {{orders:[{barcode:string,date:string,formattedDate:string,formattedTotal:string,guid:string,mealplanId:number,shopCode:string,shopDescription:string,shopId:number,status:string,statusCode:string,success:boolean,totalAmount:number,transComment:string}],order?:{barcode:string,date:string,formattedDate:string,formattedTotal:string,guid:string,mealplanId:number,shopCode:string,shopDescription:string,shopId:number,status:string,statusCode:string,success:boolean,totalAmount:number,transComment:string}}} order */
    applyDateFormat(order){
      // modify default date:
      const formatter = new Intl.DateTimeFormat(undefined ,{localeMatcher:"best fit",dateStyle: 'medium',timeStyle: 'short'});

      let list = order.orders;
      if ( !this.Utils.isArray(list) ) {
        list = [order.order];
      }
      for (const item of list) {
        item.formattedDate = formatter.format(new Date(item.date));
      }
    },
    async retrieveOrders(dateFrom, dateTo) {
      this.isLoading = true;
      const result = await orderService.getOrders(dateFrom, dateTo);
      this.isLoading = false;

      if (result.errorCode < 0) {
        this.makeToast('danger', result.errorMsg);
        return;
      }
      this.applyDateFormat(result);

      this.recentOrders = result.orders;
      this.ordersFormattedTotal = result.ordersFormattedTotal;
    },
    async openOrderDetails(guid, shopId) {
      this.isLoading = true;
      const result = await orderService.getOrderDetails(guid, shopId);
      this.isLoading = false;

      this.applyDateFormat(result);

      this.currentOrderSelected = result.order;
      this.currentOrderSelected.guid = result.guid;
      this.$refs.orderDetailsModal.openModal();
    },
    closeOrderDetails() {
      this.$refs.orderDetailsModal.closeModal();
      this.currentOrderSelected = null;
    },
    rowIdentifier(item, type) {
      if (type != 'row') {
        return {};
      }
      return {
        'title': this.$t('text.transactionFrom') + ' ' + item.formattedDate
      }
    }
  },
  async created() {
    await this.retrieveOrders(Utils.getStartOfMonth(), Utils.getEndOfMonth());
  },
  beforeRouteLeave (to,from,next) {

    Swal.close();
    next();
  }
}
</script>

<style scoped>

.fas {
  margin-right: 5px;
}

.va-middle td{
  vertical-align: middle;
}

</style>
