import {apiUtils} from "@/utils/apiUtils";

export const paymentService = {
  async getPaymentMethods() {
    try {
      return await apiUtils.doGet('TCPPaymentJ.action', { json: "true" });
    } catch (error) {
      console.error("Error on payment submit:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  },
  async pay(paymentMethod) {
    try {
      const result = await apiUtils.doPost('/TCPPaymentSubmitJ.action', {
        json: "true",
        deliveryAddress: "0",
        paymentMethod: paymentMethod,
      });

      return result;
    } catch (error) {
      console.error("Error on payment submit:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  },
  async configurePrepayment(amount) {
    try {
      const result = await apiUtils.doPost('/TCPConfigurePrepaymentJ.action', {
        json: "true",
        amount: amount.toString(),
      });
      return result;
    } catch (error) {
      console.error("Error on configure pre-payment:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  },
  async initPayment(paymentProvider, options = {}) {
    const endpoints = {
      adyen: '/AdyenJ.action',
      novalnet: '/NovalnetJ.action',
      saferpay: '/SaferpayJ.action',
      securepay: '/SecurepayJ.action',
      stripe: '/StripeJ.action',
    };

    if (!endpoints[paymentProvider]) {
      throw new Error(`Unsupported payment provider: ${paymentProvider}`);
    }

    try {
      const params = { json: "true", ...options };
      return await apiUtils.doGet(endpoints[paymentProvider], params);
    } catch (error) {
      console.error(`Error initializing ${paymentProvider} payment:`, error);
      throw error;
    }
  },
  async processPayment(paymentProvider, options = {}) {
    const endpoints = {
      saferpay: '/ProcessSaferpayJ.action',
      payone: '/ProcessPayOneJ.action',
    };

    if (!endpoints[paymentProvider]) {
      throw new Error(`Unsupported payment provider: ${paymentProvider}`);
    }

    try {
      const params = { json: "true", ...options };
      return await apiUtils.doPost(endpoints[paymentProvider], params);
    } catch (error) {
      console.error(`Error process ${paymentProvider} payment:`, error);
      throw error;
    }
  },
  async deleteStoredCard(paymentProvider, options = {}) {
    const endpoints = {
      saferpay: '/SaferpayDeleteCardSubmitJ.action',
    };

    if (!endpoints[paymentProvider]) {
      throw new Error(`Unsupported payment provider: ${paymentProvider}`);
    }

    try {
      const params = { json: "true", ...options };
      return await apiUtils.doPost(endpoints[paymentProvider], params);
    } catch (error) {
      console.error(`Error deleting card for ${paymentProvider} payment:`, error);
      throw error;
    }
  },
  async payAtTable(guid, amount, lockTable) {
    try {
      const result = await apiUtils.doPost('/TCPPayAtTableJ.action', {
        json: "true",
        guid: guid,
        amount: amount.toString(),
        lockTable: lockTable,
      });
      return result;
    } catch (error) {
      console.error("Error on paying at table:", error);
      throw error; // Re-throw the error to handle it in the component
    }
  },
};
