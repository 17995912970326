<template>
  <div id="mealPlans">
    <VueLoading v-if="isLoading" />
    <div class="d-flex flex-row rowGap20 accountPageFlexer flex-wrap">
      <FlexContainer :title="$t('subTitle.myAccount')" #content>
        <div class="d-flex flex-column gap10 align-items-start">
          <div class="text-left my-1">
            <span>{{ customer.firstName }} {{ customer.lastName }}</span><br/>
            <span>{{ customer.emailAddr }}</span><br/>
            <span>{{ customer.telephonNumber }}</span>
          </div>

          <!-- Edit Account Data -->
          <b-button v-if="config.CUSTOMER_DATA.editable" class="w-100 noMargin text-left" variant="primary" @click="navigateToEditCustomer">
            {{ $t('button.editCustomerData')}}
          </b-button>

          <b-button v-if="config.CHANGE_PASSWORD.enabled" class="w-100 noMargin text-left" variant="primary" @click="navigateToPasswordReset">
            {{ $t('button.changePassword')}}
          </b-button>
        </div>
      </FlexContainer>
      <FlexContainer :title="$t('subTitle.deliveryAddress')" #content :visible="config.SHOW_ACCOUNT_PAGE_ADRESSES">
        <div class="d-flex flex-column gap10 align-items-start">

          <select class="vueselect-wrapper noPadding" id="addressPicker" ref="addressPickerValue" aria-controls="btnEditAddress">
            <option v-for="address in addresses" :key="address.id" :value="address.id" v-html="address.firstName + ' ' + address.lastName + ', \u000a' + address.streetAddress + ', \u000a' + address.postcode + ' ' +  address.city + ', ' + address.countryName + ' - \u000a' + (address.telephoneNumber ? address.telephoneNumber: '') "></option>
          </select>

          <button class="btn btn-primary text-left mx-0 w-100 noMargin" @click="editAddress" id="btnEditAddress" aria-describedby="addressPicker">
            {{$t('subTitle.editAddress')}}
          </button>

          <button class="btn btn-primary text-left mx-0 w-100 noMargin" @click="addNewAddress">
            {{$t('subTitle.addAddress')}}
          </button>
        </div>
      </FlexContainer>

      <FlexContainer :title="$t('subTitle.configuration')" #content :visible="config.USE_ALLERGEN_FILTERS">
        <div class="d-flex flex-column gap10 align-items-start">
          <!-- todo after storing the Alergens Data; show the Icons here -->
          <!--<div class="d-flex flex-row flex-wrap gap10">-->
          <!--  <img v-for="alergene of diataryInfo" :key="alergene" src="{}" alt="">-->
          <!--</div>-->
          {{$t('text.allergensAndPropertiesSelected',[diataryInfoCount])}}

          <b-button v-if="config.USE_ALLERGEN_FILTERS" class="w-100 noMargin text-left" variant="primary" @click="viewAllergenDietaryFilter" aria-label="Edit customer allergen and dietary infos">
            <!--<font-awesome-icon :icon="['fas', 'pen-to-square']" />-->
            {{ $t('button.changeAllergens') }}
          </b-button>
        </div>
      </FlexContainer>
    </div>

    <!-- Modal for allergen and dietary filters -->
    <b-modal id="allergen-dietary-filter-modal" hide-footer :title="$t('title.dietaryInfo')" title-tag="h3" title-class="normalText">
      <AllergenDietaryFilter @edit-customer-dietary-attributes="handleEditCustomerDietaryAttributes" :selected-allergens="selectedAllergens" :selected-properties="selectedProperties"/>
    </b-modal>
  </div>
</template>

<script>
import $ from 'jquery'
import Utils from "@/utils/utils";
import {accountService} from "@/services/api/accountService";
import VueLoading from "vue-loading-overlay";
import AllergenDietaryFilter from "@/components/sub-components/AllergenDietaryFilter.vue";
import {customerService} from "@/services/api/customerService";
import FlexContainer from "@/components/page-components/templates/FlexContainer.vue";
import {useCustomerStore} from "../../stores/customer";
import {addressService} from "../../services/api/addressService";

export default {
  data(){
    return {
      isLoading: false,
      customer: {},
      selectedAllergens: [],
      selectedProperties: [],
      addresses:[],
    }
  },
  computed:{
    Utils() {
      return Utils
    },
    config(){
      return window.config;
    },
    diataryInfoCount() {
      return this.selectedAllergens.length + this.selectedProperties.length;
    },
  },
  components:{
    AllergenDietaryFilter,
    VueLoading,
    FlexContainer
  },
  setup() {
    const customerStore = useCustomerStore();

    return {
      customerStore
    };
  },
  methods:{
    async handleEditCustomerDietaryAttributes(attributes) {
      this.loading = true;
      const result = await customerService.editCustomerDietaryAttributes(attributes);
      this.loading = false;

      this.customerStore.setDietaryInfo(result.wondCustomer.dietaryInfo);
      this.selectedAllergens = result.wondCustomer.allergenIds || [];
      this.selectedProperties = result.wondCustomer.propertyIds || [];

      this.$bvModal.hide("allergen-dietary-filter-modal");
      this.makeToast('success', this.$t('notification.dietaryInfosConfigUpdate'));
    },
    navigateToEditCustomer() {
      this.$navigateToEditCustomer();
    },
    navigateToPasswordReset() {
      this.$navigateToPasswordReset();
    },
    viewAllergenDietaryFilter() {
      this.$bvModal.show("allergen-dietary-filter-modal");
    },
    editAddress() {
      const selectedAddressId = $('#addressPicker').val();
      this.$navigateToEditAddress(selectedAddressId);
    } ,
    addNewAddress(){
      this.$navigateToEditAddress();
    },
    async refreshAddresses() {
      const result = await addressService.getAll();
      if (result.exception !== undefined) {
        this.makeToast('danger', this.$t('notification.noAddresses'));
        return;
      }
      this.addresses = result.addresses;

      let currentlySelectedAddressId = $('#addressPicker').val();
      if (currentlySelectedAddressId === null || currentlySelectedAddressId === undefined) {
        this.selectedAddress = result.addresses.sort((a,b)=>b.isPrimary-a.isPrimary)[0]; // if no address has been selected, try to select the Primary Address
      } else {
        this.selectedAddress = result.addresses.filter(addr => addr.id.toString() === currentlySelectedAddressId.toString())[0];
      }
    },
    async logout() {
      const result = await accountService.logout();
      if (result.error) {
        this.makeToast('danger', result.message);
      } else {
        this.makeToast('success', this.$t('notification.logoutSuccessful'));
        this.$navigateToLogin();
      }
    },
  },
  async created() {
    const config = this.config;

    this.isLoading = true;
    const result = await accountService.afterLogin();
    this.isLoading = false;

    if (result.exception !== undefined) {
      this.makeToast('danger', this.$t("balanceNotEnough"));
      return;
    }

    if (config.USE_ALLERGEN_FILTERS && result.wondCustomer.allergenIds !== undefined) {
      this.selectedAllergens = result.wondCustomer.allergenIds;
    }

    if (config.USE_ALLERGEN_FILTERS && result.wondCustomer.propertyIds !== undefined) {
      this.selectedProperties = result.wondCustomer.propertyIds;
    }

    this.customer = result.customer;

    await this.refreshAddresses();
  },
}
</script>

<style scoped>
.accountPageFlexer > * {
  flex: 1 1 350px;
  max-width: 100%;
}
</style>
