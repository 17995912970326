import {Order} from "@/constants/constants";

export function getShortOrderType(orderType) {
  const typeMap = {
    [Order.type.DELIVERY]: Order.shortType.DELIVERY,
    [Order.type.TAKEAWAY]: Order.shortType.TAKEAWAY,
    [Order.type.DINE_IN]: Order.shortType.DINE_IN,
  };
  return typeMap[orderType?.toLowerCase()] || Order.shortType.DINE_IN;
}

export function getNumericOrderType(orderType) {
  const typeMap = {
    [Order.type.DELIVERY]: Order.numericType.DELIVERY,
    [Order.type.TAKEAWAY]: Order.numericType.TAKEAWAY,
    [Order.type.DINE_IN]: Order.numericType.DINE_IN,
  };
  return typeMap[orderType?.toLowerCase()] || Order.numericType.DINE_IN;
}

export function getOrderTypeByNumber(numericOrderType) {
  const typeMap = {
    [Order.numericType.DELIVERY]: Order.type.DELIVERY,
    [Order.numericType.TAKEAWAY]: Order.type.TAKEAWAY,
    [Order.numericType.DINE_IN]: Order.type.DINE_IN,
  };
  return typeMap[numericOrderType] || Order.type.DINE_IN;
}
