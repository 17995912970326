import Vue from 'vue';
import VueSimpleAlert from "vue-simple-alert";
import App from './App.vue';
import VueCookies from 'vue-cookies'
import VueValidate from 'vuelidate'
import VueLoading from 'vue-loading-overlay'
import VueAxios from "vue-axios";
import axios from 'axios';
import NavigationPlugin from './plugins/navigation';
import Effects from "@/plugins/effects";
import {BootstrapVue, BootstrapVueIcons, IconsPlugin} from "bootstrap-vue";
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faAddressBook,
    faArrowLeft,
    faArrowLeftLong,
    faArrowRightLong,
    faBan,
    faBars,
    faBurger,
    faCalendarDays,
    faCartPlus,
    faCartShopping,
    faCashRegister,
    faCheckCircle,
    faChevronRight,
    faCircleArrowLeft,
    faCircleInfo,
    faCircleLeft,
    faCircleMinus,
    faCirclePlus,
    faClock,
    faClockRotateLeft,
    faCreditCard,
    faEnvelope,
    faExclamationTriangle,
    faEye,
    faFloppyDisk,
    faHandDots,
    faHome,
    faKey,
    faLock,
    faPenToSquare,
    faPlus,
    faPowerOff,
    faQrcode,
    faReceipt,
    faRectangleList,
    faSearch,
    faShop,
    faShopSlash,
    faTicket,
    faTimes,
    faTimesCircle,
    faTrashCan,
    faUser,
    faUserGraduate,
    faUtensils
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {AlertMixin} from "@/components/mixins/alertMixin";
import {loadCss} from "@/utils/loadCss";
import i18n from "@/i18n";
import router from "@/router/index.js";
import {faFacebookF, faInstagram, faTwitter} from "@fortawesome/free-brands-svg-icons";
import pinia from "@/stores";

Vue.use(VueAxios, axios);
Vue.use(VueSimpleAlert);
Vue.use(VueCookies);
Vue.use(VueValidate);
Vue.use(VueLoading);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(IconsPlugin);
Vue.use(NavigationPlugin);
Vue.use(Effects);

const vueApp = new Vue({
    i18n,
    router,
    pinia,
    render: h => h(App),
});

Vue.mixin(AlertMixin);

Vue.config.productionTip = false

// Loading a CSS file at runtime
loadCss(process.env.BASE_URL + '/settings/resources/custom.css').then(() => {
  console.log('CSS file loaded successfully');
}).catch((error) => {
  console.error(error);
});

Vue.component('font-awesome-icon', FontAwesomeIcon)
library.add(
    faAddressBook,
    faArrowLeft,
    faArrowLeftLong,
    faArrowRightLong,
    faBan,
    faBars,
    faBurger,
    faCalendarDays,
    faCartPlus,
    faCartShopping,
    faCashRegister,
    faCheckCircle,
    faChevronRight,
    faCircleArrowLeft,
    faCircleInfo,
    faCircleLeft,
    faCircleMinus,
    faCirclePlus,
    faClock,
    faClockRotateLeft,
    faCreditCard,
    faEnvelope,
    faExclamationTriangle,
    faEye,
    faFacebookF,
    faFloppyDisk,
    faHandDots,
    faHome,
    faInstagram,
    faKey,
    faLock,
    faPenToSquare,
    faPlus,
    faPowerOff,
    faQrcode,
    faReceipt,
    faRectangleList,
    faSearch,
    faShop,
    faShopSlash,
    faTicket,
    faTimes,
    faTimesCircle,
    faTrashCan,
    faTwitter,
    faUser,
    faUserGraduate,
    faUtensils
)

// Global error handler
vueApp.errorHandler = (err, vueInstance, info) => {

  // Handle the error globally
  console.error("Global error:", err);
  console.log("Vue instance:", vueInstance);
  console.log("Error info:", info);

  // UI notification
  this.makeToast('warning', this.$t('notification.genericError'));
};

vueApp.$mount('#app');

// eslint-disable-next-line no-unused-vars
Vue.mixin({
  data: function() {
    return {
      globalCartObject: [],
      TableMode : false
    }
  },
  computed: {
    shoppingCart() {
      return this.globalCartObject;
    }
  },
});

