<template>
  <div>
    <img id="LogoImage" :src="logoPath" :alt="$t('ariaLabel.logo')"/>
    <img id="LogoImageSmall" :src="smallLogoPath" :alt="$t('ariaLabel.logo')" style="height: 40px;"/>
  </div>
</template>

<script>

export default {
  name: "logo-image",
  computed: {
    logoPath() {
      return process.env.BASE_URL + 'settings/resources/logo.png';
    },
    smallLogoPath() {
      return process.env.BASE_URL + 'settings/resources/logo_small.png';
    },
  },
}
</script>

<style scoped>
#LogoImage {
  display: initial;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
#LogoImageSmall {
  display: none;
  height: 100%;
  width: auto;
}
@media only screen and (max-width: 767px) {
  #LogoImage {
    display: none;
  }
  #LogoImageSmall {
    display: initial;
  }
}
</style>
