<template>
  <b-container fluid>
    <div class="dashboard">
      <div class="dashboard-content grid-layout">
        <Button v-for="item in links" :key="item.key" :action="item.action" :icon="item.icon" lg left class="dashboard-item">
          <!-- class="btn btn-primary btn-lg btnSubmit mx-0 w-100 noMargin dashboard-item" -->
            <h2>{{ item.name() }}</h2>
        </Button>
      </div>
    </div>
  </b-container>
</template>

<script>
import {useAppStore} from "@/stores/app";
import {Workflow} from "@/constants/constants";
import Button from "@/components/barrier-free-template-components/Button.vue";

export default {
  components: {Button},
  data() {
    return {
      items:[
        {key: 0, name: ()=>'Template', action: this.navigateToTemplate, icon: 'user', visible: ()=> process.env.NODE_ENV !== 'production'},
        {key: 1, name: ()=>this.$t('title.customerCard'), action: this.navigateToCustomerCard, icon: 'qrcode', visible: ()=>this.config.CUSTOMER_CARD.enabled},
        {key: 2, name: ()=>this.$t('title.account'), action: this.navigateToAccount, icon: 'user'},
        {key: 3, name: ()=>this.$t('title.order'), action: this.navigateToOrder, icon: 'burger', visible: ()=>this.config.ORDER.enabled},
        {key: 4, name: ()=>this.$t('title.mealPlan'), action: this.navigateToMealPlan, icon: 'calendar-days', visible: ()=>this.config.MEAL_PLAN.enabled},
        {key: 5, name: ()=>this.$t('title.orderHistory'), action: this.navigateToOrderHistory, icon: 'rectangle-list', visible: ()=>this.config.ORDER_HISTORY.enabled},
        {key: 6, name: ()=>this.$t('title.transactionHistory'), action: this.navigateToTransactionHistory, icon: 'receipt', visible: ()=>this.config.TRANSACTION_HISTORY.enabled},
        {key: 7, name: ()=>this.$t('title.studentAccounts'), action: this.navigateToStudentAccounts, icon: 'user-graduate', visible: ()=>this.config.STUDENT_ACCOUNTS.enabled},
      ],
    };
  },
  computed: {
    config() {
      return window.config;
    },
    links(){
      return this.$data.items.filter(function(item){return item.visible===undefined || item.visible();});
    },
  },
  setup() {
    const appStore = useAppStore();

    return {
      appStore
    };
  },
  methods: {
    navigateToTemplate() {
      this.$navigateToTemplate();
    },
    navigateToAccount() {
      this.$navigateToAccount();
    },
    navigateToOrder() {
      this.appStore.setWorkflow(Workflow.ORDER);
      this.$navigateToOrderShops();
    },
    navigateToMealPlan() {
      this.appStore.setWorkflow(Workflow.MEAL_PLAN);
      this.$navigateToMealPlanShops();
    },
    navigateToCustomerCard() {
      this.appStore.setWorkflow(Workflow.TOP_UP);
      this.$navigateToCustomerCard();
    },
    navigateToTransactionHistory() {
      this.$navigateToTransactionHistory();
    },
    navigateToOrderHistory() {
      this.$navigateToOrderHistory();
    },
    navigateToStudentAccounts() {
      this.appStore.setWorkflow(Workflow.SCHOOL);
      this.$navigateToStudentAccounts();
    },
  },
};
</script>

<style scoped>
.dashboard {
  padding: 20px 0;
  /*background-color: #f9f9f9;*/
}

.dashboard-content {
  /*display: flex;
  //flex-wrap: wrap;
  //gap: 20px; /* Add spacing between items
  margin-top: 10px;*/
}

.dashboard-item {
  flex: 1 1 calc(30% - 20px); /* Responsive width with some margin */
  /*
  background-color: var(--dashboard-item-background-color);
  color: var(--dashboard-item-text-color);
  */
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  align-items: center; /* Vertically center icon and title */
  cursor: pointer;
  transition: background-color 0.3s;
  gap: 20px;
  /*
  &:hover {
    color: var(--dashboard-item-background-color);
  }
  */
}

/*
.dashboard-item:hover {
  background-color: #f0f0f0; !* Change background on hover *!
}
*/

.icon {
  /*margin-right: 20px; /* Space between icon and title */
  font-size: 24px;
  color: currentColor;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.title {
  font-size: 1.2em;
}

h2 {
  margin: 0;
  font-size: 1.2rem;
  line-height: normal;
}
</style>
