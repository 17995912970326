import { defineStore } from 'pinia';

export const useCustomerStore = defineStore('customer', {
    // State
    state: () => ({
      dietaryInfo: null,
      type: null,
    }),

    getters: {
      getDietaryInfo: (state) => state.dietaryInfo,
      getType: (state) => state.type,
    },

    actions: {
      setDietaryInfo(dietaryInfo) {
          this.dietaryInfo = dietaryInfo;
      },
      setType(type) {
        this.type = type;
      },
    },

    persist: {
        enabled: true,
        strategies: [
            {
                key: 'customer',
                storage: localStorage,
            },
        ],
    },
});
