<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>

    <div class="col-12">
      <b-form @submit.stop.prevent class="noPadding" :disabled="address">
        <b-form-row>
          <b-col cols="12" md="6" v-show="config.CUSTOMER_DATA.FIELDS.firstName.visible">
            <b-form-group class="form-group fontField" label-for="firstName">
              <template v-slot:label>
                {{ $t('field.firstName') }} <span v-if="config.CUSTOMER_DATA.FIELDS.firstName.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg"
                            id="firstName" name="firstName"
                            type="text" :autofocus="autofocus"
                            v-model="$v.form.firstName.$model"
                            :state="validateState('firstName')"
                            aria-describedby="firstName-feedback"
                            :maxLength="config.CUSTOMER_DATA.FIELDS.firstName.maxLength"
                            :required="config.CUSTOMER_DATA.FIELDS.firstName.required" />
              <b-form-invalid-feedback class="text-left" id="firstName-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" v-show="config.CUSTOMER_DATA.FIELDS.lastName.visible">
            <b-form-group class="form-group fontField" label-for="lastName">
              <template v-slot:label>
                {{ $t('field.lastName') }} <span v-if="config.CUSTOMER_DATA.FIELDS.lastName.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg"
                            id="lastName"
                            name="lastName"
                            type="text"
                            v-model="$v.form.lastName.$model"
                            :state="validateState('lastName')"
                            aria-describedby="lastName-feedback"
                            :maxLength="config.CUSTOMER_DATA.FIELDS.lastName.maxLength"
                            :required="config.CUSTOMER_DATA.FIELDS.lastName.required" />
              <b-form-invalid-feedback class="text-left" id="lastName-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-show="config.CUSTOMER_DATA.FIELDS.emailAddr.visible">
          <b-col cols="12" md="6">
            <b-form-group class="form-group fontField" label-for="emailAddr">
              <template v-slot:label>
                {{ $t('field.email') }} <span v-if="config.CUSTOMER_DATA.FIELDS.emailAddr.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg" id="emailAddr" name="emailAddr" type="email" :required="config.CUSTOMER_DATA.FIELDS.emailAddr.required" v-model="$v.form.emailAddr.$model"
                            :state="validateState('emailAddr')" :aria-describedby="validateState('emailAddr')?'':'emailAddr-feedback'" :maxLength="config.CUSTOMER_DATA.FIELDS.emailAddr.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="emailAddr-feedback">{{ $t('validation.invalidEmail') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-show="config.CUSTOMER_DATA.FIELDS.streetAddress.visible">
          <b-col cols="12" md="6">
            <b-form-group class="form-group fontField" label-for="streetAddress">
              <template v-slot:label>
                {{ $t('field.street') }} <span v-if="config.CUSTOMER_DATA.FIELDS.streetAddress.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg"
                            id="streetAddress"
                            name="streetAddress" type="text"
                            v-model="$v.form.streetAddress.$model"
                            :state="validateState('streetAddress')"
                            aria-describedby="streetAddress-feedback"
                            :maxLength="config.CUSTOMER_DATA.FIELDS.streetAddress.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="streetAddress-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="12" md="6" v-show="config.CUSTOMER_DATA.FIELDS.postcode.visible">
            <b-form-group class="form-group fontField" label-for="postcode">
              <template v-slot:label>
                {{ $t('field.postalCode') }} <span v-if="config.CUSTOMER_DATA.FIELDS.postcode.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg"
                            id="postcode"
                            name="postcode"
                            type="text"
                            v-model="$v.form.postcode.$model"
                            :state="validateState('postcode')"
                            aria-describedby="postcode-feedback"
                            :maxLength="config.CUSTOMER_DATA.FIELDS.postcode.maxLength"/>
              <b-form-invalid-feedback class="text-left" id="postcode-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" v-show="config.CUSTOMER_DATA.FIELDS.city.visible">
            <b-form-group class="form-group fontField" label-for="city">
              <template v-slot:label>
                {{ $t('field.city') }} <span v-if="config.CUSTOMER_DATA.FIELDS.city.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg"
                            id="city"
                            name="city"
                            type="text"
                            v-model="$v.form.city.$model"
                            :state="validateState('city')"
                            aria-describedby="city-feedback"
                            :maxLength="config.CUSTOMER_DATA.FIELDS.city.maxLength" />
              <b-form-invalid-feedback class="text-left" id="city-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-show="config.CUSTOMER_DATA.FIELDS.countryId.visible">
          <b-col cols="12" md="6">
            <b-form-group class="form-group fontField" label-for="countryId">
              <template v-slot:label>
                {{ $t('field.country') }} <span v-if="config.CUSTOMER_DATA.FIELDS.countryId.required" class="text-danger">*</span>
              </template>
              <select-picker-country
                id="customerCountry"
                name="customerCountry"
                class="countrySelect"
                v-if="countriesLoaded"
                :options="countries"
                :callback="selectCountry"
                :preselectedId="selectedCountryId"
                aria-describedby="countryId-feedback"
                :state="validateState('countryId')" />
              <b-form-invalid-feedback class="text-left" id="countryId-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row v-show="config.CUSTOMER_DATA.FIELDS.telephoneNumber.visible">
          <b-col cols="12" md="6">
            <b-form-group class="form-group fontField" label-for="telephoneNumber">
              <template v-slot:label>
                {{ $t('field.telephoneNumber') }} <span v-if="config.CUSTOMER_DATA.FIELDS.telephoneNumber.required" class="text-danger">*</span>
              </template>
              <b-form-input class="form-control form-control-lg" id="telephoneNumber" name="telephoneNumber" type="text" v-model="$v.form.telephoneNumber.$model"
                            :state="validateState('telephoneNumber')" aria-describedby="telephoneNumber-feedback"/>
              <b-form-invalid-feedback class="text-left" id="telephoneNumber-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>

        <b-form-row>
          <b-col cols="12" md="12">
            <b-button type="submit" @click.stop="onSubmit" :disabled="$v.$invalid" class="btn btn-primary btn-lg btn-block btnSubmit g-recaptcha w-100 noMargin">{{ message }}</b-button>
          </b-col>
        </b-form-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import VueLoading from 'vue-loading-overlay'
import selectPickerCountry from '../sub-components/SelectPickerCountry.vue'
import {email, requiredIf} from 'vuelidate/lib/validators'
import {addressService} from "../../services/api/addressService";
import {accountService} from "../../services/api/accountService";

export default {
  data() {
    return {
      form: {
        gender: undefined,
        firstName: undefined,
        lastName: undefined,
        city: undefined,
        company: undefined,
        countryId: undefined,
        postcode: undefined,
        state: undefined,
        streetAddress: undefined,
        streetAddress1: undefined,
        suburb: undefined,
        emailAddr: undefined,
        telephoneNumber: undefined,
        telephoneNumber1: undefined
      },
      countriesLoaded: false,
      countries: [],
      selectedCountry: null,
      selectedCountryId: 0,
      isLoading: false
    };
  },
  validations: { // STANDARD
    form: {
      firstName: {
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.firstName.required),
      },
      lastName:{
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.lastName.required),
      },
      streetAddress:{
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.streetAddress.required),
      },
      postcode:{
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.postcode.required),
      },
      city:{
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.city.required),
      },
      countryId:{
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.countryId.required),
      },
      telephoneNumber:{
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.telephoneNumber.required),
      },
      emailAddr:{
        required: requiredIf(() => window.config.CUSTOMER_DATA.FIELDS.emailAddr.required),
        email,
      },
    }
  },
  components:{
    'loading': VueLoading,
    'select-picker-country' : selectPickerCountry
  },
  props:{
    address: { Type: Object },
    callbackClose: { Type: Function },
    autofocus: Boolean
  },
  computed: {
    config() {
        return window.config;
    },
    message(){
      return this.addressId ? this.$t('button.save') : this.$t('button.create');
    },
  },
  methods:{
    validateState(name) {
      const {$dirty, $error} = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    selectCountry(countryId) {
        console.log("selectCountry");
        console.log(countryId);
        this.address.countryId = countryId;
    },
    async onSubmit() {
        this.$v.$touch();

        if (this.$v.$invalid) {
            this.makeToast('warning', this.$t('notification.fillAllRequiredFields'));
            return;
        }

        if (this.addressId) {
          this.isLoading = true;
          await addressService.editAddress(this.address.gender, this.address.firstName, this.address.lastName, this.address.city,
            this.address.company, this.address.countryId, this.address.postcode, this.address.state, this.address.streetAddress, this.address.streetAddress1,
            this.address.suburb, this.address.emailAddr, this.address.telephoneNumber, this.address.telephoneNumber1);

          this.isLoading = false;
        } else {
          this.isLoading = true;
          await addressService.createAddress(this.form.gender, this.form.firstName, this.form.lastName, this.form.city,
            this.form.company, this.form.countryId, this.form.postcode, this.form.state, this.form.streetAddress, this.form.streetAddress1,
            this.form.suburb, this.form.emailAddr, this.form.telephoneNumber, this.form.telephoneNumber1);
          this.isLoading = true;

          this.callbackClose();
        }
      },
    async fillForm() {
      const result = await accountService.getCountries(0);

      this.form.gender = this.address.gender;
      this.form.firstName = this.address.firstName;
      this.form.lastName = this.address.lastName;
      this.form.city = this.address.city;
      this.form.company = this.address.company;
      this.form.countryId = this.address.countryId;
      this.form.postcode = this.address.postcode;
      this.form.state = this.address.state;
      this.form.streetAddress = this.address.streetAddress;
      this.form.streetAddress1 = this.address.streetAddress1;
      this.form.suburb = this.address.suburb;
      this.form.emailAddr = this.address.emailAddrOptional;
      this.form.telephoneNumber = this.address.telephoneNumber;
      this.form.telephoneNumber1 = this.address.telephoneNumber1;

      this.countries = result.countries;
      this.selectedCountry = result.selectedCountry;
      this.form.countryId = result.selectedCountry.id;
      this.selectedCountryId = result.selectedCountry.id;
      this.countriesLoaded = true;
    },
  },
  async mounted() {
    if (this.address) {
      await this.fillForm();
    }
  },
}
</script>

<style scoped>
</style>
