<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"
             loader='bars'></loading>

    <div class="">
      <FlexContainer v-if="isChild" :title="$t('subTitle.customerCardInfos')" class="mb-2" #content>
        <div class="d-flex flex-column noPadding text-left gap10">
          <h3 class="normalText"><strong>{{ $t('text.cardHolder') }}:</strong> {{ fullName(wondCustomer) }}</h3>
          <h3 class="normalText"><strong>{{ $t('text.cardNumber') }}:</strong> {{ wondCustomer.cardNum }}</h3>
          <h3 class="normalText"><strong>{{ $t('text.customerCardBalance') }}</strong> {{ wondCustomer.formattedPrepayBalanceCash }}</h3>
        </div>
      </FlexContainer>
      <FlexContainer :title="$t('subTitle.configuration')" #content>
        <div class="d-flex flex-column noPadding gap10">
          <label for="reChargeAmount" class="text-left fontField">{{ $t('text.rechargeAmount') }}</label>
          <b-input-group class="input-group topUpInputField noMargin" :prepend="Utils.getCurrencySymbol(config.ACCOUNT_BALANCE_CURRENCY)">
            <currency-input class="form-control" id="reChargeAmount" name="reChargeAmount" :aria-label="$t('ariaLabel.rechargeHelpText')"
                            v-model="rechargeAmount"
                            :options="{
                                      currency: config.ACCOUNT_BALANCE_CURRENCY,
                                      currencyDisplay: 'hidden',
                                      valueRange: { min: config.AUTO_TOPUP.rechargeAmount.minValue, max: config.AUTO_TOPUP.rechargeAmount.maxValue }}">
            </currency-input>
          </b-input-group>
          <label for="reChargeAmount" class="text-left fontField">{{ $t('text.thresholdAmount') }}</label>
          <b-input-group class="input-group topUpInputField noMargin" :prepend="Utils.getCurrencySymbol(config.ACCOUNT_BALANCE_CURRENCY)">
            <currency-input class="form-control" id="thresholdAmount" name="thresholdAmount" :aria-label="$t('ariaLabel.thresholdAmount')"
                            v-model="thresholdAmount"
                            :options="{
                                      currency: config.ACCOUNT_BALANCE_CURRENCY,
                                      currencyDisplay: 'hidden',
                                      valueRange: { min: config.AUTO_TOPUP.thresholdAmount.minValue, max: config.AUTO_TOPUP.thresholdAmount.maxValue }}">
            </currency-input>
          </b-input-group>
          <button
            class="toggle-button"
            aria-pressed="false"
            id="toggleButton"
            :value="active">
            <span class="toggle-label">{{$t('text.enabled')}}</span>
          </button>
          <b-button type="button" :disabled="rechargeAmount === null || thresholdAmount === null" @click.stop="confirm(rechargeAmount, thresholdAmount)" :aria-label="$t('ariaLabel.rechargeConfirmHelpText')" class="btn btn-primary topUpBtn noMargin">{{ $t('button.confirm', rechargeAmount, config.ACCOUNT_BALANCE_CURRENCY) }}</b-button>
        </div>
      </FlexContainer>
    </div>

    <modal ref="autoTopupModal">
      <template v-slot:header>
        <h2 id="modalTitle">{{$t('title.autoCharge')}}</h2>
      </template>

      <template v-slot:body>

        <b-form @submit="autoTopupFormSubmit">
          <!-- <b-form-group id="input-group-1"> -->
          <b-form-checkbox
            id="checkbox-1"
            v-model="autoTopupForm.active"
            name="checkbox-1"
            value="1"
            unchecked-value="0"
            switch
          >
            {{$t('text.enabled')}}
          </b-form-checkbox>
          <!-- </b-form-group> -->

          <b-form-group
            id="input-group-2"
            :label="$t('text.threshold')"
            label-for="input-2"
            :description="$t('text.thresholdDescription')"
            :append="' ' + config.ACCOUNT_BALANCE_CURRENCY"
          >
            <b-form-input
              id="input-2"
              v-model="autoTopupForm.thresholdValue"
              type="number"
              placeholder="0"
              step="0.01"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-3" :label="$t('text.rechargeAmount')" label-for="input-3" :description="$t('text.rechargeAmountDescription')">
            <b-form-input
              id="input-3"
              v-model="autoTopupForm.rechargeValue"
              type="number"
              placeholder="0"
              step="0.01"
              required
            ></b-form-input>
          </b-form-group>

          <b-button type="submit" variant="primary">{{$t("button.confirm")}}</b-button>
          <b-button type="reset" variant="primary" @click="$refs.autoTopupModal.closeModal()">{{$t("button.cancel")}}</b-button>
        </b-form>
      </template>

      <template v-slot:footer>
        <!-- <div class="d-flex align-items-center justify-content-between">
        <b-button class="btn btn--secondary" @click="$refs.autoTopupModal.closeModal()">Cancel</b-button>
        <b-button class="btn btn--primary" @click="$refs.autoTopupModal.closeModal()">Save</b-button>
        </div> -->
      </template>
    </modal>
  </div>
</template>

<script>
import Utils from "@/utils/utils";
import VueLoading from "vue-loading-overlay";
import {accountService} from "@/services/api/accountService";
import FlexContainer from "@/components/page-components/templates/FlexContainer.vue";
import {useAppStore} from "../../stores/app";
import {Workflow} from "../../constants/constants";
import modal from "../sub-components/ModalAccessible.vue";
import {customerService} from "../../services/api/customerService";
import CurrencyInput from "../sub-components/CurrencyInput.vue";

export default {
  name: "auto-top-up",
  components: {
    CurrencyInput,
    modal,
    'loading' : VueLoading,
    FlexContainer
  },
  data() {
    return {
      isLoading: false,
      wondCustomer: {},
      active: false,
      thresholdAmount: null,
      rechargeAmount: null,
    };
  },
  setup() {
    const appStore = useAppStore();

    return {
      appStore
    };
  },
  props: {
    customerId: {
      type: [String, Number],
    }
  },
  computed: {
    Utils() {
      return Utils
    },
    config(){
      return window.config;
    },
    isChild() {
      return this.appStore.isWorkflow(Workflow.SCHOOL);
    },
  },
  methods: {
    fullName(customer) {
      const nameParts = [];

      if (customer.description) {
        nameParts.push(customer.description);
      }

      if (customer.firstName) {
        nameParts.push(customer.firstName);
      }

      return nameParts.join(' ').trim();
    },
    async selectChild(id) {
      this.isLoading = true;
      const result = await accountService.selectChild(id);
      this.isLoading = false;

      if (result.exception !== undefined || result.errorCode < 0) {
        this.makeToast('danger', this.$t('notification.selectChildError'));
        return;
      }

      return result;
    },
    async confirm(active, rechargeAmount, thresholdAmount) {
      this.isLoading = true;
      await customerService.configureRecurringPayment(active, thresholdAmount, rechargeAmount);
      this.isLoading = false;

      this.makeToast('success', this.$t('notification.autoChargeConfigUpdate'));
    },
  },
  async mounted() {
    let result;
    if (this.isChild) {
      this.isLoading = true;
      result = await this.selectChild(this.customerId);
      this.isLoading = false;

      this.wondCustomer = result.wondChild;
    } else {
      result = await customerService.getCustomer();
    }

    if (result.recurringPayment) {
      let recurringPaymentData = result.recurringPayment;
      this.active = recurringPaymentData.active !== undefined ? recurringPaymentData.active : false;
      this.thresholdAmount = recurringPaymentData.thresholdValue !== undefined ? recurringPaymentData.thresholdValue : undefined;
      this.rechargeAmount = recurringPaymentData.rechargeValue !== undefined ? recurringPaymentData.rechargeValue : undefined;
    }
  },
};
</script>
<style scoped>

.topUpBtn {
  width: 100%;
}

.topUpInputField{
  width: 100%;
  height: 44px;
  & .input-group-text, & input {
    font-size: 20px;
    height: 100%;
  }
}
</style>
