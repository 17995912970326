import { apiUtils } from "@/utils/apiUtils";

export const productService = {
  async getCategories() {
    try {
      const result = await apiUtils.doGet("/TCPCategoryTreeJ.action", {
        json: true
      });

      return result;
    } catch (error) {
      console.error("Error fetching categories:", error);
      throw error;
    }
  },
  async getMealPlans(dateFrom, dateTo) {
        try {
            const result = await apiUtils.doGet("/TCPGetMealPlansJ.action", {
                json: 'true',
                dateFrom: dateFrom,
                dateTo: dateTo,
                includeReservations: true,
                includeAllergenIds: true,
                includePropertyIds: true,
                includeNutriValues: true,
                includePropertyList: true
            });

            return result;
        } catch (error) {
            console.error("Error fetching meal plans:", error);
            throw error;
        }
    },
    async getProducts(limit, offset, orderBy, fillCustomAttrArray, fillDescription,
                      fillMiscItems, fillProductOptions, fillProductQuantities,
                      dietaryInfo, returnCategoryFacets, returnManufacturerFacets,
                      priceTypeRequired, searchText, whereToSearch, categoryId) {
      try {
        const result = await apiUtils.doPost('/TCPProductSearchJ.action', {
          json: 'true',
          dataDescriptor: {
            limit: limit,
            offset: offset,
            orderBy: orderBy,
            fillCustomAttrArray: fillCustomAttrArray,
            fillDescription: fillDescription,
            fillMiscItems: fillMiscItems,
            fillProductOptions: fillProductOptions,
            fillProductQuantities: fillProductQuantities,
          },
          dietaryInfo: dietaryInfo,
          productSearch: {
            returnCategoryFacets: returnCategoryFacets,
            returnManufacturerFacets: returnManufacturerFacets,
            priceTypeRequired: priceTypeRequired,
            searchText: searchText != "" ? searchText : undefined,
            whereToSearch: whereToSearch,
            categoryId: searchText != "" ? undefined : categoryId
          }
        });

        return result;
      } catch (error) {
        console.error("Error fetching products:", error);
        throw error;
      }
    },
    async getProductDetails(productId, includeChildren, addChildren, includeAllergenIds, includePropertyIds, includeNutriValues) {
        try {
            const result = await apiUtils.doGet("/TCPGetProductDetailsJ.action", {
                kkProdId: productId,
                json: true,
                includeChildren: includeChildren,
                addChildren: addChildren,
                includeAllergenIds: includeAllergenIds,
                includePropertyIds: includePropertyIds,
                includeNutriValues: includeNutriValues
            });

            return result;
        } catch (error) {
            console.error("Error fetching product details:", error);
            throw error;
        }
    },
    async getDietaryInfoTypes() {
      try {
        const result = await apiUtils.doGet("/TCPGetDietaryInfoTypesJ.action", {
          json: true,
        });

        return result;
      } catch (error) {
        console.error("Error fetching dietary info types:", error);
        throw error;
      }
    },
};
