<template>
  <b-container fluid class="d-flex flex-column" style="row-gap: 7px">
    <!-- Check if product exists before rendering the image -->
    <b-row v-if="product" class="product-image">
      <img :src="product.image1" :alt="product.name" />
    </b-row>
    <b-row v-if="product && product.longDesc">
      <b-card class="custom-card" no-body>
        <b-card-header class="small-header" role="tab">
          <b-row>
            <b-col>
              <b-button block v-b-toggle.accordion-product-details class="btn btn-accordion d-flex justify-content-between align-items-center">
                <span>{{ $t('subTitle.productDetails') }}</span>
                <font-awesome-icon :icon="['fas', 'circle-plus']" class="ml-2" />
              </b-button>
            </b-col>
          </b-row>
        </b-card-header>
        <b-collapse id="accordion-product-details" accordion="accordion-product-details" role="tabpanel">
          <b-card-body class="product-details">
            <!-- Check if longDesc exists -->
            <b-row>
              <b-col>
                <p>{{ product.longDesc }}</p>
              </b-col>
            </b-row>
          </b-card-body>
        </b-collapse>
      </b-card>
    </b-row>
    <b-row v-if="product && product.properties && product.properties.length > 0">
      <b-card class="custom-card" no-body>
        <b-card-header class="small-header" role="tab">
          <b-row>
            <b-col>
              <b-button block v-b-toggle.accordion-properties class="btn btn-accordion d-flex justify-content-between align-items-center">
                <span>{{ $t('subTitle.properties') }}</span>
                <font-awesome-icon :icon="['fas', 'circle-plus']" class="ml-2" />
              </b-button>
            </b-col>
          </b-row>
        </b-card-header>
        <b-collapse id="accordion-properties" accordion="accordion-properties" role="tabpanel">
          <b-card-body>
            <b-tr class="allergens"> <!-- TODO STOP USING TR IF THERE IS NO TABLE!!! -->
              <b-td v-for="property in product.properties" :key="property.id">
                <b-tr> <!-- TODO STOP USING TR IF THERE IS NO TABLE!!! -->
                  <img :src="property.image" :alt="property.name" class="allergen-img" />
                </b-tr>
                <b-tr>
                  {{ property.name }}
                </b-tr>
              </b-td>
            </b-tr>
          </b-card-body>
        </b-collapse>
      </b-card>
    </b-row>
    <b-row v-if="product && product.allergens && product.allergens.length > 0">
      <b-card class="custom-card" no-body>
        <b-card-header class="small-header" role="tab">
          <b-row>
            <b-col>
              <b-button block v-b-toggle.accordion-allergens class="btn btn-accordion d-flex justify-content-between align-items-center">
                <span>{{ $t('subTitle.allergens') }}</span>
                <font-awesome-icon :icon="['fas', 'circle-plus']" class="ml-2" />
              </b-button>
            </b-col>
          </b-row>
        </b-card-header>
        <b-collapse id="accordion-allergens" accordion="accordion-allergens" role="tabpanel">
          <b-card-body>
            <b-tr class="allergens"> <!-- TODO STOP USING TR IF THERE IS NO TABLE!!! -->
              <b-td v-for="allergen in product.allergens" :key="allergen.id">
                <b-tr> <!-- TODO STOP USING TR IF THERE IS NO TABLE!!! -->
                  <img :src="allergen.image" :alt="allergen.name" class="allergen-img" />
                </b-tr>
                <b-tr>
                  {{ allergen.name }}
                </b-tr>
              </b-td>
            </b-tr>
          </b-card-body>
        </b-collapse>
      </b-card>
    </b-row>
    <b-row v-if="product && product.nutriValues && product.nutriValues.length > 0">
      <b-card class="custom-card" no-body>
        <b-card-header class="small-header" role="tab">
          <b-row>
            <b-col>
              <b-button block v-b-toggle.accordion-nutriValues class="btn btn-accordion d-flex justify-content-between align-items-center">
                <span>{{ $t('subTitle.nutriValues') }}</span>
                <font-awesome-icon :icon="['fas', 'circle-plus']" class="ml-2" />
              </b-button>
            </b-col>
          </b-row>
        </b-card-header>
        <b-collapse id="accordion-nutriValues" accordion="accordion-nutriValues" role="tabpanel">
          <b-card-body>
            <div class="allergens" v-for="nutriValue in product.nutriValues" :key="nutriValue.id">
              <span class="text-left">
                {{ nutriValue.name }}
              </span>
              <span class="text-right">
                {{ nutriValue.value }}
              </span>
            </div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </b-row>

    <!-- Standard Options (Single Select) -->
    <b-row v-for="group in groupedItems.standard" :key="group.name">
      <Dropdown
        :label="group.name"
        :options="group.items"
        v-model="selectedOptions[group.name]"
        :required="group.minQty > 0"
        :placeholder="group.name + ' ' + $t('placeholder.chooseOption')"
        @selection-changed="handleSelectedOption(group.name, $event)"
        sortable="asc"
      />
    </b-row>

    <!-- Additions (MultiSelect with SubItems) -->
    <b-row>
      <Checkbox v-for="item in groupedItems.additions.items" :key="item.id"
        :label="item.name"
        :v-model="selectedAdditions[item]"
        checkboxSize="medium"
        checkboxId="checkbox${item.id}"
      />
      <!--<MultiSelectDropdown
        :label="group.name"
        :options="group.items"
        v-model="selectedAdditions[group.name]"
        :required="group.minQty > 0"
        :placeholder="group.name + ' ' + $t('placeholder.chooseOption')"
        @selection-changed="handleSelectedAddition(group.name, $event)"
        sortable="asc"
      />-->
    </b-row>

    <div v-if="!viewMode && maxQuantity > 0">
      <b-row cols="12">
        <b-form-spinbutton
          v-model=quantity
          :min=minQuantity
          :max=maxQuantity
        />
      </b-row>
      <b-row>
        <b-button v-if="quantity > 0" class="btn btn-accordion btn-primary" @click="addToBasket">
          <span>{{ formattedTotalPrice }}</span>
          <font-awesome-icon class="addIconMenu" :icon="['fas', 'cart-plus']" />
        </b-button>
        <b-button v-if="quantity === 0" class="btn btn-accordion btn-primary" @click="addToBasket">
          <font-awesome-icon class="mr-3" :icon="['fas', 'trash-can']" />
          <span>{{ $t('button.removeArticle') }}</span>
        </b-button>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import Utils from "@/utils/utils";
import {productService} from "@/services/api/productService";
import Dropdown from "@/components/barrier-free-template-components/Dropdown.vue";
import Checkbox from "@/components/page-components/templates/Checkbox.vue";

export default {
  props: {
    productId: {
      type: Number,
      required: true,
    },
    maxQuantity: {
      type: Number,
      required: false,
    },
    minQuantity: {
      type: Number,
      required: false,
    },
    actualQuantity: {
      type: Number,
      required: false,
    },
    group: {
      type: Object,
      required: false,
    },
    viewMode: Boolean
  },
  data() {
    return {
      product: {},
      groupedItems: {
        standard: [],
        additions: []
      },
      quantity: 1,
      selectedOptions: {},
      selectedAdditions: {},
    };
  },
  async created() {
    if (!this.productId) return;

    this.loading = true;
    const result = await productService.getProductDetails(this.productId, true, true, true, true, true);
    this.loading = false;

    this.quantity = this.actualQuantity;
    if (result.exception || !result.product) {
      this.makeToast("warning", this.$t("notification.getItemFailed"));
      return;
    }

    this.product = result.product;

    if (this.product.hasChildren && this.product.items) {
      let standard = [], additions = [];

      this.product.items.forEach(group => {
        const groupData = { name: group.name, minQty: group.minQty, maxQty: group.maxQty, items: [] };

        group.items.forEach(item => {
          const itemData = {
            value: item.kkProdId,
            text: item.price > 0 ? `${item.name} (${item.formattedPrice})` : item.name,
            disabled: item.locked,
            items: item.hasChildren ? item.items.map(subItem => ({
              value: subItem.kkProdId,
              text: subItem.price > 0 ? `${subItem.name} (${subItem.formattedPrice})` : subItem.name,
              disabled: subItem.locked,
            })) : []
          };

          groupData.items.push(itemData);
        });

        group.items.some(item => item.addition) ? additions.push(groupData) : standard.push(groupData);
      });

      this.groupedItems = { standard, additions };

      console.log("Standard Groups:");
      this.groupedItems.standard.forEach((group, index) => {
        console.log(`Group ${index + 1}:`, group);
      });

      console.log("Addition Groups:");
      this.groupedItems.additions.forEach((group, index) => {
        console.log(`Group ${index + 1}:`, group);
      });
    }
  },
  components: {
    Checkbox,
    Dropdown,
  },
  computed: {
    formattedTotalPrice() {
      const basePrice = this.product.price || 0;
      return Utils.formatCurrency(this.quantity * basePrice, window.config.ACCOUNT_BALANCE_CURRENCY);
    },
  },
  methods: {
    handleSelectedOption(group, value) {
      this.selectedOptions[group] = value;
    },
    handleSelectedAddition(group, values) {
      this.$set(this.selectedAdditions, group, values);
    },
    addToBasket() {
      let product = this.product;
      product.options = this.selectedOptions;
      product.additions = this.selectedAdditions;

      console.log("Emitting add-to-basket", {
        group: this.group,
        productId: this.product?.kkProdId,
        quantity: this.quantity,
        price: this.product?.price
      });

      this.$emit("add-to-basket", this.group, this.product.kkProdId, this.quantity, this.product.price);

      this.closeModal();
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.modal-body .product-image {
  aspect-ratio: 1 / 1;
}

.product-details {
  display: flex;
}

.product-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  aspect-ratio: 1 / 1;
}

.allergens {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.allergen-img {
  max-width: 30px;
  margin-right: 5px;
}

.small-header {
  font-size: 0.9em; /* Small header font size */
  text-align: left; /* Align header text to the left */
  background: none;
  padding: 0;
}

.btn-accordion {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  margin-top: 5px;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
  background-color: var(--main-color-button);
}

.addIconMenu {
  margin-left: 5px;
}

/* Align radio buttons and checkboxes to the left */
.b-form-radio-group,
.b-form-checkbox-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.b-form-radio,
.b-form-checkbox {
  margin-left: 0; /* Align radio buttons and checkboxes to the left */
}

/* Add padding to b-card-header title */
.b-card-header {
  padding-left: 1rem; /* Adjust as needed to match collapse button padding */
}
</style>
