<template>
  <div :class="{'cart-button-container':true,'popin':!hidden}" ref="container">
    <!-- Cart Button -->
    <b-button variant="primary" class="cart-button" @click="goToBasket">
      <span class="cart-icon">
        <font-awesome-icon :icon="['fas', 'cart-shopping']" />
        <!-- Product Counter displayed on the cart icon -->
        <span v-if="productCount > 0" class="product-counter" :class="{'product-counter':true,'counter-small':counterSmall}">{{ productCount }}</span>
      </span>
      <span class="cart-text">
        {{ $t('button.goToBasket') }}
        <!-- Total price displayed in parentheses -->
        <span class="cart-price">
          {{ typeof totalPrice === 'number' ? Utils.formatCurrency(totalPrice, config.ACCOUNT_BALANCE_CURRENCY) : totalPrice }}
        </span>
      </span>
    </b-button>
  </div>
</template>

<script>
import Utils from "@/utils/utils";
import {useAppStore} from "@/stores/app";
import {Workflow} from "@/constants/constants";

export default {
  name: 'CartButton',
  computed: {
    config() {
      return window.config;
    },
    Utils() {
      return Utils;
    },
  },
  props: {
    productCount: {
      type: Number,
      default: 0,
    },
    totalPrice: {
      type: [Number, String],
      default: 0.00,
    },
    hidden:Boolean,
    counterSmall:Boolean, /* todo : make this a config */
  },
  setup() {
    const appStore = useAppStore();

    return {
      appStore
    };
  },
  methods: {
    goToBasket() {
      if (this.appStore.isWorkflow(Workflow.MEAL_PLAN)) {
        this.$navigateToMealPlanConfirmation();
      } else if (this.appStore.isWorkflow(Workflow.ORDER)) {
        this.$navigateToOrderConfirmation();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/css/customizer';

/* Container for the cart button */
.cart-button-container {
  width: 100%;
  position: sticky;
  bottom: 0;
  right: 0;
  justify-items: right;
  /*padding: 0 0 0.5rem 0;*/
  padding-bottom: .5rem;
  overflow: hidden;
  /*width: fit-content;*/
  float: right;
  z-index: 1;
  transition: transform ease-out .2s;
}
.cart-button-container.popin {
  overflow: unset;
}

.cart-button-container.popin .cart-button {
  /*height*/
  transform: translateY(0%); /* shows the button again */
}

/* Cart button styling */
.cart-button {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-color); /* Blue background */
  color: var(--main-color-text);
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 18px;
  width: 100%; /* Full width */
  transition: transform ease-out .2s;
  transform: translateY(150%); /* hides the Button out of sight */
}

/* Icon with the cart and counter */
.cart-icon {
  display: flex;
  align-items: center;
  margin-right: 10px;
  position: relative;
}

.product-counter {
  display: custom-chain(basket-count-display,block);
  background: custom-chain(basket-count-background,#ff0000); /* Red background for the counter */
  color: custom-chain(basket-count-color,var(--main-color-text));
  margin-left: custom-chain(basket-count-gap, basket-gap,5px);

  border-radius: 12px;
  font-size: 14px;
  padding: 1px 9px;
}

.product-counter.counter-small {
  border-radius: 7px;
  font-size: 10px;
  padding: 0 5px;

  position: absolute;
  top: -8px;
  right: -7px;
}

/* Text and price section */
.cart-text {
  display: flex;
  align-items: center;
  font-weight: custom-chain(-basket-text-font-weight);
  font-size: custom-chain(basket-text-font-size,18px);
  white-space: nowrap;
}

.cart-price {
  margin-left: custom-chain(basket-price-gap,basket-gap,5px);
  font-weight: custom-chain(basket-price-font-weight,bold);
  font-size: custom-chain(basket-price-font-size,18px);
}

/* Sticky position for mobile devices */
@media (max-width: 768px) {
  .cart-button {
    /*font-size: 18px;*/
    padding: 15px;
  }

  .cart-icon {
    margin-right: 8px;
  }

  .cart-price {
    /*font-size: 18px;*/
  }
}

/* Desktop view: Centered with fixed width */
@media (min-width: 769px) {
  .cart-button-container {
    width: fit-content;
  }

  .cart-button {
    max-width: 400px;
    padding: 12px 20px;
  }
}
</style>
