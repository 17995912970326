import {apiUtils} from "@/utils/apiUtils";
import {useShopStore} from "@/stores/shop";

export const shopService = {
    async selectShop(shopId) {
      try {
        const shopStore = useShopStore();

        const result = await apiUtils.doGet('/TCPSelectShopJ.action', {
          id: shopId,
          json: "true"
        });

        const { id, custom1, custom2, name, image, custom6 } = result.shop;
        shopStore.setShop(id, custom1, custom2, name, image, custom6);

        return result;
      } catch (error) {
        console.error("Error on selecting a shop:", error);
        throw error;
      }
    },
    async getShops(emptyBasket) {
        try {
            const result = await apiUtils.doGet('/TCPShopsJ.action', {
                json: "true",
                emptyBasket: emptyBasket,
            });

            return result;
        } catch (error) {
            console.error("Error fetching shops:", error);
            throw error;
        }
    }
};
