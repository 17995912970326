<template>
  <div class="meal-planner">
    <div ref="headerSelection" v-collapse:500="hasSelected">
      <div>
        <SwiperDate
          v-model="selectedDate"
          class=""
          style="padding-top:1px;padding-bottom:1px;"
          grouped
          selectable
          continues-elements
          :items-per-view="dayCountShown"
          compose-as-week
          :base-date="selectedDate"
        />
        <Toolbar
          v-if="mealPlans.length > 1"
          :toolbarItems="mealPlans"
          :selected-item-id="getAcutalMealPlanId()"
          @item-click="selectMealPlan"
        />
        <ReservationList
          v-if="selectedMealPlan && selectedMealPlan.reservation"
          :reservation="selectedMealPlan.reservation" @reservation-canceled="handleReservationCanceled()"
        />
      </div>
    </div>
    <!-- Sticky Header for Date, Category -->
    <div v-collapse:500="!hasSelected" class="header-sticky noEars row my-0">
      <div class="d-flex flex-grow-1 flex-nowrap px-3 def-main" ref="headerText">
        <!-- todo insert stuff -->
        <h2 v-if="selectedMealPlan" class="text-left flex-grow-1 noMargin p-1 centerContent" style="font-size: 22px">{{dateFormatted}}, {{ selectedMealPlan.description }}</h2>
        <b-button variant="secondary" class="def-inv-main button mx-1 py-1 m-1" @click="clearCart">
          {{ $t('button.cancel') }}
        </b-button>
      </div>
    </div>
    <div style="margin-bottom:4px;"></div>
    <MealPlan :key="mealPlanKey"
      v-if="selectedDate && selectedMealPlan && !selectedMealPlan.reservation"
      :mealPlan="selectedMealPlan"
      :reservationDateTime="selectedDate"
      ref="mealPlanItem"
      @changed="updateMealPlanCount"
    />
    <div v-else-if="!isLoading && (!selectedMealPlan || (selectedMealPlan && !selectedMealPlan.reservation) )" class="meal-plan-noOrderAvailable">
      <h3>{{$t('text.noMealPlanOrderSelectedDate')}}</h3>
    </div>
    <GoToBasketButton :product-count="getCurrentOrderBasket.productCount" :total-price="getCurrentOrderBasket.totalPrice" :hidden="!hasSelected" v-collapse:100="!hasSelected"/>
  </div>
</template>

<script>
import MealPlan from "@/components/page-components/MealPlanner/MealPlan.vue";
import SwiperDate from "@/components/layout-components/SwiperDate.vue";
import Toolbar from "@/components/layout-components/Toolbar.vue";
import { productService } from "@/services/api/productService";
import Utils from "@/utils/utils";
import ReservationList from "@/components/page-components/MealPlanner/ReservationList.vue";
import GoToBasketButton from "@/components/sub-components/GoToBasketButton.vue";
import {shopService} from "@/services/api/shopService";
import {useMealPlanStore} from "@/stores/mealPlan";
import {basketService} from "@/services/api/basketService";
import {useShopStore} from "@/stores/shop";
import {useAppStore} from "@/stores/app";

export default {
  name: "MealPlanner",
  components: {
    ReservationList,
    SwiperDate,
    Toolbar,
    MealPlan,
    GoToBasketButton,
  },
  data() {
    return {
      selectedDate: this.appStore.currentMealPlanOrderId
        ? this.getValidDate(this.mealPlanStore.getOrder(this.appStore.currentMealPlanOrderId)?.date)
        : new Date(),  // Set selectedDate based on order date or current date
      selectedMealPlan: null,
      mealPlans: [],
      isLoading: false,
      hasMealItemSelected:false,
      mealPlanKey: 0,
    };
  },
  inject:['setPageMetaTitle'],
  setup() {
    const mealPlanStore = useMealPlanStore();
    const shopStore = useShopStore();
    const appStore = useAppStore();

    return {
      mealPlanStore,
      shopStore,
      appStore,
    };
  },
  computed:{
    getCurrentOrderBasket() {
      if (!this.selectedMealPlan || !this.selectedMealPlan.id) {
        console.warn("No valid selected meal plan found.");
        return { productCount: 0, totalPrice: 0.00 }; // Ensure valid structure
      }

      if (!this.appStore.currentMealPlanOrderId) {
        console.warn("No order ID found in appStore.");
        return { productCount: 0, totalPrice: 0.00 };
      }

      const order = this.mealPlanStore.getOrder(this.appStore.currentMealPlanOrderId);
      if (!order) {
        console.warn(`Meal plan order not found for ID: ${this.appStore.currentMealPlanOrderId}`);
        return { productCount: 0, totalPrice: 0.00 }; // Return a valid empty structure
      }

      // Sum up the total quantity of all products
      const totalQuantity = order.products.reduce((sum, product) => sum + (product.quantity || 0), 0);

      return {
        productCount: totalQuantity,  // Total quantity of all products
        totalPrice: order.totalPrice || 0.00, // Total price
      };
    },
    hasSelected(){
      return this.hasMealItemSelected;
    },
    dateFormatted(){
      return this.formatterInstanceFullDate.format(this.selectedDate) // for me : '01.03.21, 14:15'
    },
    dayCountShown(){
      return window.config.MEAL_PLAN.weekDays || 5;
    },
    formatterInstanceFullDate() {
      return Intl.DateTimeFormat(this.$i18n.locale.replace('_', '-'), {formatMatcher: "best fit", weekday: "short", month: "short", day: "numeric"});
    },
  },
  async created() {
    await this.setPageMetaTitle(this.shopStore.getShopName);
    await shopService.selectShop(this.shopStore.getShopId);

    // Restore selection from appStore
    const order = this.mealPlanStore.getOrder(this.appStore.currentMealPlanOrderId);
    if (order) {
      this.selectedDate = new Date(order.date);
      this.selectedMealPlan = this.mealPlans.find(mp => mp.id === order.mealPlanId);

      console.log(`Restored selectedDate: ${this.selectedDate}, MealPlanId: ${order.mealPlanId}`);
    }

    await this.fetchMealPlans(this.selectedDate);
  },
  mounted() {
    if (this.getCurrentOrderBasket.productCount > 0) {
      this.hasMealItemSelected = true;
    }
  },
  methods: {
    forceRerender() {
      this.mealPlanKey += 1;
    },
    getValidDate(date) {
      if (!date) return new Date(); // If `date` is null or undefined, use today's date
      const parsedDate = new Date(date);
      return isNaN(parsedDate.getTime()) ? new Date() : parsedDate;
    },
    onMealPlanSelect(mealPlan) {
      this.selectedMealPlan = mealPlan;
    },
    handleReservationCanceled() {
      this.selectedMealPlan.reservation = null;
      this.makeToast("success", this.$t("notification.cancelOrderSuccessful"));
    },
    getAcutalMealPlanId() {
      if (this.selectedMealPlan) {
        return this.selectedMealPlan.id;
      }

      if (!this.appStore.currentMealPlanOrderId) {
        return null;
      }

      // Try restoring from an existing order if available
      const order = this.mealPlanStore.getOrder(this.appStore.currentMealPlanOrderId);
      if (order) {
        return order.mealPlanId;
      }

      // Fallback: Select the first available meal plan
      if (this.mealPlans.length > 0) {
        return this.mealPlans[0].id;
      }

      return null; // No valid meal plan available
    },
    async fetchMealPlans(date) {
      try {
        this.isLoading = true;

        let mealPlanIdToSelect = this.getAcutalMealPlanId();

        const result = await productService.getMealPlans(Utils.getStartOfDate(date), Utils.getEndOfDate(date));
        this.mealPlans = result.mealPlans || [];

        if (this.mealPlans.length > 0) {
          if(mealPlanIdToSelect > 0)
          {
            const selectedMealPlan = this.mealPlans.find(plan => plan.id === mealPlanIdToSelect);
            if(selectedMealPlan)
            {
              this.onMealPlanSelect(selectedMealPlan)
            }
            else
            {
              this.onMealPlanSelect(this.mealPlans[0]);
            }
          }
          else
          {
            this.onMealPlanSelect(this.mealPlans[0]);
          }
        } else {
          this.selectedMealPlan = null; // No meal plans available
        }
      } catch (error) {
        console.error("Failed to fetch meal plans:", error);
      } finally {
        this.isLoading = false;
      }
    },
    selectMealPlan(index) {
      // Ensure the index is valid
      if (index >= 0 && index < this.mealPlans.length) {
        this.onMealPlanSelect(this.mealPlans[index]);
      } else {
        console.error("Invalid meal plan index:", index);
      }
    },
    updateMealPlanCount(newCount){
      this.hasMealItemSelected = newCount > 0;
    },
    clearCart(){
      this.mealPlanStore.remove(this.appStore.currentMealPlanOrderId);
      this.hasMealItemSelected = false;
      basketService.emptyBasket();
      this.forceRerender();
    },
  },
  watch: {
    selectedDate: {
      immediate: false,
      handler(newDate, oldDate) {
        if (newDate instanceof Date && !isNaN(newDate)) {
          if (!oldDate || !(oldDate instanceof Date) || isNaN(oldDate) || newDate.getTime() !== oldDate.getTime()) {
            this.fetchMealPlans(newDate);  // Fetch meal plans whenever date changes
            console.log("date changed");
          }
        }
      },
    },
    selectedMealPlan: {
      immediate: true,
      handler(newMealPlan) {
        if (newMealPlan) {
          this.updateMealPlanCount(this.getCurrentOrderBasket.productCount);
        }
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import '@/css/customizer';

.meal-planner {
  text-align: center;
}

.meal-plan-noOrderAvailable {
  color: custom-chain(mealplan-noorder-color,red);
  padding-top: 4%;
}
</style>
