<template>
    <div style="padding-left: 15px; padding-right: 15px;">

      <div>
        <b-container fluid>
          <b-form @submit.stop.prevent>
            <b-form-row>
              <b-col cols="12" md="6" v-show="config.REGISTER_CHILD.FIELDS.firstName.visible">
                <b-form-group class="form-group fontField" label-for="firstName">
                  <template v-slot:label>
                    {{ $t('field.firstName') }} <span v-if="config.REGISTER_CHILD.FIELDS.firstName.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg"
                                id="firstName" name="firstName"
                                type="text"
                                v-model="$v.form.firstName.$model"
                                :state="validateState('firstName')"
                                aria-describedby="firstName-feedback"
                                :maxLength="config.REGISTER_CHILD.FIELDS.firstName.maxLength" />
                  <b-form-invalid-feedback class="text-left" id="firstName-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" v-show="config.REGISTER_CHILD.FIELDS.lastName.visible">
                <b-form-group class="form-group fontField" label-for="lastName">
                  <template v-slot:label>
                    {{ $t('field.lastName') }} <span v-if="config.REGISTER_CHILD.FIELDS.lastName.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg"
                                id="lastName"
                                name="lastName"
                                type="text"
                                v-model="$v.form.lastName.$model"
                                :state="validateState('lastName')"
                                aria-describedby="lastName-feedback"
                                :maxLength="config.REGISTER_CHILD.FIELDS.lastName.maxLength" />
                  <b-form-invalid-feedback class="text-left" id="lastName-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.REGISTER_CHILD.FIELDS.emailAddr.visible">
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="emailAddr">
                  <template v-slot:label>
                    {{ $t('field.email') }} <span v-if="config.REGISTER_CHILD.FIELDS.emailAddr.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="emailAddr" name="emailAddr" type="email" v-model="$v.form.emailAddr.$model"
                                :state="validateState('emailAddr')" aria-describedby="emailAddr-feedback" :maxLength="config.REGISTER_CHILD.FIELDS.emailAddr.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="emailAddr-feedback">{{ $t('validation.invalidEmail') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col cols="12" md="6" v-show="config.REGISTER_CHILD.FIELDS.cardNumber.visible">
                <b-form-group class="form-group fontField" label-for="cardNumber">
                  <template v-slot:label>
                    {{ $t('field.cardNumber') }} <span v-if="config.REGISTER_CHILD.FIELDS.cardNumber.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="cardNumber" name="cardNumber" type="text" v-model="$v.form.cardNumber.$model"
                                :state="validateState('cardNumber')" aria-describedby="cardNumber-feedback" :maxLength="config.REGISTER_CHILD.FIELDS.cardNumber.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="cardNumber-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" v-show="config.REGISTER_CHILD.FIELDS.customerCode.visible">
                <b-form-group class="form-group fontField" label-for="customerCode">
                  <template v-slot:label>
                    {{ $t('field.customerCode') }} <span v-if="config.REGISTER_CHILD.FIELDS.customerCode.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="customerCode" name="customerCode" type="text" v-model="$v.form.customerCode.$model"
                                :state="validateState('customerCode')" aria-describedby="customerCode-feedback" :maxLength="config.REGISTER_CHILD.FIELDS.customerCode.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="customerCode-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col cols="12" md="6" v-show="config.REGISTER_CHILD.FIELDS.password.visible">
                <b-form-group class="form-group fontField" label-for="password">
                  <template v-slot:label>
                    {{ $t('field.password') }} <span v-if="config.REGISTER_CHILD.FIELDS.password.required" class="text-danger">*</span>
                  </template>
                  <b-input-group>
                    <b-form-input v-if="hidePassword" class="form-control form-control-lg" id="password" name="password" type="password" v-model="$v.form.password.$model"
                                  :state="validateState('password')"
                                  aria-describedby="password-feedback" />
                    <b-form-input v-else class="form-control form-control-lg" id="password" name="password" type="text" v-model="$v.form.password.$model"
                                  :state="validateState('password')"
                                  aria-describedby="password-feedback" />
                    <b-form-invalid-feedback class="text-left order-last" v-if="form.password && !$v.form.password.valid" id="password-feedback">{{ $t('validation.passwordPolicy') }}</b-form-invalid-feedback>
                    <b-input-group-append>
                      <b-button v-if="config.SHOW_PASSWORD_VISIBILITY_SWITCH" variant="outline-secondary" type="button" @click="showPassword()" :aria-label="hidePassword ? $t('ariaLabel.showPasswordNew') : $t('ariaLabel.hidePasswordNew')">
                        <b-icon-eye-fill v-if="hidePassword" aria-hidden="true" :aria-label="$t('ariaLabel.showPasswordNew')"></b-icon-eye-fill>
                        <b-icon-eye-slash-fill v-else aria-hidden="true" :aria-label="$t('ariaLabel.hidePasswordNew')"></b-icon-eye-slash-fill>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" v-show="config.REGISTER_CHILD.FIELDS.passwordRepeat.visible">
                <b-form-group class="form-group fontField" label-for="passwordRepeat">
                  <template v-slot:label>
                    {{ $t('field.passwordRepeat') }} <span v-if="config.REGISTER_CHILD.FIELDS.passwordRepeat.required" class="text-danger">*</span>
                  </template>
                  <b-input-group>
                    <b-form-input v-if="hidePasswordRepeat" class="form-control form-control-lg" id="passwordRepeat" name="passwordRepeat" type="password" v-model="$v.form.passwordRepeat.$model"
                                  :state="validateState('passwordRepeat')"
                                  aria-describedby="passwordRepeat-feedback"
                                  :minLength="config.REGISTER_CHILD.FIELDS.passwordRepeat.minLength"
                                  :maxLength="config.REGISTER_CHILD.FIELDS.passwordRepeat.maxLength"/>
                    <b-form-input v-else class="form-control form-control-lg" id="passwordRepeat" name="passwordRepeat" type="text" v-model="$v.form.passwordRepeat.$model"
                                  :state="validateState('passwordRepeat')"
                                  aria-describedby="passwordRepeat-feedback"
                                  :minLength="config.REGISTER_CHILD.FIELDS.passwordRepeat.minLength"
                                  :maxLength="config.REGISTER_CHILD.FIELDS.passwordRepeat.maxLength" />
                    <b-form-invalid-feedback class="text-left order-last" id="passwordRepeat-feedback">{{ $t('validation.fieldWarningPasswordEqual') }}</b-form-invalid-feedback>
                    <b-input-group-append>
                      <b-button v-if="config.SHOW_PASSWORD_VISIBILITY_SWITCH" variant="outline-secondary" type="button" @click="showPasswordRepeat()" :aria-label="hidePasswordRepeat ? $t('ariaLabel.showPasswordRepeat') : $t('ariaLabel.hidePasswordRepeat')">
                        <b-icon-eye-fill v-if="hidePasswordRepeat" aria-hidden="true" :aria-label="$t('ariaLabel.showPasswordRepeat')"></b-icon-eye-fill>
                        <b-icon-eye-slash-fill v-else aria-hidden="true" :aria-label="$t('ariaLabel.hidePasswordRepeat')"></b-icon-eye-slash-fill>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.REGISTER_CHILD.FIELDS.streetAddress.visible">
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="streetAddress">
                  <template v-slot:label>
                    {{ $t('field.street') }} <span v-if="config.REGISTER_CHILD.FIELDS.streetAddress.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg"
                                id="streetAddress"
                                name="streetAddress" type="text"
                                v-model="$v.form.streetAddress.$model"
                                :state="validateState('streetAddress')"
                                aria-describedby="streetAddress-feedback"
                                :maxLength="config.REGISTER_CHILD.FIELDS.streetAddress.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="streetAddress-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col cols="12" md="6" v-show="config.REGISTER_CHILD.FIELDS.postcode.visible">
                <b-form-group class="form-group fontField" label-for="postcode">
                  <template v-slot:label>
                    {{ $t('field.postalCode') }} <span v-if="config.REGISTER_CHILD.FIELDS.postcode.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg"
                                id="postcode"
                                name="postcode"
                                type="text"
                                v-model="$v.form.postcode.$model"
                                :state="validateState('postcode')"
                                aria-describedby="postcode-feedback"
                                :maxLength="config.REGISTER_CHILD.FIELDS.postcode.maxLength"/>
                  <b-form-invalid-feedback class="text-left" id="postcode-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6" v-show="config.REGISTER_CHILD.FIELDS.city.visible">
                <b-form-group class="form-group fontField" label-for="city">
                  <template v-slot:label>
                    {{ $t('field.city') }} <span v-if="config.REGISTER_CHILD.FIELDS.city.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg"
                                id="city"
                                name="city"
                                type="text"
                                v-model="$v.form.city.$model"
                                :state="validateState('city')"
                                aria-describedby="city-feedback"
                                :maxLength="config.REGISTER_CHILD.FIELDS.city.maxLength" />
                  <b-form-invalid-feedback class="text-left" id="city-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.REGISTER_CHILD.FIELDS.countryId.visible">
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="countryId">
                  <template v-slot:label>
                    {{ $t('field.country') }} <span v-if="config.REGISTER_CHILD.FIELDS.countryId.required" class="text-danger">*</span>
                  </template>
                  <select-picker-country
                      id="customerCountry"
                      name="customerCountry"
                      class="countrySelect"
                      v-if="countriesLoaded"
                      :options="countries"
                      :callback="selectCountry"
                      :preselectedId="selectedCountryId"
                      aria-describedby="countryId-feedback"
                      :state="validateState('countryId')" />
                  <b-form-invalid-feedback class="text-left" id="countryId-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row v-show="config.REGISTER_CHILD.FIELDS.telephoneNumber.visible">
              <b-col cols="12" md="6">
                <b-form-group class="form-group fontField" label-for="telephoneNumber">
                  <template v-slot:label>
                    {{ $t('field.telephoneNumber') }} <span v-if="config.REGISTER_CHILD.FIELDS.telephoneNumber.required" class="text-danger">*</span>
                  </template>
                  <b-form-input class="form-control form-control-lg" id="telephoneNumber" name="telephoneNumber" type="text" v-model="$v.form.telephoneNumber.$model"
                                :state="validateState('telephoneNumber')" aria-describedby="telephoneNumber-feedback"/>
                  <b-form-invalid-feedback class="text-left" id="telephoneNumber-feedback">{{ $t('validation.fieldWarningRequired') }}</b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col cols="12" md="12">
                  <i18n class="text-left" path="text.acceptTermsAndPrivacy" tag="p">
                    <template v-slot:terms>
                      <a :href="config.COMPANY.links.privacy" target="_blank">{{ $t('link.terms') }}</a>
                    </template>
                    <template v-slot:privacy>
                      <a :href="config.COMPANY.links.terms" target="_blank">{{ $t('link.privacy') }}</a>
                    </template>
                  </i18n>
              </b-col>
            </b-form-row>

            <b-form-row cols="1">
                <b-button type="submit" @click.stop="onClickCaptcha" :disabled="$v.$invalid" class="btn btn-primary btn-lg btn-block btnSubmit g-recaptcha">{{ $t('button.submit') }}</b-button>
            </b-form-row>
          </b-form>
        </b-container>
      </div>
    </div>
</template>

<script>
import {sameAs, email, requiredIf} from 'vuelidate/lib/validators'
import {en, de, fr} from 'vuejs-datepicker/dist/locale'
import {BIconEyeFill, BIconEyeSlashFill} from "bootstrap-vue";
import selectPickerCountry from '../sub-components/SelectPickerCountry.vue'
import {accountService} from "../../services/api/accountService";

export default {
    data() {
        return {
          form: {
            firstName: undefined,
            lastName: undefined,
            streetAddress: undefined,
            postcode: undefined,
            city: undefined,
            countryId: undefined,
            telephoneNumber: undefined,
            emailAddr: undefined,
            cardNumber: undefined,
            customerCode: undefined,
            password: undefined,
            passwordRepeat: undefined,
          },
            isLoading:false,
            en: en,
            de: de,
            fr: fr,
            countriesLoaded: false,
            countries: {
              Type: Array
            },
            selectedCountry: null,
            selectedCountryId: 0,
            responseToken: "",
            hidePassword: true,
            hidePasswordRepeat: true,
            submit: false,
        }
	},
    computed: {
        config() {
            return window.config;
        },
    },
    components:{
      BIconEyeFill, BIconEyeSlashFill,
      'select-picker-country': selectPickerCountry,
    },
    validations: { // STANDARD
        form: {
            firstName: {
              required: requiredIf(() => window.config.REGISTER_CHILD.FIELDS.firstName.required),
            },
            lastName:{
              required: requiredIf(() => window.config.REGISTER_CHILD.FIELDS.lastName.required),
            },
            streetAddress:{
              required: requiredIf(() => window.config.REGISTER_CHILD.FIELDS.streetAddress.required),
            },
            postcode:{
              required: requiredIf(() => window.config.REGISTER_CHILD.FIELDS.postcode.required),
            },
            city:{
              required: requiredIf(() => window.config.REGISTER_CHILD.FIELDS.city.required),
            },
            countryId:{
              required: requiredIf(() => window.config.REGISTER_CHILD.FIELDS.countryId.required),
            },
            telephoneNumber:{
              required: requiredIf(() => window.config.REGISTER_CHILD.FIELDS.telephoneNumber.required),
            },
            emailAddr:{
              required: requiredIf(() => window.config.REGISTER_CHILD.FIELDS.emailAddr.required),
              email,
            },
            cardNumber:{
              required: requiredIf(() => window.config.REGISTER_CHILD.FIELDS.cardNumber.required),
            },
            customerCode: {
              required: requiredIf(() => window.config.REGISTER_CHILD.FIELDS.customerCode.required),
            },
            password: {
              required: requiredIf(() => window.config.REGISTER_CHILD.FIELDS.password.required),
              valid(value) {
                const containsUppercase = /[A-Z]/.test(value);
                const containsLowercase = /[a-z]/.test(value);
                const containsNumber = /[0-9]/.test(value);
                const containsSpecial = /[#?!@$%^&*-]/.test(value);
                const hasMinLength = value.length >= this.config.REGISTER_CHILD.FIELDS.password.minLength;
                const hasMaxLength = value.length <= this.config.REGISTER_CHILD.FIELDS.password.maxLength;
                return (
                    containsUppercase &&
                    containsLowercase &&
                    containsNumber &&
                    containsSpecial &&
                    hasMinLength &&
                    hasMaxLength
                );
              },
            },
            passwordRepeat: {
              required: requiredIf(() => window.config.REGISTER_CHILD.FIELDS.passwordRepeat.required),
              sameAsPassword : sameAs('password')
            },
        }
    },
    methods: {
        validateState(name) {
          const {$dirty, $error} = this.$v.form[name];
          return $dirty ? !$error : null;
        },
          // eslint-disable-next-line no-undef
        onClickCaptcha(e) {
            this.submit = true;
            console.log("onClickCaptcha");
            e.preventDefault();
            if(window.config.G_CAPTCHA.enabled == true)
            {
                // eslint-disable-next-line no-undef
                grecaptcha.ready(function() {
                    // eslint-disable-next-line no-undef
                    grecaptcha.execute(window.config.G_CAPTCHA.siteKey, {action: 'submit'}).then(function(token) {
                        this.responseToken = token;
                        this.onSubmit(e);
                    });
                });
            }
            else
            {
                this.onSubmit(e);
            }
        },
        showPassword() {
            this.hidePassword = !this.hidePassword;
        },
        showPasswordRepeat() {
          this.hidePasswordRepeat = !this.hidePasswordRepeat;
        },
        selectCountry(countryId) {
            this.form.countryId = countryId;
        },
        async onSubmit() {
          this.$v.$touch();

          if (this.$v.$invalid) {
            this.makeToast('danger', this.$t('notification.fillAllRequiredFields'));
            return;
          }

          const result = await accountService.register(this.responseToken, '1', this.form.gender, this.form.firstName, this.form.lastName,
            this.form.emailAddr, this.form.cardNumber, this.form.customerCode, this.form.streetAddress, this.form.postcode, this.form.city, this.form.countryId,
            this.form.telephoneNumber, undefined, undefined, undefined, undefined, undefined, this.form.password, false);

          if (result.errorCode > 0) {
            if (result.errorCode == 10) // password does confine to everything needed (length, special characters, numbers, ...)
            {
              this.makeToast('danger', this.$t('notification.passwordPolicy'));
            } else {
              this.makeToast('danger', result.errorMsg);
            }
            return;
          }
          else if (result.exception !== undefined) {
            this.makeToast('danger', result.exception.msg);
            return;
          }

          setTimeout(() => {
            this.makeToast('success', this.$t('notification.accountCreated'));
          }, 300);

          this.$navigateToStudentAccounts(true);
        },
    },
    async created(){
      const result = await accountService.getCountries(1);

      this.countries = result.countries;
      if (result.selectedCountry != undefined) {
        this.selectedCountry = result.selectedCountry;
        this.form.countryId = result.selectedCountry.id;
        this.selectedCountryId = result.selectedCountry.id;
      } else {
        if (this.countries.length > 0)
          this.selectCountry(this.countries[0].id);
      }

      this.countriesLoaded = true;
    },
}
</script>

<style scoped>
    input[type="password"]::-ms-reveal {
      display: none;
    }
</style>
