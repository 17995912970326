import $ from 'jquery';

export default {
  NAME: 'EffectsPlugin',
  install(VueInstance){
    VueInstance.directive('collapse',(function(self){
      return Object.assign(self,{
        evalState(binding){
          return binding.value;
        },
        evalSpeed(binding){
          // .filter(a=>!isNaN(a-0))[0]
          return !isNaN(binding.arg-0)?binding.arg-0:500;
        },
        getSize(jQel){
          let size = 0;
          let prev = jQel.attr("style");
          jQel.css({position:'absolute','z-index':-1,width:'inherit',height:''});
          size=jQel.innerHeight();
          jQel.attr("style",prev);
          return size;
        },
        setOpen(jQel){
          jQel.css({height:'',display:'','overflow-y':''}).attr({'aria-hidden':false})
        },
        setClosed(jQel){
          jQel.css({height:0,display:'none','overflow-y':'hidden'}).attr({'aria-hidden':true});
        },
        doOpen(jQel,dur=750){
          let size = self.getSize(jQel);
          jQel.css({display:'','overflow-y':'hidden',height:'1px'}).animate({display:'',height:size},dur,self.setOpen.bind(self,jQel));
        },
        doClose(jQel,dur=750) {
          jQel.css({display:'','overflow-y':'hidden'}).animate({display:'',height:0},dur,self.setClosed.bind(self,jQel))
        },
        created(el,binding) { /* Vue 3 */
          const jQel = $(el);
          if (self.evalState(binding))
            self.setClosed(jQel);
          else
            self.setOpen(jQel);
        },
        mounted(el,binding){ /* Vue 3 */
          self.created(el,binding);
        },
        updated(el, binding){ /* Vue 3 */
          if (binding.value === binding.oldValue){
            return;
          }
          const jQel = $(el);
          if (self.evalState(binding))
            self.doClose(jQel,self.evalSpeed(binding));
          else
            self.doOpen(jQel,self.evalSpeed(binding));
        },
        bind(){self.created(...arguments);},/* Vue 2 */
        inserted(){self.mounted(...arguments);},/* Vue 2 */
        componentUpdated(){/*self.update(...arguments);*/}, /* Vue 2 */
        update(el,binding) {
          if (binding.oldValue !== binding.value)
            self.updated(el,binding);
        },/* Vue 2 */
      });
    })({}));
  }
}
