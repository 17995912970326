<template>
    <div class="creditCardPaymentWrapper" id="credit-card-payment">

        <loading :active.sync="isLoading"
            :can-cancel="true"
            :is-full-page="true"
            loader='bars'></loading>

        <modal ref="externalPaymentModal">
            <template v-slot:header>
            </template>

            <template v-slot:body>
                <div class="externalPaymentModalBody">
                    <iframe id="externalPaymentIframe" style="height:600px; max-height: 700px;border: none; margin-top: 20px;" :src="redirectUrl"></iframe>
                </div>
            </template>

            <template v-slot:footer>
            </template>
        </modal>

        <div class="container" id="payoneCheckoutContainer">
            <div class="column">
                <section id="checkout" class="payone-checkout">
                <form>
                    <section class="payone-checkout__fields" id="payoneCheckoutFields"></section>
                    <div id="payoneCheckoutLastRow" class="payone-checkout__row">
                    <b-button class="payone-checkout__submit-btn" id="checkoutSubmitButton" >Pay</b-button>
                    </div>
                </form>
                </section>
            </div>
        </div>

        <div class="container">
            <div class="column">
                <section class="response" id="creditCardCheckResponse"></section>
            </div>

            <div class="column">
                <section class="response" id="paymentResponse"></section>
            </div>
            </div>

            <div class="container">
            <div class="column">
                <section class="response" id="transactionStatusNotification"></section>
            </div>
        </div>

        <!-- <div class="" v-html="formHTML">

        </div> -->

        <!-- <pay-one v-show="!isLoading"></pay-one> -->
        <!-- <form name="paymentform">

            <fieldset>
                <input type="hidden" name="pseudocardpan" id="pseudocardpan">
                <input type="hidden" name="truncatedcardpan" id="truncatedcardpan">


                <label for="cardtypeInput">Card type:</label>
                <span id="cardtype" class="inputIframe"></span>

                <label for="cardpanInput">Cardpan:</label>
                <span id="cardpan" class="inputIframe"></span>
                <div name="cardpan"></div>


                <label for="cvcInput">CVC:</label>
                <span id="cardcvc2" class="inputIframe"></span>

                <label for="expireInput">Expire Date:</label>
                <span id="expireInput" class="inputIframe">
                    <span id="cardexpiremonth"></span>
                    <span id="cardexpireyear"></span>
                </span>

                <label for="firstnameInput">Firstname:</label>
                <input id="firstname" type="text" name="firstname" :value="firstName">

                <label for="lastnameInput">Lastname:</label>
                <input id="lastname" type="text" name="lastname" :value="lastName">

                <div id="errorOutput"></div>

                <input id="paymentsubmit" type="button" onclick="window.check();">
            </fieldset>
        </form>

        <div id="paymentform"></div> -->

    </div>
</template>


    <script type="text/javascript; charset=UTF-8" src="https://secure.pay1.de/client-api/js/v1/payone_hosted.js" defer></script>
    <script type="text/javascript; charset=UTF-8" src="../../../src/payone/payone_hostedpage/PayoneCheckout.js" defer></script>
    <script type="text/javascript; charset=UTF-8" src="../../../src/payone/payone_hostedpage/helpers.js" defer></script>
    <script type="text/javascript; charset=UTF-8" src="../../../src/payone/payone_hostedpage/main.js" defer></script>

<script>
import VueLoading from 'vue-loading-overlay'
import Modal from '../sub-components/Modal.vue'
import {PaymentProviders} from "@/constants/constants";
import {paymentService} from "@/services/api/paymentService";

export default {
    data(){
        return {
            dataLoaded: false,
            externalUrl : "",
            isLoading: true,
            vaultedCards: null,
            showSaferpayFrame: false,
            noVaultedCards: true,
            creditCardId: "",
            showVaultedCardsNotation: false,
            transactionInterface: false,
            formHTML: "",
            lastName:"",
            firstName:"",
            aid:"",
            mode:"",
            mid:"",
            portalId:"",
            hash: "",
            truncatedcardpan: "",
            pseudocardpan: "",
            redirectUrl: "",
        }
    },
    props: {
        subCode: {
            Type: String
        },
        callback: {
          Type: Function
        }
    },
    computed:{
        config(){
            return window.config;
        },
    },
    methods: {
        initPayOne(){

// Helper constants for colors
const COLOR_GREEN = 'green'
const COLOR_GREENISH = '#008000a6'
const COLOR_RED = '#a42e2e'

// ------- Payone related helpers -------
function showMessageInCheckout(paymentResponse) {
  const checkoutForm = document.querySelector('form')
  checkoutForm.insertAdjacentHTML('beforeend', `<section class="message-container" id="messageContainer"></section>`)

  const messageContainer = document.querySelector('#messageContainer')

  switch (paymentResponse.status) {
    case 'success':
      messageContainer.style.color = COLOR_GREEN
      break

    case 'needs_redirect':
      messageContainer.style.color = COLOR_GREENISH
      break

    case 'error':
    default:
      messageContainer.style.color = COLOR_RED
  }

  messageContainer.innerText = paymentResponse.message
}

function insertGoToPaymentPageLink(redirectUrl) {
  const paymentResponseContainer = document.querySelector('#paymentResponse')
  paymentResponseContainer.insertAdjacentHTML('beforeend', `
    <div class="go-to-payment-page-link-container">
      <a
        class="go-to-payment-page-link js-go-to-payment-page-link--hidden"
        id="goToPaymentPageLink"
      >Go to payment page <span class="go-to-payment-page-link__arrow">➜</span>
      </a>
    </div>
  `)

  const goToPaymentPageLink = document.querySelector('#goToPaymentPageLink')
  goToPaymentPageLink.classList.remove('js-go-to-payment-page-link--hidden')
  goToPaymentPageLink.href = redirectUrl
}

function showMessageInCheckoutAfterRedirect(message, color) {
  const checkoutForm = document.querySelector('form')
  checkoutForm.insertAdjacentHTML('beforeend', `<section class="message-container" id="messageContainer"></section>`)

  const messageContainer = document.querySelector('#messageContainer')

  if (color === 'green') {
    messageContainer.style.color = COLOR_GREEN
  }
  if (color === 'greenish') {
    messageContainer.style.color = COLOR_GREENISH
  }
  if (color === 'red') {
    messageContainer.style.color = COLOR_RED
  }

  messageContainer.innerText = message
}

function showPayoneExampleTransactionStatusNotification(paymentMethod) {
  // If user hasn't clicked a payment method card, then it's the default 'card' payment method
  if (paymentMethod === null) {
    paymentMethod = 'card'
  }

//   fetch(`_example-payone-transaction-statuses/${paymentMethod}.json`)
//     .then(response => response.json())
//     .then((response) => {
//       const lastPaymentMethodSpecificData = JSON.parse(localStorage.getItem('lastPaymentMethodSpecificData'))
//       response.lastname = lastPaymentMethodSpecificData.surname
//       response.country = lastPaymentMethodSpecificData.country
//       if (paymentMethod === 'card') {
//         response.cardexpiredate = lastPaymentMethodSpecificData.cardExpireDate
//         response.cardtype = lastPaymentMethodSpecificData.cardType
//         response.cardpan = lastPaymentMethodSpecificData.truncatedCardPAN
//       }
//       if (paymentMethod === 'paydirekt') {
//         response.firstname = lastPaymentMethodSpecificData.firstName
//         response.lastname = lastPaymentMethodSpecificData.surname
//         response.zip = lastPaymentMethodSpecificData.zip
//         response.city = lastPaymentMethodSpecificData.city
//       }

//       const container = document.querySelector('#transactionStatusNotification')

//       container.innerHTML = `
// <div class="response-heading-container">
//   <h2 style="text-align: center;">Payone example transaction status notification message</h2>
// </div>
// <div class="response__description-container">
//   <p>
//     Your app would receive a message like this in the future:
//   </p>
// </div>
// <pre class="response-text" style="color: ${COLOR_GREEN}">
// ${JSON.stringify(response, null, 2)}
// </pre>
// `
//     }
//    )
}

function showCreditCardCheckResponse(creditCardCheckResponse) {
  if (creditCardCheckResponse === undefined) return

  const container = document.querySelector('#creditCardCheckResponse')

  let color = ''
  switch (creditCardCheckResponse.status) {
    case 'VALID':
      color = 'green'
      break

    case 'INVALID':
    case 'ERROR':
    default:
      color = '#a42e2e'
  }

  container.innerHTML = `
<div class="response-heading-container">
  <h2 class="response-heading" style="text-align: center;">Creditcard check</h2>
</div>
<div class="response__description-container">
  <p>
    This is the output of the tokenization step:
  </p>
</div>
<pre class="response-text" style="color: ${color}">
${JSON.stringify(creditCardCheckResponse, null, 2)}
</pre>
`
}

function showPaymentResponse(response) {
  const container = document.querySelector('#paymentResponse')

  let color = ''
  switch (response.status) {
    case 'success':
      color = COLOR_GREEN
      break

    case 'needs_redirect':
      color = COLOR_GREENISH
      break

    case 'error':
    default:
      color = COLOR_RED
  }

  container.innerHTML = `
<div class="response-heading-container">
  <h2 class="response-heading" style="text-align: center;">Payment response</h2>
</div>
${response.status === 'needs_redirect' ?
  `<div class="response__description-container">
    <p>
      This payment method needs a redirect.
    </p>
  </div>`
  : ''}
<pre class="response-text" style="color: ${color}">
${JSON.stringify(response, null, 2)}
</pre>
`
}

// ------- UI helpers -------
function setCheckoutLoading(isLoading = true) {
  const submitButton = document.querySelector('#checkoutSubmitButton')

  if (isLoading === true) {
    submitButton.disabled = true
    submitButton.innerHTML = '<div class="lds-ring"><div></div><div></div><div></div><div></div></div>'
  } else {
    submitButton.disabled = false
    submitButton.innerHTML = 'Pay'
  }
}


            class PayoneCheckout {
  _apiCredentials
  _apiMode
  _style
  _supportedCardTypes
  _checkout
  _container
  _creditCardCheckResponse

  paymentMethod = 'card' // default payment method

  constructor(config) {
    this._apiCredentials = config.apiCredentials;
    this._apiMode = config.apiMode ?? 'test';
    this._style = config.style;
    this._supportedCardTypes = config.supportedCardTypes ?? ['V', 'M', 'A']

    // Payone's hosted JS, in order to invoke handler for successful credit card check, expects string name of a global function.
    // That's why we need to make the handler global. In this case, name of the handler is 'storeCreditCardCheckResponse'
    window.storeCreditCardCheckResponse = this._storeCreditCardCheckResponse.bind(this)
  }

  mount(selector) {
      console.log("MOUNT STARTED")
    const container = document.querySelector(selector)
    this._container = container
    if (this._container === null) throw new Error(`Couldn't mount to selector '${selector}'. Provide valid selector for the checkout container`)

    // Insert <style> tag with necessary styles for the checkout
    document.head.insertAdjacentHTML("beforeend", `
<style>
.payone-checkout__payment-methods-line {
  display: flex;
}

.payone-checkout__payment-method-card {
  margin-right: 20px;
  padding: 25px 30px;
  background-color: transparent;
  border: 1px solid lightgray;
  border-radius: 5px;
  cursor: pointer;
}
.payone-checkout__payment-method-card:focus {
  outline: none;
  border-color: #0969da;
  box-shadow: rgba(9, 105, 218, 0.5) 0 0 0 3px;
}
.payone-checkout__payment-method-card:last-child {
  margin-right: 0;
}
.payone-js-checkout__payment-method-card--chosen {
  box-shadow: rgba(9, 105, 218, 0.5) 0 0 0 3px;
}


/** Checkout **/
.payone-checkout {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  color: #141414;
  width: 400px;
  background-color: white;
  padding: 13px 31px;
  border-radius: 4px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* Card types line */
.payone-checkout__card-types-line {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

.payone-checkout__card {
  margin-right: 15px;
  padding: 2px;
}
.payone-checkout__card-visa {
  height: 20px;
}
.payone-checkout__card:last-child {
  margin-right: 0;
}

.payone-js-checkout__card--highlighted {
  outline: 2px solid cornflowerblue;
}

/* Checkout row */
.payone-checkout__row {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.payone-checkout__row:last-child {
  margin-bottom: 0;
}
.payone-checkout__row:only-child {
  margin-bottom: 0;
}

.payone-checkout__fields {
  margin-bottom: 20px;
}

.payone-checkout__row label {
  margin-bottom: 6px;
}

.payone-checkout__row input {
  border: 1px solid lightgray;
  border-radius: 5px;
  font-size: 18px;
  box-sizing: border-box;
  padding: 8px 10px;
}
.payone-checkout__row input:focus {
  border-color: #0969da;
  outline: none;
}

/* Checkout row inner */
.payone-checkout__row-inner {
  display: flex;
  justify-content: space-between;
}

.payone-checkout__row-inner .payone-checkout__col input {
  width: 185px;
}

/* Checkout column */
.payone-checkout__col {
  display: flex;
  flex-direction: column;
}

.payone-checkout__col label {
  font-size: 14px;
}

/* Checkout submit button */
.payone-checkout__submit-btn {
  border: none;
  cursor: pointer;
  padding: 9px 12px;
  background-color: #1f9cd0;
  color: white;
  border-radius: 4px;
  font-size: 18px;
}
.payone-checkout__submit-btn:hover {
  background-color: rgba(35, 165, 220, 0.95);
}
.payone-checkout__submit-btn:focus {
  border-color: #0969da;
  outline: none;
  box-shadow: rgba(9, 105, 218, 0.3) 0 0 0 3px;
}
.payone-checkout__submit-btn:disabled {
  cursor: not-allowed;
  background-color: rgba(35, 165, 220, 0.47);
}

/* Checkout select */
.payone-checkout select {
  width: 185px;
  box-sizing: border-box;
  padding: 8px 10px;
  border-radius: 5px;
  font-size: 17px;
  /* Specify this for Firefox */
  border: 1px solid lightgray;
  background-color: white;
}
.payone-checkout select:focus {
  border: 1px solid #0969da;
}
.payone-checkout select:focus-visible {
  border-color: #0969da;
  outline: none;
}

/* Message container */
.message-container {
  text-align: center;
  margin-top: 7px;
}

/* Related to iframes */
iframe {
  width: 100%;
  max-height: 39px;
}

.payone-checkout__row-inner .payone-checkout__col section {
  width: 185px;
}

/* Loader */
.lds-ring {
  display: inline-block;
  position: relative;

  /* Commented with and height and added top and left for proper positioning */
  /*width: 80px;*/
  /*height: 80px;*/

  top: -28px;
  left: -26px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 8px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>`
    )

    // Render the HTML
    this._container.innerHTML = `
      <div class="payone-checkout__row" id="payoneCheckoutRowCard">
        <label for="cardNumber">Card number</label>
        <section id="cardNumber"></section>
        <section id="checkoutCardTypesLine" class="payone-checkout__card-types-line"></section>
      </div>

      <div class="payone-checkout__row" id="payoneCheckoutRowCard">
        <div class="payone-checkout__row-inner">
          <div class="payone-checkout__col">
            <label for="cardExpiryMonth">Expire month</label>
            <section id="cardExpiryMonth"></section>
          </div>

          <div class="payone-checkout__col">
            <label for="cardexpireyear">Expire year</label>
            <section id="cardexpireyear"></section>
          </div>
        </div>
      </div>

      <div class="payone-checkout__row" id="payoneCheckoutRowCard">
        <div class="payone-checkout__row-inner">
          <div class="payone-checkout__col">
            <label for="cardcvc2">CVC</label>
            <section id="cardcvc2"></section>
          </div>
        </div>
      </div>

      <div class="payone-checkout__row" id="payoneCheckoutRowCard">
        <label for="surname">Surname</label>
        <input required maxlength="50" name="surname">
      </div>
    <div class="payone-checkout__row" id="payoneCheckoutRowCard">
        <label for="firstname">Firstname</label>
        <input required maxlength="50" name="firstname">
      </div>
    `

    // Render supported card types
    const cardTypesLine = this._container.querySelector('#checkoutCardTypesLine')
    cardTypesLine.innerHTML = this._supportedCardTypes.reduce((acc, type) => (
      acc + `<img class="payone-checkout__card ${type === 'V' ? 'payone-checkout__card-visa' : ''}" data-card-type="${type}" src="https://cdn.pay1.de/cc/${type.toLowerCase()}/s/transparent.png" alt="${type} card icon">`
    ), '')


var configPayOne, requestPayOne;
                requestPayOne = {
                    request: 'creditcardcheck',                  // fixed value
                    responsetype: 'JSON',                        // fixed value
                    mode: this.mode,                                // desired mode
                    mid: this.mid,                                // your MID
                    aid: this.aid,                                // your AID
                    portalid: this.portalId,                         // your PortalId
                    encoding: 'UTF-8',                           // desired encoding
                    storecarddata: 'yes',                        // fixed value
                    // hash calculated over your request-parameter-values (alphabetical request-order) plus PMI portal key
                    // hash: '1cf456bf692453613ebb992a3fb859cc347ddc7e94e2ca764efbe8b0089de6964ab1266df0831e59de89dc5291070fe7'
                    hash: this.hash     // see Chapter 3.1.5.3
                };

                // eslint-disable-next-line no-unused-vars
                configPayOne = {
                    fields: {
                        cardtype: {
                            selector: "cardtype",
                                      // put name of your div-container here
                            cardtypes: ["V", "M", "A"]           // define possible cardtypes in PAYONE iFrame
                        },
                        cardpan: {
                            selector: "cardpan",                 // put name of your div-container here
                            type: "text",           // text (default), password, tel
                            //element: document.getElementsByName('cardpan')[0],
                            style: "font-size: 1em; border: 1px solid #000;"
                        },
                        cardcvc2: {
                            selector: "cardcvc2",                // put name of your div-container here
                            type: "password",                    // select(default), text, password, tel
                            style: "font-size: 1em; border: 1px solid #000;",
                            size: "4",
                            maxlength: "4",                      // set max. length for CVC input; empty values possible
                            length: { "A": 4, "V": 3, "M": 3, "J": 0 } // set required CVC length per cardtype
                                                                // if set exact length required; 0=CVC input disabled
                        },
                        cardexpiremonth: {
                            selector: "cardexpiremonth",         // put name of your div-container here
                            type: "select",                      // select(default), text, password, tel
                            size: "2",
                            maxlength: "2",
                            iframe: {
                                width: "50px"
                            }
                        },
                        cardexpireyear: {
                            selector: "cardexpireyear",          // put name of your div-container here
                            type: "select",                      // select(default), text, password, tel
                            iframe: {
                                width: "80px"
                            }
                        },
                        mid: {
                            selector: "mid",          // put name of your div-container here
                            type: "text",                      // select(default), text, password, tel
                            iframe: {
                                width: "80px"
                            }
                        }
                    },
                    defaultStyle: {
                        input: "font-size: 1em; border: 1px solid #000; width: 175px;",
                        select: "font-size: 1em; border: 1px solid #000;",
                        iframe: {
                            height: "33px",
                            width: "180px"
                        }
                    },
                    error: "errorOutput",                        // area to display error-messages (optional)
                    // eslint-disable-next-line no-undef
                    language: Payone.ClientApi.Language.de       // Language to display error-messages
                                                                // (default: Payone.ClientApi.Language.en)
                };

    // Use Payone's Client API hostted iFrames to receive inputted card's data

    this._checkout = new Payone.ClientApi.HostedIFrames(
    {
        fields: {
          cardpan: {
            selector: 'cardNumber',
            type: 'text',
            maxlength: '19'
          },
          cardexpiremonth: {
            selector: 'cardExpiryMonth',
            type: 'text',
            maxlength: '2',
          },
          cardexpireyear: {
            selector: 'cardexpireyear',
            type: 'text',
            maxlength: '2'
          },
          cardcvc2: {
            selector: 'cardcvc2',
            type: 'password',
            maxlength: '4',
            length: {'V': 3, 'M': 3, 'A': 4}
          },
        },

        defaultStyle: {
          input: this._style?.default ?? 'border: 1px solid lightgray; border-radius: 5px; font-size: 18px; box-sizing: border-box; padding: 8px 10px; width: 99%; height: 99%',
          inputFocus: this._style?.focused ?? 'border: 1px solid #0969da; border-radius: 5px; font-size: 18px; box-sizing: border-box; padding: 8px 10px; width: 99%; height: 99%; outline: none;',
          iframe: {},
        },

        autoCardtypeDetection: {
          supportedCardtypes: this._supportedCardTypes,
          callback: (detectedCardType) => {
            document.querySelectorAll('[data-card-type]').forEach((cardTypeIcon) => {
              cardTypeIcon.classList.remove('payone-js-checkout__card--highlighted')
            })

            const detectedCardTypeIcon = document.querySelector(`[data-card-type=${detectedCardType}]`)
            detectedCardTypeIcon.classList.add('payone-js-checkout__card--highlighted')
          }
        },
      },
        {
            request: 'creditcardcheck',                  // fixed value
            responsetype: 'JSON',                        // fixed value
            mode: this.mode,                                // desired mode
            mid: this.mid,                                // your MID
            aid: this.aid,                                // your AID
            portalid: this.portalId,                         // your PortalId
            encoding: 'UTF-8',                           // desired encoding
            storecarddata: 'yes',                        // fixed value
            // hash calculated over your request-parameter-values (alphabetical request-order) plus PMI portal key
            // hash: '1cf456bf692453613ebb992a3fb859cc347ddc7e94e2ca764efbe8b0089de6964ab1266df0831e59de89dc5291070fe7'
            hash: this.hash     // see Chapter 3.1.5.3
        }

    )

    return this
  }

  async pay(endpointUrl, data) {
    if (endpointUrl === undefined) {
      throw new Error('No payment endpoint URL provided. Did you forget about it?')
    }

    function storeCreditCardCheckResponse1(creditCardCheckResponse) {
        console.log("storeCreditCardCheckResponse");
        console.log("creditCardCheckResponse");
        console.log(creditCardCheckResponse);
        this._creditCardCheckResponse = creditCardCheckResponse;
    }

    switch (this.paymentMethod) {
      case 'card':
        return new Promise(async (resolve) => {
          if (!this._checkout.isComplete()) {
            // Naming status 'not_all_fields_filled_properly' instead of 'not_all_fields_complete' because if, for example, we have
            // only one digit in CCV, then .isComplete() is also run which is a little bit of a lie, i.e. it *is* Complete,
            // but not with proper values
            return resolve({
              status: 'error',
              type: 'not_all_fields_filled_properly',
              message: 'Not all the fields are filled properly.'
            })
          }


        //   console.log(window.storeCreditCardCheckResponse);
          const lastCreditCardCheckResponse = this._creditCardCheckResponse ?? {}
          console.log(this._checkout);
          //this._checkout.setRequestData();

          this._checkout.creditCardCheck('storeCreditCardCheckResponse');

          // Stop execution until we get credit card check response from Payone's hosted JS
          await new Promise((resolve) => {
            setInterval(() => {
              if (this._creditCardCheckResponse === undefined)
              {
                // console.log("this._creditCardCheckResponse");
                // console.log(this._creditCardCheckResponse);
                return;
              }
              // If we still haven't received updated credit card check response, do nothing and continue waiting
              if (objectsAreEqual(this._creditCardCheckResponse, lastCreditCardCheckResponse))
              {
                return;
              }

              resolve()
            }, 50)
          })

          console.log("validity RECEIVED!");

          switch (this._creditCardCheckResponse.status) {

            case 'INVALID':
            case 'ERROR':
              return resolve({
                status: 'error',
                type: 'credit_card_check_error',
                message: this._creditCardCheckResponse.errormessage,
                _payone_creditCardCheckResponse: this._creditCardCheckResponse
              })
            default:
              return resolve({
                status: 'error',
                type: 'credit_card_check_error',
                message: `Critical error: undefined credit card check response status - ${this._creditCardCheckResponse.status}`,
                _payone_creditCardCheckResponse: this._creditCardCheckResponse
              })

            // Actually make request to user-specified endpoint that makes true payment request
            case 'VALID':
            this.pseudocardpan = this._creditCardCheckResponse.pseudocardpan;
            this.truncatedcardpan = this._creditCardCheckResponse.truncatedcardpan;
              const paymentMethod = this.paymentMethod
              const pseudoCardPan = this._creditCardCheckResponse.pseudocardpan
            //   const country = this._container.querySelector('#country').value
            //   const surname = this._container.querySelector('input[name=surname]').value

              const truncatedCardPAN = this._creditCardCheckResponse.truncatedcardpan
              const cardExpireDate = this._creditCardCheckResponse.cardexpiredate
              const cardType = this._creditCardCheckResponse.cardtype
              const _lastPaymentMethodSpecificData = { truncatedCardPAN, cardExpireDate, cardType, country, surname }
              localStorage.setItem('lastPaymentMethodSpecificData', JSON.stringify(_lastPaymentMethodSpecificData))
              this.processPayOne();

            //   fetch(endpointUrl, {
            //     method: 'POST',
            //     headers: {
            //       'Content-Type': 'application/json; charset=utf-8'
            //     },
            //     body: JSON.stringify({
            //       ...{paymentMethod, pseudoCardPan, country, surname},
            //       ...data
            //     })
            //   })
            //     .then(response => response.json().catch(err => console.error('Unable to parse JSON. Details:', err)))
            //     .then((response) => {
            //       switch (response.status) {
            //         case 'APPROVED':
            //           return resolve({
            //             status: 'success',
            //             type: 'payment_success',
            //             message: 'Payment succeeded ✓',
            //             txid: response.txid,
            //             _payone_response: response
            //           })

            //         case 'REDIRE T':
            //           return resolve({
            //             status: 'needs_redirect',
            //             type: 'payment_redirect',
            //             redirectUrl: response.redirecturl,
            //             message: 'Click the button inside "Payment response" to go to the payment page',
            //             txid: response.txid,
            //             _payone_response: response
            //           })

            //         case 'ERROR':
            //           return resolve({
            //             status: 'error',
            //             type: 'payment_error',
            //             // Not writing 'Error: ' here to not include English word 'Error' if server responds with non-english language
            //             message: `${response.customermessage}`,
            //             _payone_response: response
            //           })

            //         default:
            //           return resolve({
            //             status: 'error',
            //             type: 'payment_error',
            //             message: `Error: unknown response status - ${response.status}`,
            //             _payone_response: response
            //           })
            //       }
            //     })
            //     .catch((err) => {
            //       console.error(`Try checking if you provided a valid URL for your payment endpoint URL (${endpointUrl}) and that it exists`)
            //       console.error(err)
            //     })
          }
        })
        break

      case 'paypal':
      case 'paydirekt':
        const paymentMethod = this.paymentMethod
        const country = this._container.querySelector('#containerForOtherPaymentMethods #country').value
        const surname = this._container.querySelector('#containerForOtherPaymentMethods input[name=surname]').value
        const firstName = this._container.querySelector('#containerForOtherPaymentMethods input[name=firstname]')?.value
        const zip = this._container.querySelector('#containerForOtherPaymentMethods input[name=zip]')?.value
        const city = this._container.querySelector('#containerForOtherPaymentMethods input[name=city]')?.value

        const _lastPaymentMethodSpecificData = { firstName, zip, city, country, surname }
        localStorage.setItem('lastPaymentMethodSpecificData', JSON.stringify(_lastPaymentMethodSpecificData))

        return new Promise((resolve) => {
          fetch(endpointUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify({
              ...{paymentMethod, country, surname, firstName, zip, city},
              ...data
            })
          })
            .then(response => response.json().catch(err => console.error('Unable to parse JSON. Details:', err)))
            .then((response) => {
              switch (response.status) {
                case 'REDIRECT':
                  return resolve({
                    status: 'needs_redirect',
                    type: 'payment_redirect',
                    redirectUrl: response.redirecturl,
                    message: 'Click the button inside "Payment response" to go to the payment page',
                    txid: response.txid,
                    _payone_response: response
                  })

                case 'ERROR':
                  return resolve({
                    status: 'error',
                    type: 'payment_error',
                    // Not writing 'Error: ' here to not include English word 'Error' if server responds with non-english language
                    message: `${response.customermessage}`,
                    _payone_response: response
                  })

                default:
                  return resolve({
                    status: 'error',
                    type: 'payment_error',
                    message: `Error: unknown response status - ${response.status}`,
                    _payone_response: response
                  })
              }
            })
            .catch((err) => {
              console.error(`Try checking if you provided a valid URL for your payment endpoint URL (${endpointUrl}) and that it exists`)
              console.error(err)
            })
        })
        break

      default:
        console.error(`Undefined payment method - ${this.paymentMethod}`)
    }
  }

  _storeCreditCardCheckResponse(creditCardCheckResponse) {
    console.log("storeCreditCardCheckResponse");
    console.log("creditCardCheckResponse");
    console.log(creditCardCheckResponse);
    this._creditCardCheckResponse = creditCardCheckResponse;
  }
}

function objectsAreEqual(objOne, objTwo) {
  const objOneSize = Object.keys(objOne).length;
  const objTwoSize = Object.keys(objTwo).length;
  if (objOneSize !== objTwoSize) return false;

  let objectsAreEqual = true;
  for (const prop in objOne) {
    if (objOne[prop] !== objTwo[prop]) {
      objectsAreEqual = false;

      break;
    }
  }

  return objectsAreEqual;
}

// PAYONE_MERCHANT_ID=123456
// PAYONE_PORTAL_ID=7654321
// PAYONE_SUBACCOUNT_ID=12345
// PAYONE_KEY=S3cr3t

var apiCredentials1 = {};
apiCredentials1 = {
    merchantId: this.mid,
    portalId: this.portalId,
    subAccountId: this.aid,
    hash: this.hash
  }

// apiCredentials1["PAYONE_MERCHANT_ID"] = this.mid;
// apiCredentials1["PAYONE_PORTAL_ID"] = this.portalId;
// apiCredentials1["PAYONE_SUBACCOUNT_ID"] = this.aid;
// apiCredentials1["PAYONE_KEY"] = this.hash;

var payoneApiMode1 = {}
payoneApiMode1 = {
    mode: this.mode,
    integrator_name: "zucchetti"
}
// payoneApiMode1["PAYONE_MODE"] = this.mode;
// payoneApiMode1["PAYONE_INTEGRATOR_NAME"] = "Zucchetti";

// PAYONE_MODE=test
// PAYONE_INTEGRATOR_NAME=PayoneDeveloper

            const { payoneApiCredentials, payoneApiMode } = window
            console.log(apiCredentials1);
            console.log(payoneApiMode1);

            const payoneCheckout = new PayoneCheckout({apiCredentials: apiCredentials1, apiMode: payoneApiMode1}).mount('#payoneCheckoutFields');
            // {apiCredentials: apiCredentials1, apiMode: payoneApiMode1}
            console.log(payoneCheckout);
            document.querySelector('form').addEventListener('submit', async (evt) => {
            console.log("main.js form submit");
            evt.preventDefault();
            setCheckoutLoading()

            const paymentResponse = await payoneCheckout.pay('/pay.php', {
                items: getCartStorage()
            })
            console.log('%c The payment response:', 'color: #ca880f') // orange-ish color
            console.log(paymentResponse)

            showMessageInCheckout(paymentResponse)
            showPaymentResponse(paymentResponse)
            showCreditCardCheckResponse(payoneCheckout._creditCardCheckResponse)

            if (paymentResponse.status === 'needs_redirect') {
                setCheckoutLoading()

                insertGoToPaymentPageLink(paymentResponse.redirectUrl)

                return // Stop execution to leave the icon loading
            }

            setCheckoutLoading(false)
            })

            // If there was a redirect, check query params and set respective messages in the checkout
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            const { paymentStatus } = params
            if (paymentStatus) {
            if (paymentStatus === 'success') {
                showMessageInCheckoutAfterRedirect('Payment succeeded ✓', 'green')

                // Show last payment method example Payone transaction status notification
                const lastPaymentMethod = localStorage.getItem('lastPaymentMethod')
                showPayoneExampleTransactionStatusNotification(lastPaymentMethod)
            }
            if (paymentStatus === 'error') {
                showMessageInCheckoutAfterRedirect('Error while trying to finish payment', 'red')
            }
            if (paymentStatus === 'back') {
                showMessageInCheckoutAfterRedirect('Cancelled payment', 'yellow')
            }
            }

            // ------- For stubbing data -------
            function getCartStorage() {
            return [
                {
                id: 42,
                quantity: 2,
                },
                {
                id: 43,
                quantity: 1
                }
            ]
            }
        },
        async processPayOne() {
          const result = await paymentService.processPayment(PaymentProviders.PAYONE, {
            pseudocardpan: this.pseudocardpan,
            truncatedcardpan: this.truncatedcardpan,
          });

          this.isLoading = false;
          switch (result.status) {
            case 0:
              this.callback(result.status);
              //this.$refs.topUpModal.openModal();
              //this.$refs.externalPaymentModal.openModal();
              //this.redirectUrl = result.redirectUrl;
              break;
            case 1:
              break;
            case 2:
              break;
            case -3:
              this.$refs.externalPaymentModal.openModal();
              this.redirectUrl = result.redirectUrl;
              // $("#externalPaymentIframe").attr("src",result.redirectUrl);
              // document.getElementById('externalPaymentIframe').setAttribute("src", result.redirectUrl);
              break;
            case -1:
              break;
            default:
              break;
          }
        },
        scrollFrameIntoView(override = false){
                  setTimeout(() => {
                        var objDiv = document.getElementsByClassName("content")[0];
                        var iFrame = document.getElementById("ExternalPaymentFormFrame");
                        var creditCardsWrapper = document.getElementById("credit-cards-wrapper");

                        if(this.vaultedCards != null && this.vaultedCards.length > 0 && !override)
                        {
                            objDiv.scrollTop = objDiv.scrollHeight;
                            creditCardsWrapper.scrollIntoView({behavior: "smooth"});
                        }
                        else
                        {
                            if(window.screen.width < 700)
                            {
                                objDiv.scrollTop = objDiv.scrollHeight;
                                if(iFrame != null)
                                    iFrame.scrollIntoView({behavior: "smooth"});
                                setTimeout(() => {
                                    objDiv.scrollBy({
                                        top: -100,
                                        left: 0,
                                        behavior: 'smooth'
                                    });
                                }, 800);
                            }
                            else
                            {
                                objDiv.scrollTop = objDiv.scrollHeight;
                                if(iFrame != null)
                                    iFrame.scrollIntoView({behavior: "smooth"});
                                setTimeout(() => {
                                    objDiv.scrollBy({
                                        top: -100,
                                        left: 0,
                                        behavior: 'smooth'
                                    });
                                }, 800);
                            }
                        }
                    }, 200);
        },
        onLoad() {
            this.isLoading = false;
        },
        async loadSaferpay() {
          const result = await paymentService.initPayment(PaymentProviders.PAYONE);

          this.firstName = result.firstName;
          this.lastName = result.lastName;

          this.aid = result.aid;
          this.hash = result.hash;
          this.mid = result.mid;
          this.portalId = result.portalId;
          this.mode = result.mode;
          this.dataLoaded = true;
          // eslint-disable-next-line no-unused-vars
          var request = {
            request: 'creditcardcheck',                  // fixed value
            responsetype: 'JSON',                        // fixed value
            mode: result.mode,                                // desired mode
            mid: result.mid,                                // your MID
            aid: result.aid,                                // your AID
            portalid: result.portalId,                         // your PortalId
            encoding: 'UTF-8',                           // desired encoding
            storecarddata: 'yes',                        // fixed value
            // hash calculated over your request-parameter-values (alphabetical request-order) plus PMI portal key
            // hash: '1cf456bf692453613ebb992a3fb859cc347ddc7e94e2ca764efbe8b0089de6964ab1266df0831e59de89dc5291070fe7'
            hash: result.hash     // see Chapter 3.1.5.3
          };

          // eslint-disable-next-line no-unused-vars
          var configPayOne = {
            fields: {
              cardtype: {
                selector: "cardtype",
                // put name of your div-container here
                cardtypes: ["V", "M", "A"]           // define possible cardtypes in PAYONE iFrame
              },
              cardpan: {
                //selector: "cardpan",                 // put name of your div-container here
                type: "text",           // text (default), password, tel
                element: document.getElementsByName('cardpan')[0],
                style: "font-size: 1em; border: 1px solid #000;"
              },
              cardcvc2: {
                selector: "cardcvc2",                // put name of your div-container here
                type: "password",                    // select(default), text, password, tel
                style: "font-size: 1em; border: 1px solid #000;",
                size: "4",
                maxlength: "4",                      // set max. length for CVC input; empty values possible
                length: {"A": 4, "V": 3, "M": 3, "J": 0} // set required CVC length per cardtype
                // if set exact length required; 0=CVC input disabled
              },
              cardexpiremonth: {
                selector: "cardexpiremonth",         // put name of your div-container here
                type: "select",                      // select(default), text, password, tel
                size: "2",
                maxlength: "2",
                iframe: {
                  width: "50px"
                }
              },
              cardexpireyear: {
                selector: "cardexpireyear",          // put name of your div-container here
                type: "select",                      // select(default), text, password, tel
                iframe: {
                  width: "80px"
                }
              },
              mid: {
                selector: "mid",          // put name of your div-container here
                type: "text",                      // select(default), text, password, tel
                iframe: {
                  width: "80px"
                }
              }
            },
            defaultStyle: {
              input: "font-size: 1em; border: 1px solid #000; width: 175px;",
              select: "font-size: 1em; border: 1px solid #000;",
              iframe: {
                height: "33px",
                width: "180px"
              }
            },
            error: "errorOutput",                        // area to display error-messages (optional)
            // eslint-disable-next-line no-undef
            language: Payone.ClientApi.Language.de       // Language to display error-messages
            // (default: Payone.ClientApi.Language.en)
          };

          this.initPayOne();

          // eslint-disable-next-line no-undef
          //initPayone(configPayOne,request);


          // this.externalUrl = result.redirectURL;
          // this.isLoading = true;
          console.log(result);
          console.log(textStatus);
          console.log(jqXHR);
          this.$forceUpdate();
          this.isLoading = false;
        }
    },
    components:{
        'loading' : VueLoading,
        'modal': Modal
        // 'pay-one': PayOne
    },
    mounted() {
        window.processPayone = this.processPayOne;
        if(this.subCode == "T")
        {
            this.transactionInterface = true;
        }
        this.loadSaferpay();
        // document.getElementById('ExternalPaymentFormFrame').onload = function() {
        //     this.onLoad();
        // }
    }
}
</script>

<style>
.creditCardCard {
    display:inline-block;
    margin:15px;
    width:270px;
    height:165px;
    border-radius:5px;
    border: 0.5px solid black;
    text-align:start;
    background: rgb(0,57,70);
    background: radial-gradient(circle, rgba(0,57,70,0.15) 0%, rgba(111,111,111,0) 100%);
}
@media screen and (max-width:600px) {
    .creditCardCard {
        margin:12px;
        width:250px;
        height:160px;
        border-radius:5px;
        border: 0.5px solid black;
        text-align:start;
        background: rgb(0,57,70);
        background: radial-gradient(circle, rgba(0,57,70,0.4) 0%, rgba(111,111,111,0.08) 100%);
    }

    #credit-cards-wrapper{
        padding-top:20px !important;
    }
}

.creditCardCard:hover{
    cursor:pointer;
    box-shadow: var(--box-shadow);
    border:0.2px solid var(--main-color);
}

.creditCardCardBrandImage{
    /* max-width:70px; */
    max-height:40px;
    height:40px;
    margin:10px;
}

.cardNumber {
    font-size:20px;
    padding:8px;
    padding-left:15px;
}
.cardInfo {
    font-size:17px;
    padding:8px;
    padding-left:15px;
}
.vaultedCardsNotation{
    font-size:12px;
}

        fieldset {
            padding: 1em;
            border: 1px solid #000;
            width: 275px;
            margin: 10px;
        }

        /* select {
            margin-right: 10px;
        } */

         .inputIframe  {
            display: block;
            margin-bottom: 10px;
        }


        #paymentsubmit {
            float: right;
            width: auto;
            padding: 5px;
            margin-bottom: 0px;
            margin-right: 10px;
        }
        #errorOutput {

            text-align: center;
            color: #ff0000;
            display: block;
        }





































.container {
  max-width: 1110px;
  margin-top: 45px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: flex-start; /* Don't stretch both checkout and cart */
}

.app-title {
  font-weight: lighter;
  font-size: 66px;
}

.column {
  width: 600px;
  margin-right: 100px;
}
.column:last-child {
  margin-right: 0;
}

.mt-0 {
  margin-top: 0;
}

/** Checkout form **/
.go-to-payment-page-link-container {
  margin-top: 10px;
}

.go-to-payment-page-link {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border: none;
  cursor: pointer;
  padding: 9px 12px;
  background-color: #008000a6;
  color: white;
  border-radius: 4px;
  font-size: 18px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: block;
}
.go-to-payment-page-link:hover {
  background-color: rgba(0, 128, 0, 0.58);
}
.js-go-to-payment-page-link--hidden {
  display: none;
}

.go-to-payment-page-link__arrow {
  position: relative;
  top: 2px;
  animation: back-and-forth 2s ease infinite;
}
@keyframes back-and-forth {
  0% {
    left: 0;
  }

  50% {
    left: 3%;
  }

  100% {
    left: 0;
  }
}

/** Responses **/

.responses-container {
  max-width: 70vw;
  margin-top: 45px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.response {
  background-color: white;
  border-radius: 4px;
  box-sizing: border-box;
  padding-bottom: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.response:empty {
  padding: 0;
}

.response-heading-container {
  padding: 1px 15px;
  background-color: #d0d0d047;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #d2d2d259;
}

.response-heading {
  font-weight: normal;
}

.response__description-container {
  padding: 0 15px;
}

.response-text {
  font-size: 20px;
  padding: 5px 15px;
  /* Allow long text (like URLs) to be broken into new lines */
  white-space: break-spaces;
  word-break: break-word;

  max-width: 800px;
}

/** Cart **/
.cart {
  width: 326px;
  background-color: white;
  padding: 0 16px;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.cart__row {
  display: flex;
  margin-bottom: 20px;
}
.cart__row:last-child {
  margin-bottom: 0;
}

.cart__image {
  max-height: 100px;
  margin-right: 10px;
}
</style>
